import { ClassHelper } from "app/utils";
import Style from "./input.tailwind.js";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Icon } from "@/components/ui/icon/icon";
import { Label } from "../label/label.js";
import { Error } from "../error/error.js";

export function TimeInput(props) {
  const { t } = useTranslation();
  const error = props.errorMessage || t("validation.enterValue");

  let currentDate = props.value;
  if (!currentDate) {
    currentDate = new Date();
  }

  const timeValue = props.value ? moment(currentDate).format("HH:mm") : "";

  function validate(e) {
    let value = e ? e.target.value : "";
    let valid = undefined;

    // input is required and value is blank
    if (props.required && value === "") valid = false;

    // input isn't required and value is blank
    if (!props.required && value === "") valid = true;

    if (props.required && value !== "") valid = true;

    // update the parent form
    const time = moment(value, "HH:mm");
    const updatedDate = moment(currentDate).set({
      hour: time.get("hour"),
      minute: time.get("minute"),
    });
    props.onChange?.(props.name, updatedDate.toDate(), valid);
  }

  // style
  const textStyle = ClassHelper(Style, {
    textbox: true,
    className: props.className,
    error: props.valid === false,
    success: props.valid === true,
    warning: props.warning,
  });

  const onChange = (e) => {
    const time = moment(e.target.value, "HH:mm");
    const updatedDate = moment(currentDate).set({
      hour: time.get("hour"),
      minute: time.get("minute"),
    });
    props.onChange?.(props.name, updatedDate.toDate(), undefined);
  };

  return (
    <div className={Style.input}>
      {props.label && (
        <Label text={props.label} required={props.required} for={props.name} />
      )}

      <input
        type="time"
        id={props.id}
        name={props.name}
        value={timeValue}
        className={textStyle}
        disabled={props.disabled}
        placeholder={props.placeholder}
        onChange={onChange}
        onBlur={(e) => validate(e)}
      />

      {props.valid === false && <Error message={error} />}

      {props.valid === true && (
        <Icon
          image="check"
          color="#8CC57D"
          className={Style.successIcon}
          size={16}
        />
      )}
    </div>
  );
}
