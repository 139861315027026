import { Chat } from "views/chat/chat";
import i18n from "../i18n";

const Routes = [
  {
    path: "/chat",
    view: Chat,
    layout: "app",
    permission: "familyMember",
    title: i18n.t("chatScreen.title"),
  },
];

export default Routes;
