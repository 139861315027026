import { z } from "zod";
import dialogUiConfig from "./dialogUiConfig";
import { IconNames } from "../../icon/icon";

const CategoryFormSchema = z.object({
  folderName: z.string().min(1, {
    message: dialogUiConfig.folderNameFieldValidationMsg,
  }),
  folderIcon: z.enum(IconNames as [string, ...string[]], {
    message: dialogUiConfig.folderIconFieldValidationMsg,  
  }),
});

export default CategoryFormSchema;