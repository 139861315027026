import { ClassHelper } from "app/utils";
import ReactSelect from "react-select";
import Style from "./select.tailwind.js";
import { useTranslation } from "react-i18next";
import { Error } from "../error/error.js";
import { Label } from "../label/label.js";

export function Select(props) {
  const { t } = useTranslation();
  let options = props.options;
  const error = props.errorMessage || t("common.selectOption");
  let defaultValue = null;
  if (props.default !== undefined) {
    defaultValue = options.find((option) => option.value === props.default);
  }

  // set the default
  if (!props.default && options?.length) {
    // if theres no default, show a please select option
    // if (options && options[0]?.value === "unselected") options.shift(0);
    // options.unshift({ value: "unselected", label: t('common.selectOption') });
  }

  function change(e) {
    const value = e?.value;
    let valid = undefined;

    // validate
    valid = props.required && value === "unselected" ? false : true;
    props.onChange(props.name, value, valid);
    props.callback && props.callback(value);
  }

  const selectStyle = ClassHelper(Style, {
    select: props.valid !== false,
    error: props.valid === false,
    warning: props.warning,
  });

  return (
    <div className={Style.input}>
      <Label text={props.label} required={props.required} for={props.name} />

      <ReactSelect
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            padding: "0.5rem",
          }),
        }}
        options={options}
        isClearable={true}
        onChange={(e) => change(e)}
        defaultValue={defaultValue}
        className={selectStyle}
        placeholder={t("common.selectOption")}
      />

      {props.valid === false && (
        <Error message={error} className={Style.message} />
      )}
    </div>
  );
}
