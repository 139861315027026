import Axios from 'axios';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { useEffect } from 'react'

const settings = require("settings.json");

export type SSEEventType = {
  eventType: 'event_room_created'
  | 'event_room_removed'
  | 'event_room_member_invited'
  | 'event_room_member_removed'
  | 'event_room_message_created';
  [key: string]: any;
};

const useSSESubscription = (
  onSSEEvent: (event: SSEEventType) => void,
  enabled = true,
) => {
  useEffect(() => {
    let sseErrorHandler: EventListener | null = null;
    let sseNotifyHandler: EventListener | null = null;
    let eventSource: EventSourcePolyfill | null = null;
    let subsciptions: any[] = [];

    if (!enabled) {
      return;
    }

    (async() => {
      try {
        const result = await Axios.get('/api/notification/subscribe');
        subsciptions = result.data?.subsciptions ?? [];

        eventSource = new EventSourcePolyfill(
          `${settings[process.env.NODE_ENV as string].server_url}/api/notification/stream`,
          {
            heartbeatTimeout: 120000,
            headers: {
              // assime request headers have been set
              'Authorization': Axios.defaults.headers.common['Authorization'] as string,
            },
          }
        );

        sseErrorHandler = (event: any) => {
          if (event.type === 'error') {
            console.error('[::useSSESubscription] -> Connection error:', event.message);
          } else if (event.type === 'exception') {
            console.error('[useSSESubscription] -> Error:', event.message, event.error);
          }
        }

        sseNotifyHandler = (event: any) => {
          onSSEEvent(JSON.parse(event.data))
        }

         // @ts-ignore
        eventSource.addEventListener('error', sseErrorHandler);

        for (const subsciption of subsciptions) {
          eventSource.addEventListener(subsciption, sseNotifyHandler);
        }
      } catch (err) {
        eventSource = null;
        console.error(err);
      }
    })();

    return () => {
      if (eventSource && sseNotifyHandler) {
        if (sseErrorHandler) {
          // @ts-ignore
          eventSource.removeEventListener('error', sseErrorHandler);
        }

        if (sseNotifyHandler) {
          for (const subsciption of subsciptions) {
            eventSource.removeEventListener(subsciption, sseNotifyHandler);
          }  
        }
        eventSource.close();
      }
    }
  }, [
    onSSEEvent,
    enabled,
  ]);
};

export default useSSESubscription;
