import { useState, useEffect, useCallback } from "react";
import _ from "lodash";
import moment from "moment";
import { ClassHelper } from "app/utils";
import Style from "./scheduler.module.scss";
import { useTranslation } from "react-i18next";
import { Label } from "../label/label";

export const SchedulerInput = (props) => {
  const { t } = useTranslation();
  const init = props.default ?? {};
  const repeatEndDateInit = init.repeat_end_date
    ? moment(init.repeat_end_date).format("YYYY-MM-DD")
    : null;
  const [state, setSatate] = useState({
    repeat: init.repeat ?? "daily",
    repeat_interval: init.repeat_interval ?? "1",
    repeat_by_weekday: init.repeat_by_weekday ?? [],
    repeat_end_date: repeatEndDateInit,
  });

  const [errors, setErrors] = useState({
    repeat: null,
    repeat_interval: null,
    repeat_by_weekday: null,
    repeat_end_date: null,
  });

  const repeatOptions = ["never", "daily", "weekly", "monthly"];

  const repeatByWeekdayOptions = [
    { value: "mo", label: t("patientsScreen.eventForm.scheduler.mo") },
    { value: "tu", label: t("patientsScreen.eventForm.scheduler.tu") },
    { value: "we", label: t("patientsScreen.eventForm.scheduler.we") },
    { value: "th", label: t("patientsScreen.eventForm.scheduler.th") },
    { value: "fr", label: t("patientsScreen.eventForm.scheduler.fr") },
    { value: "sa", label: t("patientsScreen.eventForm.scheduler.sa") },
    { value: "su", label: t("patientsScreen.eventForm.scheduler.su") },
  ];

  const repeatWrapperStyle = ClassHelper(Style, {
    className: props.className,
    success: !errors.repeat,
    errorWrapper: errors.repeat,
    warningWrapper: false,
  });

  const repeatStyle = ClassHelper(Style, {
    select: true,
    error: errors.repeat,
    warning: false,
  });

  const repeatIntervalStyle = ClassHelper(Style, {
    textbox: true,
    error: errors.repeat_interval,
    success: !errors.repeat_interval,
    warning: false,
  });

  const repeatEndDateStyle = ClassHelper(Style, {
    textbox: true,
    error: errors.repeat_end_date,
    success: !errors.repeat_end_date,
    warning: false,
  });

  const validate = useCallback(
    (field) => (e) => {
      const value = e.target.value;
      let valid = true;

      if (
        field === "repeat_interval" &&
        (state.repeat === "daily" || state.repeat === "monthly")
      ) {
        valid = value && value > 0;
        setErrors({
          ...errors,
          [field]: valid
            ? null
            : t("patientsScreen.eventForm.scheduler.errorInterval"),
        });
      }

      if (field === "repeat_by_weekday") {
        let selectedQty = state.repeat_by_weekday.length;
        if (e.target.checked) selectedQty++;
        else selectedQty--;

        valid = selectedQty > 0;
        setErrors({
          ...errors,
          [field]: valid
            ? null
            : t("patientsScreen.eventForm.scheduler.errorWeekday"),
        });
      }

      // update the parent form
      props.onChange(field, value, valid);
    },
    [props, state, errors, t]
  );

  const change = (field) => (e) => {
    let value = e.target.value;
    const valid = validate(field)(e);
    if (field === "repeat_by_weekday") {
      value = e.target.checked
        ? [...state.repeat_by_weekday, value]
        : state.repeat_by_weekday.filter((day) => day !== value);
    }

    props.onChange(field, value, valid);
    setSatate({ ...state, [field]: value });
  };

  // handle the repeat_end_date change when user changes the start_date on the Event Form
  // or when we setup the default value for the repeat_end_date
  const handleUpdateRepeatEndDate = useCallback(
    (e) => {
      const value = e.detail;
      const valid = validate("repeat_end_date")(e);
      props.onChange("repeat_end_date", value, valid);
      const controlValue = moment(value).format("YYYY-MM-DD");
      setSatate({ ...state, repeat_end_date: controlValue });
    },
    [props, state, validate]
  );

  useEffect(() => {
    document.addEventListener(
      "SchedulerInput.updateRepeatEndDate",
      handleUpdateRepeatEndDate
    );
    return () => {
      document.removeEventListener(
        "SchedulerInput.updateRepeatEndDate",
        handleUpdateRepeatEndDate
      );
    };
  }, [handleUpdateRepeatEndDate]);

  const handleSelection = (item) => {
    if (item === "never") {
      setSatate({ ...state, repeat: item, repeat_by_weekday: [] });
      props.onChange("repeat", item, true);
      props.onChange("repeat_end_date", null, true);
      props.onChange("repeat_by_weekday", null, true);
    } else if (item === "every_day") {
      setSatate({
        ...state,
        repeat: "daily",
        repeat_interval: 1,
        repeat_by_weekday: [],
      });
      props.onChange("repeat", "daily", true);
      props.onChange("repeat_interval", 1, true);
      props.onChange("repeat_by_weekday", null, true);
    } else if (item !== "never" && item !== "every_day") {
      const repeat_by_weekday = state.repeat_by_weekday?.includes(item)
        ? state.repeat_by_weekday.filter((day) => day !== item)
        : [...state.repeat_by_weekday, item];
      setSatate({ ...state, repeat: "weekly", repeat_by_weekday });
      props.onChange("repeat", "weekly", true);
      props.onChange("repeat_by_weekday", repeat_by_weekday, true);
    }
  };
  const isSelected = (item) => {
    if (item === "never" && state.repeat === "never") return true;
    if (
      item === "every_day" &&
      state.repeat === "daily" &&
      state.repeat_interval === 1
    )
      return true;
    if (
      item !== "never" &&
      item !== "every_day" &&
      state.repeat === "weekly" &&
      state.repeat_by_weekday.includes(item)
    )
      return true;
    return false;
  };

  return (
    <>
      <div className={Style.input}>
        <Label
          text={t("patientsScreen.eventForm.scheduler.repeat")}
          required
          for="repeat"
        />

        <div className={Style.inputWrapper}>
          <span
            key={"every_day"}
            onClick={() => handleSelection("every_day")}
            className={
              isSelected("every_day")
                ? Style.inputItemSelected
                : Style.inputItem
            }
          >
            {t(`patientsScreen.eventForm.scheduler.daily`)}
          </span>
          {repeatByWeekdayOptions?.map((option) => {
            return (
              <span
                key={option.value}
                onClick={() => handleSelection(option.value)}
                className={
                  isSelected(option.value)
                    ? Style.inputItemSelected
                    : Style.inputItem
                }
              >
                {option.label}
              </span>
            );
          })}
          <span
            key={"never"}
            onClick={() => handleSelection("never")}
            className={
              isSelected("never") ? Style.inputItemSelected : Style.inputItem
            }
          >
            {t(`patientsScreen.eventForm.scheduler.never`)}
          </span>
        </div>

        {/* <div className={repeatWrapperStyle}>
          <select
            className={repeatStyle}
            defaultValue={state.repeat}
            onChange={change("repeat")}
            id="repeat"
          >
            {repeatOptions?.map((option) => {
              return (
                <option key={option} value={option}>
                  {t(`patientsScreen.eventForm.scheduler.${option}`)}
                </option>
              );
            })}
          </select>

          {errors.repeat && (
            <Error message={errors.repeat} className={Style.message} />
          )}
        </div> */}
      </div>

      {/* {(state.repeat === "daily" || state.repeat === "monthly") && (
        <div className={Style.input}>
          <Label
            text={t("patientsScreen.eventForm.scheduler.repeatInterval")}
            for="repeat_interval"
          />

          <input
            step={1}
            type="number"
            id="repeat_interval"
            name="repeat_interval"
            value={state.repeat_interval ?? ""}
            className={repeatIntervalStyle}
            onChange={change("repeat_interval")}
            onBlur={validate("repeat_interval")}
          />

          {errors.repeat_interval && <Error message={errors.repeat_interval} />}
        </div>
      )} */}

      {/* {state.repeat === "weekly" && (
        <div className={Style.input}>
          <Label
            text={t("patientsScreen.eventForm.scheduler.repeatWeekday")}
            for="repeat_interval"
          />

          {repeatByWeekdayOptions?.map((option) => {
            return (
              <div key={option.value}>
                <input
                  type="checkbox"
                  name="repeat_by_weekday"
                  value={option.value}
                  checked={state.repeat_by_weekday.includes(option.value)}
                  onChange={change("repeat_by_weekday")}
                  style={{ marginRight: "5px" }}
                />
                {option.label}
              </div>
            );
          })}

          {errors.repeat_by_weekday && (
            <Error message={errors.repeat_by_weekday} />
          )}
        </div>
      )} */}

      {/* {state.repeat !== "never" && (
        <div className={Style.input}>
          <Label
            text={t("patientsScreen.eventForm.scheduler.repeatEndDate")}
            for="repeat_end_date"
          />

          <input
            type="date"
            id="repeat_end_date"
            name="repeat_end_date"
            value={state.repeat_end_date ?? ""}
            className={repeatEndDateStyle}
            onChange={change("repeat_end_date")}
            onBlur={validate("repeat_end_date")}
          />

          {errors.repeat_end_date && <Error message={errors.repeat_end_date} />}
        </div>
      )} */}
    </>
  );
};
