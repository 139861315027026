import { t } from "i18next";
import { Pencil, FolderPlus } from "lucide-react";

const dialogUiConfig = {
  folderNameFieldLabel: t("mediaLibraryScreen.addUpdateCategoryDialog.folderNameFieldLabel"),
  folderNameFieldPlaceholder: t("mediaLibraryScreen.addUpdateCategoryDialog.folderNameFieldPlaceholder"),
  folderIconFieldLabel: t("mediaLibraryScreen.addUpdateCategoryDialog.folderIconFieldLabel"),
  folderIconFieldPlaceholder: t("mediaLibraryScreen.addUpdateCategoryDialog.folderIconFieldPlaceholder"),
  folderNameFieldValidationMsg: t("mediaLibraryScreen.addUpdateCategoryDialog.folderNameFieldValidationMsg"),
  folderIconFieldValidationMsg: t("mediaLibraryScreen.addUpdateCategoryDialog.folderIconFieldValidationMsg"),
  createContext: {
    DialogTriggerIcon: FolderPlus,
    dialogTriggerLabel: t("mediaLibraryScreen.addUpdateCategoryDialog.createContext.dialogTriggerLabel"),
    dialogHeaderTitle: t("mediaLibraryScreen.addUpdateCategoryDialog.createContext.dialogHeaderTitle"),
    dialogHeaderDescr: t("mediaLibraryScreen.addUpdateCategoryDialog.createContext.dialogHeaderDescr"),
    submitBtnLabel: t("mediaLibraryScreen.addUpdateCategoryDialog.createContext.submitBtnLabel"),
    successMsg: t("mediaLibraryScreen.addUpdateCategoryDialog.createContext.successMsg"),
    errMsg: t("mediaLibraryScreen.addUpdateCategoryDialog.createContext.errMsg"),
  },
  updateContext: {
    DialogTriggerIcon: Pencil,
    dialogTriggerLabel: t("mediaLibraryScreen.addUpdateCategoryDialog.updateContext.dialogTriggerLabel"),
    dialogHeaderTitle: t("mediaLibraryScreen.addUpdateCategoryDialog.updateContext.dialogHeaderTitle"),
    dialogHeaderDescr: t("mediaLibraryScreen.addUpdateCategoryDialog.updateContext.dialogHeaderDescr"),
    submitBtnLabel: t("mediaLibraryScreen.addUpdateCategoryDialog.updateContext.submitBtnLabel"),
    successMsg: t("mediaLibraryScreen.addUpdateCategoryDialog.updateContext.successMsg"),
    errMsg: t("mediaLibraryScreen.addUpdateCategoryDialog.updateContext.errMsg"),
  },
};

export default dialogUiConfig;