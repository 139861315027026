import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import Axios from "axios";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { AppContext } from "app/providers/stateProvider";
import { Icon } from "../../icon/icon";

export function CallDialog(props) {
  const { t } = useTranslation();
  const appContext = useContext(AppContext);
  const [callInfo, setCallInfo] = useState(props.callInfo);

  useEffect(() => {
    setCallInfo(props?.callInfo);
  }, [props?.callInfo]);

  function renderCall() {
    return (
      <iframe
        title="call"
        src={callInfo?.roomUrl}
        className="w-full h-full"
        allow="camera; microphone; speaker; fullscreen; display-capture"
      />
    );
  }

  function renderCalling() {
    return (
      <span>
        {t("patientsScreen.profile.calling")} {props.patientName}
      </span>
    );
  }

  async function startCall() {
    const callInfoRespose = await Axios.post("/api/call", {
      patient: props.patientId,
      originatorName:
        appContext?.chat?.chatState?.currentUserData?.name || "test",
    });

    setCallInfo({ ...callInfoRespose.data, callAccepted: true });
  }

  async function finishCall() {
    console.log("call finished", callInfo);
    await Axios.post("/api/call/" + callInfo?.roomId + "/finish", callInfo);
    // props.hide();
  }

  async function acceptCall() {
    console.log("call accepted", callInfo);
    const response = await Axios.post(
      "/api/call/" + callInfo?.roomId + "/accept",
      callInfo
    );
    setCallInfo(response.data);
  }

  function renderView() {
    if (callInfo?.callAccepted) {
      return renderCall();
    } else {
      return renderCalling();
    }
  }

  function setOpen(open) {
    if (open) {
      startCall();
    } else {
      finishCall();
    }
    console.log("open", open);
  }

  return (
    <Dialog onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className="rounded-full bg-[#13974F] hover:bg-[#13974F]/80"
        >
          <Icon
            image="icon-call"
            pack="innocom"
            color="#fff"
            className="text-[20px]"
          />
        </Button>
        {/* <Button variant="ghost" size="icon">
          <div
            className="h-10 w-10 p-0 bg-cover bg-center bg-no-repeat"
            style={{
              backgroundImage: `url("/icons/call.png")`,
            }}
          />
        </Button> */}
      </DialogTrigger>
      <DialogContent className="min-w-full h-screen">
        <div className="flex h-full p-2">{renderView()}</div>
      </DialogContent>
    </Dialog>
  );
}
