import { allowedMimeTypes } from "@/config";
import { t } from "i18next";
import { FileIcon, Share, Trash } from "lucide-react";
import { FC, Fragment, memo } from "react";
import { useDropzone } from 'react-dropzone';

// !TODO: move to helpers
const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
};

// !TODO: accepted files format as a prop with the maxSize of the file (512 MB?)
type DropzonePropsType = {
  // onChange: ChangeEventHandler<HTMLInputElement>;
  onChange: (files: File[]) => void; // should be defined
  onRemoveFile: (file: File) => void;
  acceptedFiles: File[];
  disabled: boolean;
};

// !TODO: conainer styles
const Dropzone: FC<DropzonePropsType> = ({
  onChange,
  onRemoveFile,
  acceptedFiles,
  disabled,
}) => {
  // const [myFiles, setMyFiles] = useState<File[]>([]);

  const onDrop = (acceptedFiles: File[]) => {
    onChange(acceptedFiles);
    // setMyFiles(acceptedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 1,
    onDrop,
    disabled,
    // accept: {"image/png": [".png"]}
    // maxSize
  });

  return (
    <div
      className={`
        ${isDragActive ? "bg-opacity-60" : "bg-opacity-100"}
        cursor-pointer
        border-dashed
        border-[1px]
        border-[#1949A3]
        h-[140px]
        content-center
        flex-col
        bg-[#E8EDF6]
        rounded-xl
        px-[12px]
        text-center
        whitespace-nowrap
        overflow-hidden
      `}
      {...getRootProps()}
    >
      {acceptedFiles.length === 0 ? (
        <Fragment>
          <input {...getInputProps({ disabled: false })} />
          <Share className="w-full mb-[8px]" color="#11203D" />
          <p className="font-normal text-sm text-[#11203D] mb-[4px] truncate">
            {t("common.dragAndDropAreaLabel")}
          </p>
          <p className="font-normal text-sm text-[#858992] truncate">
            {allowedMimeTypes.slice(0,2).join(', ')}
          </p>
        </Fragment>
      ) : (
        <div className="flex justify-around items-center w-full border-2 border-[#E0E0E0] rounded-md px-[8px] py-[8px] overflow-hidden">
 
          <div className="flex w-8 h-8 items-center justify-center">
            <FileIcon
              size={16}
              className="inline-block"
              color="#858992"
            />
          </div>

          <div className="flex flex-col mx-[4px] items-start overflow-hidden">
            <span className="font-medium text-xs text-[#11203D] truncate mb-[2px]">
              {acceptedFiles[0].name} 
            </span>

            <span className="truncate flex text-xs font-normal text-[#858992]">
              {formatBytes(acceptedFiles[0].size)}
            </span>
          </div>

          <div className="flex w-8 h-8 items-center justify-center">
            <Trash
              size={16}
              color="#858992"
              className="inline-block"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onRemoveFile(acceptedFiles[0]);
                // setMyFiles(myFiles.filter((file) => file !== myFiles[0]));               
              }}
            />
          </div>

        </div>
      )}
    </div>
  );
};

export default memo(Dropzone);
