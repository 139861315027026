import { MediaLibrary } from "views/mediaLibrary/mediaLibrary";
import i18n from "../i18n";

const Routes = [
  {
    path: "/media-library",
    view: MediaLibrary,
    layout: "app",
    permission: "familyMember",
    title: i18n.t("mediaLibraryScreen.title"),
  },
];

export default Routes;
