import {
  FC,
  memo,
  useCallback,
  useContext,
  useState,
} from "react";
import Axios from "axios";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { IconNames } from "@/components/ui/icon/icon";
import { AuthContext } from "app/providers/auth";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { DropdownMenuItem } from "@/components/ui/dropdown-menu";
import { t } from "i18next";
import CategoryIconSelector from "../../../common/CategoryIconSelector";
import { MediaCategoryDataType } from "@/types";
import CategoryFormSchema from "./CategoryFormSchema";
import dialogUiConfig from "./dialogUiConfig";
import DialogCommonFooter from "../DialogCommonFooter";

export type AddCategoryDialogPropsType = {
  apiPrefix: string;
  parentCategoryId?: string; 
  selectedCategory?: MediaCategoryDataType;
  onOpenClose: (isOpen: boolean) => void;
  onSubmit: (errMsg: string | null, successMsg?: string) => void;
  disabled: boolean;
  // mediaLibraryUserId?: string;
};

const AddCategoryDialog: FC<AddCategoryDialogPropsType> = ({
  apiPrefix,
  parentCategoryId,
  selectedCategory,
  onOpenClose,
  onSubmit,
  disabled,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [submitFetching, setSubmitFetching] = useState(false);
  const authContext = useContext(AuthContext);
 
  const onOpenChange = useCallback((open: boolean) => {
    setDialogOpen(open);

    if (onOpenClose) {
      onOpenClose(open);
    }
    // !TODO: update linter configuration
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const form = useForm<z.infer<typeof CategoryFormSchema>>({
    resolver: zodResolver(CategoryFormSchema),
    defaultValues: {
      folderName: selectedCategory?.name ?? "",
      folderIcon: selectedCategory?.icon ?? "",
    },
  });

  const uiConfig = !!selectedCategory
    ? dialogUiConfig.updateContext
    : dialogUiConfig.createContext;

  const onSubmitForm = async (formData: z.infer<typeof CategoryFormSchema>) => {
    try {
      setSubmitFetching(true);

      const { status: createCategoryStatus } = await Axios({
        method: !!selectedCategory
          ? "PATCH"
          : "POST",
        url:  !!selectedCategory
          ? `${apiPrefix}/category/${selectedCategory?.id}`
          : `${apiPrefix}/category`,
        data: {
          name: formData.folderName,
          icon: formData.folderIcon,
          parent_id: parentCategoryId,
          account_id: !!selectedCategory
            ? selectedCategory?.account_id
            : authContext.user.account_id,
          description: selectedCategory?.description ?? "",
          is_public: selectedCategory?.is_public ?? false,
          read_access: selectedCategory?.read_access ?? [authContext.user.account_id],
          package_visible_to: selectedCategory?.package_visible_to ?? [],
        },
      });

      if (createCategoryStatus !== 201 && createCategoryStatus !== 200) {
        throw new Error("Error creating category");
      }

      onOpenChange(false);
      onSubmit(null, uiConfig.successMsg);
    } catch (err: any) {
      console.error(err);
      onSubmit(uiConfig.errMsg);
    } finally {
      setSubmitFetching(false);
    }
  };

  return (
    <Dialog open={dialogOpen} onOpenChange={onOpenChange}>
      <DialogTrigger
        disabled={disabled}
        className="p-0 w-full"
      >
        <DropdownMenuItem
          disabled={disabled}
          className="w-full flex py-0 px-[8px]"
          onSelect={(e) => e.preventDefault()}
        >
          <div className="flex flex-row items-center w-full h-[32px]">
            <uiConfig.DialogTriggerIcon className="h-4 w-4 mr-[8px]" />
            <span>{uiConfig.dialogTriggerLabel}</span>
          </div>
        </DropdownMenuItem>
      </DialogTrigger>
      <DialogContent className="flex flex-col w-[432px] h-[360px]">
        <DialogHeader>
          <DialogTitle>
            {uiConfig.dialogHeaderTitle}
          </DialogTitle>
          <DialogDescription>
            {uiConfig.dialogHeaderDescr}
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form className="h-full" onSubmit={form.handleSubmit(onSubmitForm)}>
            <FormField
              disabled={submitFetching}
              control={form.control}
              name="folderName"
              render={({ field }) => (
                <FormItem className="mb-[4px]">
                  <FormLabel className="mb-[6px]" htmlFor="foldername">
                    {dialogUiConfig.folderNameFieldLabel}
                  </FormLabel>
                  <FormControl id="foldername">
                    <Input
                      id="foldername"
                      className="focus-visible:ring-0"
                      placeholder={dialogUiConfig.folderNameFieldPlaceholder}
                      {...field}
                    />
                  </FormControl>
                  <div className="flex h-[20px] overflow-hidden">
                    <FormMessage className="truncate" />
                  </div>
                </FormItem>
              )}
            />
            <FormField
              disabled={submitFetching}
              control={form.control}
              name="folderIcon"
              render={({ field }) => (
                <FormItem className="mb-[4px]">
                  <FormLabel 
                    className="mb-[6px]"
                    htmlFor="foldericon"
                  >
                    {dialogUiConfig.folderIconFieldLabel}
                  </FormLabel>
                  <FormControl id="folderIcon">
                    <CategoryIconSelector
                      values={IconNames}
                      defaultValue={field.value}
                      onValueChange={field.onChange}
                    />
                  </FormControl>
                  <div className="flex h-[20px] overflow-hidden">
                    <FormMessage className="truncate" />
                  </div>
                </FormItem>
              )}
            />
          </form>
        </Form>

        <DialogCommonFooter
          isFetching={submitFetching}
          onSubmitBtnClick={form.handleSubmit(onSubmitForm)}
          submitBtnLabel={uiConfig.submitBtnLabel}
          cancelBtnLabel={t("common.cancel")}
        />
      </DialogContent>
    </Dialog>
  );
};

export default memo(AddCategoryDialog);

