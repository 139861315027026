import { useState, useEffect, useContext, useCallback } from "react";
import Axios from "axios";
import { useParams } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { DataTable } from "@/components/ui/data-table";
import InviteFamilyMemberDialog from "./InviteFamilyMemberDialog";
import { t } from "i18next";
import { Badge } from "@/components/ui/badge";
import { InviteActionsDropdown, UserActionsDropdown } from "./ActionsDropdown";
import AddFamilyMembersDialog from "./AddFamilyMembersDialog";
import { AuthContext } from "app/providers/auth";
import { useToast } from "@/components/ui/use-toast";
import { useError } from "app/utils";

export function FamilyMembers() {
  const { user: currentUser } = useContext(AuthContext);
  const params = useParams();
  const [access, setAccess] = useState([]);
  const [loading, setLoading] = useState(false);
  const canManageFamily = ["owner", "master", "admin", "doctor"].includes(
    currentUser?.permission
  );
  const { toast } = useToast();
  const [handleError] = useError();

  const refreshUsers = async () => {
    try {
      setLoading(true);
      let tableData = [];

      const patientFamilyMembersResult = await Axios.get(
        `/api/patient/${params.patientId}/family`
      );
      const data = patientFamilyMembersResult.data ?? [];

      tableData = tableData.concat(
        data.map(({ name, id, email, date_created, account }) => ({
          name,
          title:
            account.find((a) => a.id === currentUser?.account_id)?.permission ??
            "familyMember",
          id,
          email,
          status: true,
          date_created: moment(date_created).format("MMM DD, YYYY"),
        }))
      );

      const familyMembersInvitesResult = await Axios.get(
        `/api/invite/family/${params.patientId}`
      );
      const familyInvitesData = familyMembersInvitesResult.data ?? [];

      tableData = tableData.concat(
        familyInvitesData.map(
          ({ id, email, user_name, date_sent: date_created }) => ({
            name: user_name,
            status: false,
            email,
            id,
            date_created,
          })
        )
      );

      tableData.header = [
        {
          name: "name",
          title: t("patientsScreen.access.table.name"),
          sort: true,
        },
        {
          name: "email",
          title: t("accountScreen.users.table.email"),
          sort: true,
        },
        {
          name: "status",
          title: t("accountScreen.users.table.status"),
          sort: true,
        },
      ];
      setLoading(false);
      setAccess(tableData);
    } catch (err) {
      setLoading(false);
      handleError(err);
    }
  };

  useEffect(() => {
    refreshUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteUser = useCallback(async (user) => {
    try {
      const removeUserResult = await Axios.delete(
        `/api/patient/${params.patientId}/family/${user.id}`
      );
      if (!removeUserResult.data) {
        throw new Error("error removeing family member");
      }

      refreshUsers();
    } catch (err) {
      handleError(err);
    }
    // eslint-disable-next-line
  }, []);

  const onRemoveUserInvite = useCallback(async (user) => {
    try {
      const removeInviteResult = await Axios.delete(
        `/api/invite/family/${params.patientId}/${user.id}`
      );
      if (!removeInviteResult.data) {
        throw new Error("error removeing family member invite");
      }

      refreshUsers();
    } catch (err) {
      handleError(err);
    }
    // eslint-disable-next-line
  }, []);

  const onResendUserInvite = useCallback(async (user) => {
    try {
      const resendInviteResult = await Axios.post("/api/invite/family", {
        name: user.name || "",
        email: user.email,
        targetPatientId: params.patientId,
        permission: "familyMember",
      });

      if (!resendInviteResult.data) {
        throw new Error("Error resending family member invide");
      }

      refreshUsers();
    } catch (err) {
      handleError(err);
    }
    // eslint-disable-next-line
  }, []);

  const renderActionsCell = useCallback(({ row: { original: dataEntry } }) => {
    if (!canManageFamily) {
      return null;
    }

    return dataEntry.status ? (
      <UserActionsDropdown user={dataEntry} onRemoveUserClick={deleteUser} />
    ) : (
      <InviteActionsDropdown
        user={dataEntry}
        onRemoveUserInvite={onRemoveUserInvite}
        onResendUserInvite={onResendUserInvite}
      />
    );
    // eslint-disable-next-line
  }, []);

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  return (
    <>
      <DataTable
        actionButtons={
          <>
            {canManageFamily && (
              <AddFamilyMembersDialog
                onAddSent={refreshUsers}
                patientId={params.patientId}
              />
            )}
            <InviteFamilyMemberDialog
              onInviteSent={refreshUsers}
              patientId={params.patientId}
            />
          </>
        }
        columns={[
          {
            accessorKey: "name",
            header: t("patientsScreen.access.table.name"),
            sort: true,
          },
          {
            accessorKey: "email",
            header: t("accountScreen.users.table.email"),
            cell: ({ row: { original: dataEntry } }) => (
              <span>{`${dataEntry.email}`}</span>
            ),
          },
          {
            accessorKey: "status",
            header: t("accountScreen.users.table.status"),
            cell: ({ row: { original: dataEntry } }) => (
              <Badge variant={dataEntry.status ? "secondary" : "destructive"}>
                {dataEntry.status
                  ? t("accountScreen.users.statusVerified")
                  : t("accountScreen.users.statusInvited")}
              </Badge>
            ),
          },
          {
            id: "actions",
            enableHiding: false,
            cell: renderActionsCell,
          },
        ]}
        data={access}
        searchField="name"
      />
    </>
  );
}
