import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react";
import Axios from "axios";
import reducer, { ChatActions, ChatStateType, initalState } from "./reducer";
import useSSESubscription from "../../hooks/useSSESubscription";
import {
  ChatRoomDataType,
  ChatRooomType,
  RoomUserType,
  UserType,
} from "./types";
import { AuthContext } from "app/providers/auth";

// is member of the 1-1 room
const isPrivateRoomMember = (roomsData: ChatRooomType[], userId: string) => {
  return !!roomsData.find((roomData) => {
    return (
      !roomData.creator_id &&
      roomData.members.length === 2 &&
      !!roomData.members.find(({ memberId }) => memberId === userId)
    );
  });
};

export type AppContextType = {
  chat: {
    chatState: ChatStateType;
    onSelectChatRoomByUser: (selectedRoomId: string | null) => void;
  };
};

export const AppContext = createContext<AppContextType | null>(null);
type StateProviderPropsType = {};

const StateProvider: FC<PropsWithChildren<StateProviderPropsType>> = ({
  children,
}) => {
  const authContext = useContext(AuthContext);
  const [chatState, dispatch] = useReducer(reducer, initalState);
  const isUserAuthenticated =
    authContext?.user?.token && authContext?.user?.authenticated;

  useEffect(() => {
    if (!isUserAuthenticated) {
      console.log("user is not authenticated");
      return;
    }

    (async () => {
      try {
        dispatch({ type: ChatActions.INITIAL_STATE_REQUEST });

        const currentUserData = (await Axios.get("/api/user"))?.data
          ?.data as UserType;

        if (!currentUserData) {
          throw new Error("error getting current user data!");
        }

        const roomsData = (await Axios.get("/api/chatrooms"))
          ?.data as ChatRooomType[];

        if (!roomsData) {
          throw new Error("error getting chat rooms data!");
        }

        const contactsData = ["admin", "master", "owner"].includes(
          currentUserData.permission as unknown as string
        )
          ? (await Axios.get("/api/contacts/admin/my"))?.data
          : (await Axios.get("/api/contacts/service/my"))?.data;

        if (!contactsData) {
          throw new Error("error getting contacts data!");
        }

        const virtualRoomsData: ChatRoomDataType[] = [];

        // rename to user contact
        contactsData.forEach((userContact: RoomUserType) => {
          if (
            userContact.id !== currentUserData.id &&
            !isPrivateRoomMember(roomsData, userContact.id)
          ) {
            virtualRoomsData.push({
              id: userContact.id,
              roomData: undefined,
              userData: userContact,
            });
          }
        });

        const createdRoomsData = roomsData.map((roomData: ChatRooomType) => ({
          id: roomData.id,
          userData: undefined,
          roomData,
        })) as ChatRoomDataType[];

        // !TODO: sort?

        dispatch({
          type: ChatActions.INITIAL_STATE_SUCCESS,
          currentUserData,
          chatRooms: createdRoomsData.concat(virtualRoomsData),
        });
      } catch (err) {
        dispatch({ type: ChatActions.INITIAL_STATE_FAILURE });
        console.error(err);
      }
    })();
  }, [isUserAuthenticated]);

  useEffect(() => {
    if (!isUserAuthenticated) {
      return;
    }

    (async () => {
      // !TODO: FETCHING
      try {
        if (!chatState.selectedRoomId) {
          return;
        }

        const messagesResult = await Axios.get(
          `/api/chatrooms/${chatState.selectedRoomId}/mesages`
        );

        if (!messagesResult.data) {
          throw new Error("failure getting room messages");
        }

        dispatch({
          type: ChatActions.SET_SELECTED_ROOM_MESSAGES,
          messages: messagesResult.data,
        });
      } catch (err) {
        console.error(err);
      }
    })();
  }, [chatState.selectedRoomId, isUserAuthenticated]);

  // do NOT! add dependencies to this handler (causes SSE client reconnect and hanging)
  const onSSEEvent = useCallback((event: any) => {
    if (event.eventType === "event_room_created") {
      dispatch({
        type: ChatActions.ADD_CHAT_ROOM,
        roomData: event.room,
      });
    } else if (event.eventType === "event_room_removed") {
      dispatch({
        type: ChatActions.REMOVE_CHAR_ROOM,
        roomId: event.roomId,
      });
    } else if (event.eventType === "event_room_message_created") {
      dispatch({
        type: ChatActions.UPDATE_ROOM_LAST_MESSAGE,
        message: event.message,
      });
    }
  }, []);

  useSSESubscription(onSSEEvent, isUserAuthenticated);

  const onSelectChatRoomByUser = useCallback(
    (selectedRoomId: string | null) => {
      dispatch({
        type: ChatActions.ACTION_SELECT_ROOM,
        selectedRoomId,
      });
    },
    []
  );

  const context = {
    chat: {
      chatState,
      onSelectChatRoomByUser,
    },
  };

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};

export default StateProvider;
