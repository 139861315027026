import { t } from "i18next";
// import { Pencil, Plus } from "lucide-react";
import { IconNames } from "../../icon/icon";
import {
  ActivityEventTypes,
  ActivityRepeatTypes,
  ActivityWeekDayTypes,
} from "@/types";

const dialogUiConfig = {
  eventTitleFieldLabel: t("patientScreen.addUpdateEventDialog.eventTitleFieldLabel"),
  eventTitleFieldPlaceholder: t("patientScreen.addUpdateEventDialog.eventTitleFieldPlaceholder"),
  eventTitleFieldValidationMsg: t("patientScreen.addUpdateEventDialog.eventTitleFieldValidationMsg"),
  eventIconFieldLabel: t("patientScreen.addUpdateEventDialog.eventIconFieldLabel"),
  eventIconFieldPlaceholder: t("patientScreen.addUpdateEventDialog.eventIconFieldPlaceholder"),
  eventIconFieldValidationMsg: t("patientScreen.addUpdateEventDialog.eventIconFieldValidationMsg"),
  eventStartDateFieldLabel: t("patientScreen.addUpdateEventDialog.eventStartDateFieldLabel"),
  eventStartDateFieldValidationMsg: t("patientScreen.addUpdateEventDialog.eventStartDateFieldValidationMsg"),
  eventEndDateFieldLabel: t("patientScreen.addUpdateEventDialog.eventEndDateFieldLabel"),
  eventEndDateFieldValidationMsg: t("patientScreen.addUpdateEventDialog.eventEndDateFieldValidationMsg"),
  eventReminderFieldLabel: t("patientScreen.addUpdateEventDialog.eventReminderFieldLabel"),
  eventReminderFieldValidationMsg: t("patientScreen.addUpdateEventDialog.eventReminderFieldValidationMsg"),
  eventRepeatFieldLabel: t("patientScreen.addUpdateEventDialog.eventRepeatFieldLabel"),
  eventRepeatFieldValidationMsg: t("patientScreen.addUpdateEventDialog.eventRepeatFieldValidationMsg"),
  eventRepeatByWeekDayFieldLabel: t("patientScreen.addUpdateEventDialog.eventRepeatByWeekDayFieldLabel"),
  eventRepeatByWeekDayFieldPlaceholder: t("patientScreen.addUpdateEventDialog.eventRepeatByWeekDayFieldPlaceholder"),
  eventRepeatByWeekDayFieldValidationMsg: t("patientScreen.addUpdateEventDialog.eventRepeatByWeekDayFieldValidationMsg"),
  eventTypeFieldLabel: t("patientScreen.addUpdateEventDialog.eventTypeFieldLabel"),
  eventTypeFieldValidationMsg: t("patientScreen.addUpdateEventDialog.eventTypeFieldValidationMsg"),
  eventUrlFieldLabel: t("patientScreen.addUpdateEventDialog.eventUrlFieldLabel"),
  eventUrlFieldPlaceholder: t("patientScreen.addUpdateEventDialog.eventUrlFieldPlaceholder"),
  eventUrlFieldValidationMsg: t("patientScreen.addUpdateEventDialog.eventUrlFieldValidationMsg"),
  eventMediaFieldLabel: t("patientScreen.addUpdateEventDialog.eventMediaFieldLabel"),
  eventMediaFieldPlaceholder: t("patientScreen.addUpdateEventDialog.eventMediaFieldPlaceholder"),
  eventMediaFieldValidationMsg: t("patientScreen.addUpdateEventDialog.eventMediaFieldValidationMsg"),
  selectMediaBtnLabel: t("patientScreen.addUpdateEventDialog.selectMediaBtnLabel"),
  removeMediaBtnLabel: t("patientScreen.addUpdateEventDialog.removeMediaBtnLabel"),
  removeEventBtnLabel: t("patientScreen.addUpdateEventDialog.removeEventBtnLabel"),
  createContext: {
    // DialogTriggerIcon: Plus,
    dialogTriggerLabel: t("patientScreen.addUpdateEventDialog.updateContext.dialogTriggerLabel"),
    dialogHeaderTitle: t("patientScreen.addUpdateEventDialog.createContext.dialogHeaderTitle"),
    dialogHeaderDescr: t("patientScreen.addUpdateEventDialog.createContext.dialogHeaderDescr"),
    submitBtnLabel: t("patientScreen.addUpdateEventDialog.createContext.submitBtnLabel"),
    submitSuccessMessage: t("patientScreen.addUpdateEventDialog.createContext.submitSuccessMessage"),
    submitErrorMessage: t("patientScreen.addUpdateEventDialog.createContext.submitErrorMessage"),
  },
  updateContext: {
    // DialogTriggerIcon: Pencil,
    dialogTriggerLabel: t("patientScreen.addUpdateEventDialog.updateContext.dialogTriggerLabel"),
    dialogHeaderTitle: t("patientScreen.addUpdateEventDialog.updateContext.dialogHeaderTitle"),
    dialogHeaderDescr: t("patientScreen.addUpdateEventDialog.updateContext.dialogHeaderDescr"),
    submitBtnLabel: t("patientScreen.addUpdateEventDialog.updateContext.submitBtnLabel"),
    submitSuccessMessage: t("patientScreen.addUpdateEventDialog.updateContext.submitSuccessMessage"),
    submitErrorMessage: t("patientScreen.addUpdateEventDialog.updateContext.submitErrorMessage"),
  },
  reminderOptions: [
    { label: "0", value: "0" },
    { label: "5", value: "5" },
    { label: "10", value: "10" },
    { label: "15", value: "15" },
    { label: "30", value: "30" },
    { label: "45", value: "45" },
    { label: "60", value: "60" },
  ],
  iconOptions: IconNames.map((iconName) => ({
    label: iconName,
    value: iconName,
  })),
  repeatTypeOptions: ActivityRepeatTypes.map((repeatType) => ({
    label: t(`patientScreen.addUpdateEventDialog.repeatTypes.${repeatType}`),
    value: repeatType,
  })),
  eventTypeOptions: ActivityEventTypes.map((eventType) => ({
    label: t(`patientScreen.addUpdateEventDialog.eventTypes.${eventType}`),
    value: eventType,
  })),
  repeatByWeekDayOptions: [{
    groupId: "idWeekDays",
    groupLabel: "Week days",
    items: ActivityWeekDayTypes.map((weekDayType) => ({
      id: weekDayType,
      label: t(`patientsScreen.eventForm.scheduler.${weekDayType}`),
    })) 
  }]
};

export default dialogUiConfig;