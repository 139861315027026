import { useToast } from "@/components/ui/use-toast";
import { useNavigate } from "react-router-dom";

export function useError() {
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleError = (err: any) => {
    let message = "There was a glitch in the matrix – please try again";

    if (err) {
      message = err.toString();

      if (err.response) {
        if (err.response.data?.message) message = err.response.data.message;

        if (err.response.status) {
          switch (err.response.status) {
            case 401:
              navigate("/signin");
              break;

            case 404:
              navigate("/notfound");
              break;

            case 429:
              toast({
                title: err.response.data,
                variant: "destructive",
              });
              break;

            case 402: // payment required
              navigate("/account/upgrade?plan=" + err.response.data.plan);
              break;

            default:
              toast({
                title: message,
                variant: "destructive",
              });
              break;
          }
        }
      } else {
        toast({
          title: message,
          variant: "destructive",
        });
      }
    }
  };

  return [handleError];
}

// helper function for constructing tailwind classes
export function ClassHelper(styles: any, props: any, debug: any) {
  let str = "";

  if (typeof styles === "object") {
    if (styles.base) str += styles.base; // always apply base styles

    if (Object.keys(props).length) {
      Object.keys(props).forEach((prop) => {
        if (prop === "className" && props[prop]) {
          // always apply className and base
          str = str.trim();
          str += ` ${props[prop]}`;
        } else if (props[prop] && styles.hasOwnProperty(prop)) {
          if (debug) console.log("styles", prop, props[prop], styles[prop]);

          str = str.trim();
          str += ` ${styles[prop]}`;
        }
      });
    }
  }

  if (debug) console.log("final.class", str.trim());

  return str.trim();
}

export function updateRepeatEndDate(repeatEndDate: string) {
  const evt = new CustomEvent("SchedulerInput.updateRepeatEndDate", {
    detail: repeatEndDate,
  });
  document.dispatchEvent(evt);
}
