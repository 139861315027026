import Style from "./entity.tailwind";
import { ClassHelper } from "app/utils";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Icon } from "@/components/ui/icon/icon";

export function Entity(props) {
  const { t } = useTranslation();

  console.log("Entity", props);
  const cardStyle = ClassHelper(Style, {
    base: true,
    categoryCard: !props.file,
    fileCard: props.file,
    packageCategoryCard: props.isPackage && !props.file,
    packageFileCard: props.isPackage && props.file,
    privateCategoryCard: props.isPrivateLibrary && !props.file,
    privateFileCard: props.isPrivateLibrary && props.file,
    selected: props.selected,
  });

  return (
    <div
      className={cardStyle}
      onClick={props.onNavigate}
      title={props.description}
    >
      <div className={Style.cardContainer}>
        <div className={Style.cardTitleContainer}>
          <h5 className={Style.cardTitle}>
            <span>{props.name}</span>
          </h5>

          <div className={Style.mediaType}>
            {props.file
              ? _.capitalize(props.mediaType.replace(/_/g, " "))
              : t(
                  `mediaLibraryScreen.entity.${
                    props.playlist ? "playlist" : "category"
                  }`
                )}
            {props.isPackage
              ? ` - ${t("mediaLibraryScreen.entity.package")}`
              : ""}
          </div>
        </div>
        {props.icon && (
          <Icon
            pack="innocom"
            image={`icon-${props.icon}`}
            className="text-[64px] ml-2 mr-2 width-[64px]"
          />
        )}
      </div>
    </div>
  );
}
