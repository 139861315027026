import {
  FC,
  memo,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react"
import { t } from "i18next";
import Axios from "axios";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { AuthContext } from "app/providers/auth";
import { MediaCategoryDataType } from "@/types";
import PackageFormSchema from "./PackageFormSchema";
import dialogUiConfig from "./dialogUiConfig";
import DialogCommonFooter from "../DialogCommonFooter";
import CustomMultiSelect from "@/components/common/CustomMultiSelect";
import { Button } from "../../button";

type AddUpdatePackageDialogPropsType = {
  onOpenClose: (isOpen: boolean) => void;
  apiPrefix: string;
  parentCategoryId?: string; // !TODO
  selectedPackage?: MediaCategoryDataType,
  onSubmit: (errMsg: string | null, successMsg?: string) => void;
  disabled: boolean;
  CustomDialogTrigger?: ReactElement<any>;
};

const AddUpdatePackageDialog: FC<AddUpdatePackageDialogPropsType> = ({
  onOpenClose,
  apiPrefix,
  parentCategoryId,
  selectedPackage,
  onSubmit,
  disabled,
  CustomDialogTrigger,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [submitFetching, setSubmitFetching] = useState(false);
  const authContext = useContext(AuthContext);
  const [selectData, setSelectData] = useState<any[]>([]);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const cleanupDialogData = () => {
    setSelectData([]);
  };

  const initDialogData = async () => {
    try {
      setSubmitFetching(true);
      const accountsResult = (await Axios.get(`${apiPrefix}/account`))?.data;

      const accountsToSelect = [{
        groupId: "idAccounts",
        groupLabel: dialogUiConfig.selectAccountsGroupLabel,
        items: accountsResult.map(({ name: label, id }: { name: string; id: string; }) => ({
          id, label
        }))
      }];

      setSelectData(accountsToSelect);
      form.reset();

    } catch (err) {
      console.error(err);
    } finally {
      setSubmitFetching(false);
    }
  }

  const onOpenChange = useCallback((open: boolean) => {
    setDialogOpen(open);

    if (onOpenClose) {
      onOpenClose(open);
    }

    if (open) { initDialogData(); }
    else { cleanupDialogData();}

    // !TODO: update linter configuration
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useForm({ defaultValues: async () => fetch('/api-endpoint');})
  const form = useForm<z.infer<typeof PackageFormSchema>>({
    resolver: zodResolver(PackageFormSchema),
    defaultValues: {
      packageName: selectedPackage?.name ?? "",
      packageAccounts: selectedPackage?.package_visible_to ?? [authContext.user.account_id],
    },
  });

  const uiConfig = !!selectedPackage
    ? dialogUiConfig.updateContext
    : dialogUiConfig.createContext;

  const packageOwnersAccountsIds = useMemo(() => {
    return selectedPackage
      ? [selectedPackage.account_id]
      : [authContext.user.account_id];
    // !TODO: update linter configuration
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // !TODO: API
  const onSubmitForm = async (formData: z.infer<typeof PackageFormSchema>) => {
    try {
      setSubmitFetching(true);

      const { status: packageResponseStatus } = await Axios({
        method: selectedPackage ? "PATCH" : "POST",
        url:  !!selectedPackage
          ? `${apiPrefix}/category/${selectedPackage?.id}`
          : `${apiPrefix}/category`,
        data: {
          name: formData.packageName,
          read_access: formData.packageAccounts,
          package_visible_to: formData.packageAccounts,
          is_public: false,
          icon: selectedPackage?.icon || "",
          parent_id: undefined,
          account_id: !!selectedPackage
            ? selectedPackage?.account_id
            : authContext.user.account_id,
          description: selectedPackage?.description ?? "",
        }
      });
  
      if (packageResponseStatus !== 200 && packageResponseStatus !== 201) {
        throw new Error("Error creating playlist");
      }

      onOpenChange(false);
      onSubmit(null, uiConfig.submitSuccessMessage);
    } catch (err) {
      console.error(err);
      onSubmit(uiConfig.submitErrorMessage);
    } finally {
      setSubmitFetching(false);
    }
  };

  const avoidDefaultDomBehavior = useCallback((e: Event) => {
    e.preventDefault() 
  }, []);

  return (
    <Dialog
      open={dialogOpen}
      onOpenChange={onOpenChange}
    >
      <DialogTrigger
        disabled={disabled}
        className="p-0 w-full"
      >
        {CustomDialogTrigger || (
          <Button
            className="px-[18px]"
            size={"sm"}
            variant="secondary"
          >
          <uiConfig.DialogTriggerIcon className="h-4 w-4 mr-1" />
            {uiConfig.dialogTriggerLabel}
          </Button>
        )}

      </DialogTrigger>
      <DialogContent
        onPointerDownOutside={popoverOpen ? avoidDefaultDomBehavior : undefined}
        onInteractOutside={popoverOpen ? avoidDefaultDomBehavior : undefined}
        className="flex flex-col w-[432px] h-[360px]"
      >
        <DialogHeader>
          <DialogTitle>
            {uiConfig.dialogHeaderTitle}
          </DialogTitle>
          <DialogDescription>
            {uiConfig.dialogHeaderDescr}
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            className="h-full"
            onSubmit={form.handleSubmit(onSubmitForm)}
          >
            <FormField
              disabled={submitFetching}
              control={form.control}
              name="packageName"
              render={({ field }) => (
                <FormItem className="mb-[4px]">
                  <FormLabel
                    className="mb-[6px]"
                    htmlFor="idPackageName"
                  >
                    {dialogUiConfig.packageNameFieldLabel}
                  </FormLabel>
                  <FormControl id="idPackageName">
                    <Input
                      className="focus-visible:ring-0"
                      id="idPackageName"
                      placeholder={dialogUiConfig.packageNameFieldPlaceholder}
                      {...field}
                    />
                  </FormControl>
                  <div className="flex h-[20px] overflow-hidden">
                    <FormMessage className="truncate" />
                  </div>
                </FormItem>
              )}
            />
            <FormField
              disabled={submitFetching}
              name="packageAccounts"
              render={({ field }) => (
                <FormItem className="mb-[4px]">
                  <FormLabel
                    className="mb-[6px]"
                    htmlFor="idPackageAccounts"
                  >
                    {dialogUiConfig.packageAccountsFieldLabel}
                  </FormLabel>
                  <FormControl id="idPackageAccounts">
                    <Controller
                      {...field}
                      render={({ field: { onChange, onBlur, value }}) => {
                        return (
                        <CustomMultiSelect
                          onOpenClose={setPopoverOpen}
                          disabledValues={packageOwnersAccountsIds}
                          placeholder={dialogUiConfig.packageAccountsFieldPlaceholder}
                          defaultSelectedValues={value}
                          data={selectData}
                          key={selectData.length} // we need to update the view when the data is received from the backend
                          disabled={submitFetching}
                          onChange={onChange}
                          onBlur={onBlur}
                      />
                    )}}
                  />
                  </FormControl>
                  <div className="flex h-[20px] overflow-hidden">
                    <FormMessage className="truncate" />
                  </div>
                </FormItem>
              )}
            />
          </form>
        </Form>
        <DialogCommonFooter
          isFetching={submitFetching}
          onSubmitBtnClick={form.handleSubmit(onSubmitForm)}
          submitBtnLabel={uiConfig.submitBtnLabel}
          cancelBtnLabel={t("common.cancel")}
        />
      </DialogContent>
    </Dialog>
  );
};

export default memo(AddUpdatePackageDialog);