/***
 *
 *   ONBOARDING
 *   Example of onboarding flow*
 *
 **********/

import { Fragment, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "app/providers/auth";
import { OctagonAlertIcon } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Onboarding } from "components/onboarding/onboarding";
import { Form } from "components/form/form";

export function OnboardingView(props) {
  const { t } = useTranslation();
  const context = useContext(AuthContext);
  const views = [
    {
      name: t("onboardingScreen.title"),
      description: t("onboardingScreen.description", {
        name: context.user.name,
      }),
      component: <Welcome />,
    },
  ];

  // if (context.permission.admin) {
  //   views.push({
  //     name: t("onboardingScreen.inviteTeamAdmin"),
  //     description: t("onboardingScreen.inviteTeamAdminDescription"),
  //     component: <InviteUsers />,
  //   });
  // }

  if (context.user.duplicate_user) {
    views.unshift({
      name: t("onboardingScreen.duplicateUser"),
      description: "",
      component: <DuplicateUser />,
    });
  }

  return <Onboarding save onFinish="/patients" views={views} />;
}

function DuplicateUser() {
  const { t } = useTranslation();
  return (
    <Alert>
      <OctagonAlertIcon className="h-4 w-4" />
      <AlertTitle>{t("onboardingScreen.duplicateUserMessage")}</AlertTitle>
      <AlertDescription>
        {t("onboardingScreen.duplicateUserMessageDescription")}
      </AlertDescription>
    </Alert>
  );
}

function Welcome() {
  const { t } = useTranslation();
  return (
    <Fragment>
      <p>{t("onboardingScreen.welcomeMessage")}</p>
    </Fragment>
  );
}

function InviteUsers(props) {
  const { t } = useTranslation();
  return (
    <Form
      inputs={{
        email: {
          label: t("onboardingScreen.email"),
          type: "email",
          required: true,
        },
      }}
      buttonText={t("onboardingScreen.sendInvite")}
      url="/api/invite"
      method="POST"
    />
  );
}
