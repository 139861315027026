/***
 *
 *   SIGN UP STEP 2
 *   Verify email address
 *   Step 1: create account
 *   Step 2: verify email address
 *   Step 3: select plan
 *
 **********/

import React, { useContext, useState, useEffect, useRef } from "react";
import Axios from "axios";
import { Row, Card, Message, Link } from "components/lib";
import { useTranslation } from "react-i18next";
import { AuthContext } from "app/providers/auth";
import { useLocation, useNavigate } from "react-router-dom";

export function SignupVerification(props) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const qs = location.search;
  const authContext = useRef(useContext(AuthContext));

  const [message, setMessage] = useState({
    type: "info",
    title: t("signUpScreen.verify.titleMessage"),
    text: t("signUpScreen.verify.descriptionMessage"),
    button: {
      text: t("signUpScreen.verify.button"),
      action: resendVerificationEmail,
    },
  });

  useEffect(() => {
    const verifyToken = async (token) => {
      try {
        setMessage({
          type: "success",
          title: t("signUpScreen.verify.successTitle"),
          text: t("signUpScreen.verify.successDescription"),
          button: false,
        });

        const res = await Axios.post("/api/user/verify", { token: token });
        Axios.defaults.headers.common["Authorization"] =
          "Bearer " + res.data.token;
        authContext.current.update({ verified: true, token: res.data.token });

        const view =
          authContext.current.user.permission === "user"
            ? "/welcome"
            : authContext.current.user.plan
              ? "/patients"
              : "/signup/plan";

        return navigate(view);
      } catch (err) {
        // // token isnt for this account, force signout
        if (err.response?.status === 401) return authContext.current.signout();

        setMessage({
          type: "error",
          title: t("signUpScreen.verify.errorTitle"),
          text: t("signUpScreen.verify.errorDescription"),
          button: {
            text: t("signUpScreen.verify.buttonError"),
            action: resendVerificationEmail,
          },
        });
      }
    };

    if (qs.includes("?token=")) {
      // check token exists
      verifyToken(qs.substring(qs.indexOf("?token=") + 7));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qs, authContext, t, navigate]);

  async function resendVerificationEmail() {
    setMessage({
      type: "success",
      title: t("signUpScreen.verify.successTitleMessage"),
      text: t("signUpScreen.verify.successDescriptionMessage"),
    });

    await Axios({ method: "post", url: "/api/user/verify/request" });
  }

  return (
    <Row title={t("signUpScreen.verify.title")}>
      <Card loading={false} noPadding center>
        <Message
          type={message.type}
          title={message.title}
          text={message.text}
          buttonText={message.button?.text}
          buttonAction={message.button?.action}
        />

        <div className="mt-4">
          <Link
            url="/account/profile"
            text={t("signUpScreen.verify.accountButton")}
          />
        </div>
      </Card>
    </Row>
  );
}
