import {
  ActivityEventTypes,
  ActivityRepeatTypes,
  ActivityWeekDayTypes,
} from "@/types";
import { z } from "zod";
import { IconNames } from "../../icon/icon";
import dialogUiConfig from "./dialogUiConfig";

const EventFormSchema = z.object({
  title: z.string().min(1, {
    message: dialogUiConfig.eventTitleFieldValidationMsg,
  }),
  start_date: z.coerce.date({
    message: dialogUiConfig.eventStartDateFieldValidationMsg,
  }),
  end_date: z.coerce.date({
    message: dialogUiConfig.eventEndDateFieldValidationMsg,
  }),
  event_type: z.enum(ActivityEventTypes, {
    message: dialogUiConfig.eventTypeFieldValidationMsg,
  }),
  icon: z.enum(IconNames as [string, ...string[]], {
    message: dialogUiConfig.eventIconFieldValidationMsg,
  }),
  metadata: z.any(), // !TODO (implement)
  reminder: z.number().nonnegative({
    message: dialogUiConfig.eventReminderFieldValidationMsg,
  }),
  repeat: z.enum(ActivityRepeatTypes, {
    message: dialogUiConfig.eventRepeatFieldValidationMsg,
  }),
  repeat_by_weekday: z.array(z.enum(ActivityWeekDayTypes), {
    message: dialogUiConfig.eventRepeatByWeekDayFieldValidationMsg,
  }).nullable(),
  repeat_interval: z.number().nonnegative({
    message: "Invalid repeat_interval value!" // !TODO
  }),
  repeat_end_date: z.coerce.date({
    message: "Invalid repeat_end_date value!", // !TODO
  }),
  account_id: z.string().min(1, {
    message: "Invalid account_id",
  }),
  patient_id: z.string().min(1, {
    message: "Invalid patient_id",
  })
}).superRefine((schema, ctx) => {
  if (schema.repeat === "weekly"
    && (!Array.isArray(schema.repeat_by_weekday)
    || schema.repeat_by_weekday?.length === 0)
  ) {
    ctx.addIssue({
      path: ['repeat'],
      message: dialogUiConfig.eventRepeatByWeekDayFieldValidationMsg,
      code: "custom",
    });

    return false;
  }

  if (schema.end_date < schema.start_date) {
    ctx.addIssue({
      path: ['end_date'],
      message: dialogUiConfig.eventEndDateFieldValidationMsg,
      code: "custom",
    });
    
    return false;   
  }

  if (schema.event_type === "external_source"
    && (!schema.metadata?.link || schema.metadata.link.length === 0)) {
      ctx.addIssue({
        path: ["metadata"],
        message: "Invalid external_source value", // !TODO
        code: "custom",
      });

    return false
  }

  if (schema.event_type === "media"
    && (!schema.metadata?.resource_id || schema.metadata.resource_id.length === 0)) {
      ctx.addIssue({
        path: ["metadata"],
        message: "Invalid media value", // !TODO
        code: "custom",
      });

    return false
  }

  return true;
});

export default EventFormSchema;
