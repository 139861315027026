import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { FilePlus, PlusCircle, FileKey } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { FC, memo, useState } from "react";
import { t } from "i18next";
import AddPlaylistContentDialog from "@/components/ui/dialog/AddPlaylistContentDialog";
import dialogUiConfig from "@/components/ui/dialog/AddPlaylistContentDialog/dialogUiConfig";
import { MediaCategoryDataType, MediaContentDataType } from "@/types";

type PlaylistActionsDropdownPropType = {
  isPackage: boolean; // inside a package
  apiPrefix: string;
  isPrivateLibrary: boolean;
  disabled: boolean;
  parentCategoryId?: string;
  onSubmitContent: (errMsg: string | null, successMsg?: string) => void;
  onRequestSubmitContent: (
    selectedMediaResources: MediaContentDataType[],
    selectedMediaCategories: MediaCategoryDataType[],
    selectedCustomOptions?: string[]
  ) => Promise<any>;
  canAddPrivate: boolean;
  canAddPublic: boolean;
  className?: string;
};

const PlaylistActionsDropdown: FC<PlaylistActionsDropdownPropType> = ({
  isPackage,
  apiPrefix,
  isPrivateLibrary,
  disabled,
  parentCategoryId,
  onSubmitContent,
  onRequestSubmitContent,
  canAddPrivate,
  canAddPublic,
  className = "",
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const dialogCommonProps = {
    disabled: false, // TODO
    apiPrefix,
    parentCategoryId,
    selectedCategory: undefined,
    selectedContent: undefined,
    onOpenClose: setDropdownOpen,
  };

  return (
    <DropdownMenu
      modal={false}
      open={dropdownOpen}
      onOpenChange={setDropdownOpen}
    >
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger disabled>
            <DropdownMenuTrigger className={className} disabled={disabled}>
              <Button
                disabled={disabled}
                className="px-[18px] focus-visible:ring-0 focus:ring-0"
                aria-haspopup="true"
                size="default"
              >
                <PlusCircle className="h-4 w-4 mr-1" />
                {t("common.add")}
              </Button>
            </DropdownMenuTrigger>
          </TooltipTrigger>
          {disabled && (
            <TooltipContent className="text-[#FFFFFF] bg-[#18181B]">
              {t("mediaLibraryScreen.packageEditDisabledTooltip")}
            </TooltipContent>
          )}
        </Tooltip>
      </TooltipProvider>
      <DropdownMenuContent className="w-[210px]" align="end">
        {canAddPublic && (
          <AddPlaylistContentDialog
            {...dialogCommonProps}
            apiPrefix={"/api/media"}
            className="p-0 w-full"
            acceptedMediaTypes={["video"]}
            DialogTriggerContent={
              <DropdownMenuItem
                disabled={disabled}
                className="w-full flex py-0 px-[8px]"
                onSelect={(e) => e.preventDefault()}
              >
                <div className="flex flex-row items-center w-full h-[32px]">
                  <FilePlus className="h-4 w-4 mr-[8px]" />
                  <span>{dialogUiConfig.dialogTriggerLabel}</span>
                </div>
              </DropdownMenuItem>
            }
            onRequestSubmit={onRequestSubmitContent}
            onSubmit={onSubmitContent}
          />
        )}

        {canAddPrivate && (
          <AddPlaylistContentDialog
            {...dialogCommonProps}
            apiPrefix={apiPrefix}
            className="p-0 w-full"
            acceptedMediaTypes={["video"]}
            DialogTriggerContent={
              <DropdownMenuItem
                disabled={disabled}
                className="w-full flex py-0 px-[8px]"
                onSelect={(e) => e.preventDefault()}
              >
                <div className="flex flex-row items-center w-full h-[32px]">
                  <FileKey className="h-4 w-4 mr-[8px]" />
                  <span>{dialogUiConfig.dialogTriggerLabelPrivate}</span>
                </div>
              </DropdownMenuItem>
            }
            onRequestSubmit={onRequestSubmitContent}
            onSubmit={onSubmitContent}
          />
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default memo(PlaylistActionsDropdown);
