/***
 *
 *   ICON
 *   Render an icon from feather icons
 *
 *   PROPS
 *   color: dark/light/grey/green/orange/blue or hex code
 *   pack: icon pack to use, default is feathericons
 *   image: image to use (see: https://feathericons.com)
 *   className: inject a parent class object
 *
 **********/
// @ts-ignore
import FeatherIcon from "feather-icons-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import "./icons.css";

export const IconNames = [
  "training",
  "calendar",
  "call",
  "communication",
  "doctor_visit",
  "family",
  "games",
  "hygiene",
  "meal",
  "medicines",
  "music",
  "nurse",
  "other",
  "person",
  "sleep",
  "toilet",
  "tv",
  "walk",
  "documentation",
  "animals",
  "weight",
  "healthy_snack",
  "nature",
];

export function Icon(props: any) {
  const { color, pack, image, className, size } = props;
  const packs = {
    fontawesome: FontAwesomeIcon,
    feather: FeatherIcon,
  };

  if (pack === "innocom") {
    return (
      <i className={`icon ${image} ${className}`} style={{ color: color }}></i>
    );
  }

  if (pack === "fontawesome") library.add(fab);

  // @ts-ignore
  const Icon = packs[pack || "feather"];

  return (
    <Icon color={color} icon={image} size={size || 16} className={className} />
  );
}
