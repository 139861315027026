import { FC, memo, MouseEventHandler } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { MediaCategoryDataType } from "@/types";
import { Checkbox } from "../../checkbox";
import { Icon } from "../../icon/icon";

type PlaylistCategoryEntityPropsType = {
  entityData: MediaCategoryDataType;
  isSelected?: boolean;
  actionsMenuEnabled: boolean;
  onClick: (entityData: MediaCategoryDataType) => void;
  onCheck: (entityData: MediaCategoryDataType, checked: boolean) => void;
};

const PlaylistCategoryEntity: FC<PlaylistCategoryEntityPropsType> = ({
  entityData,
  isSelected,
  onClick,
  onCheck,
}) => {
  const onCheckClick: MouseEventHandler<HTMLButtonElement> = (e: any) => {
    const { dataset: { state } } = e.target;
    onCheck(entityData, state !== "checked");
  };

  return (
    <Card className="h-[180px] w-[180px] overflow-hidden flex-col flex">
      <CardHeader className="p-0 px-[12px] py-[12px] border-0 border-slate-200">
        <CardTitle className="items-center flex flex-1 justify-between overflow-hidden">
          <span className="text-sm text-[#11203D] font-medium whitespace-nowrap text-ellipsis w-full truncate mr-2">
            {entityData?.name}
          </span>
          <Checkbox
            aria-label="select item"
            checked={isSelected}
            onClick={onCheckClick}
            id={entityData?.id}
            className="focus-visible:ring-0 border-[#E0E0E0] data-[state=checked]:bg-[#1949A3] data-[state=checked]:border-[#E0E0E0]"
          />
        </CardTitle>
      </CardHeader>
      <CardContent
      className="cursor-pointer border-1 w-full h-full border-blue-200 flex grow-1 p-0 px-[12px] pb-[12px]"
      onClick={() => onClick(entityData)}
    >
      <div className="border-[1px] w-full h-full border-grey-50 flex justify-center items-center bg-grey-background rounded-md">
        <div
          className="flex h-20 w-20 bg-cover bg-center bg-no-repeat justify-center items-center"
          style={{
            backgroundImage: `url(${entityData.is_package
              ? "/icons/innocom-folder.png"
              : "/icons/folder.png"})`,
          }}
        >
          {(entityData?.icon?.length && !entityData.is_package) && (
            <Icon
              pack="innocom"
              color="#3A70D4"
              image={`icon-${entityData?.icon}`}
              className="text-[32px] width-[32px] mt-[5px]"
            />
          )}
        </div>
      </div>
    </CardContent>
    </Card>
  );
};

export default memo(PlaylistCategoryEntity);
