const getFilesMediaType = (files: File[]) => {
  let lastType = null;

  if (files.length === 0) {
    return null;
  }

  for (let i = 0, len = files.length; i < len; i += 1) {
    const { type } = files[i];

    if (lastType !== null && type !== lastType) {
      return null;
    }

    lastType = type;
  }

  if (lastType === "video/mp4" ) {
    return "video";
  }

  if (lastType === "application/pdf") {
    return "document";
  }

  return null;
};

export {
  getFilesMediaType
};