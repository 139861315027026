import { Signin } from "views/auth/signin";
import { SigninOTP } from "views/auth/signin/otp";
import { ForgotPassword } from "views/auth/signin/forgotpassword";
import { ResetPassword } from "views/auth/signin/resetpassword";
import i18n from "../i18n";
import { SignupUser } from "views/auth/signup/user";
import { SignupPlan } from "views/auth/signup/plan";
import { SignupVerification } from "views/auth/signup/verify";
import { Signup } from "views/auth/signup/account";

const Routes = [
  {
    path: "/signup",
    view: Signup,
    layout: "auth",
    title: i18n.t("signUpScreen.title"),
  },
  {
    path: "/signup/verify",
    view: SignupVerification,
    layout: "auth",
    permission: "user",
    title: i18n.t("signUpScreen.verify.title"),
  },
  {
    path: "/signup/plan",
    view: SignupPlan,
    layout: "auth",
    permission: "owner",
    title: i18n.t("signUpScreen.title"),
  },
  {
    path: "/signup/user",
    view: SignupUser,
    layout: "auth",
    title: i18n.t("signUpScreen.title"),
  },
  {
    path: "/signin",
    view: Signin,
    layout: "auth",
    title: i18n.t("signInScreen.title"),
  },
  {
    path: "/signin/otp",
    view: SigninOTP,
    layout: "auth",
    title: i18n.t("signInScreen.otp.title"),
  },
  {
    path: "/forgotpassword",
    view: ForgotPassword,
    layout: "auth",
    title: i18n.t("signInScreen.forgotPassword.question"),
  },
  {
    path: "/resetpassword",
    view: ResetPassword,
    layout: "auth",
    title: i18n.t("signInScreen.resetPassword"),
  },
];

export default Routes;
