import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { CSSTransition } from "react-transition-group";

export function VideoDialog(props) {
  return (
    <CSSTransition in appear timeout={0} classNames="modal">
      <div
        className="modal"
        onClick={(e) => e.target === e.currentTarget && props.hide()}
      >
        <div className="modal-content overflow-y-auto">
          <Card>
            <CardHeader>
              <CardTitle>{props.title}</CardTitle>
            </CardHeader>
            <CardContent>
              <video
                width="100%"
                height="540"
                controls
                controlsList="nodownload"
                oncontextmenu="return false;"
                preload="none"
                style={{
                  backgroundColor: "#cfcfcf",
                  borderRadius: "4px",
                  maxHeight: "540px",
                  width: "100%",
                }}
              >
                <source src={props.url} type="video/mp4" />
              </video>
            </CardContent>
          </Card>
        </div>
      </div>
    </CSSTransition>
  );
}
