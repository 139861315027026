import { useState } from "react";
import { Categories } from "./categories";
import { Playlist } from "./playlist";

export function MediaEditor(props) {
  const { apiPrefix } = props;
  const isPackage = props.package ?? false;
  const isPrivateLibrary = props.privateLibrary ?? false;
  const [playlistId, setPlaylistId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);

  const backToCategory = () => {
    setPlaylistId(null);
  };

  return playlistId ? (
    <Playlist
      apiPrefix={apiPrefix}
      categoryId={categoryId}
      id={playlistId}
      backToCategory={backToCategory}
      backToRoot={() => setPlaylistId(null)}
      package={isPackage}
      privateLibrary={isPrivateLibrary}
    />
  ) : (
    <Categories
      categoryId={categoryId}
      apiPrefix={apiPrefix}
      onOpenPlaylist={setPlaylistId}
      onChangeCategory={setCategoryId}
      package={isPackage}
      privateLibrary={isPrivateLibrary}
    />
  );
}
