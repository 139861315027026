/***
 *
 *   MODAL
 *   Display an overlay modal anywhere in your application by calling
 *   context.modal.show() with an object containing the following props
 *
 *   PROPS
 *   title: (optional)
 *   text: message to the user (optional)
 *   form: a form object (optional: see form docs for more information)
 *   url: destination to send the form
 *   method: HTTP post type
 *   buttonText – text for the confirm button
 *
 **********/

import { useContext } from "react";
import { CSSTransition } from "react-transition-group";
import "./modal.scss";
import { Card } from "components/card/card";
import { Form } from "components/form/form";
import { ViewContext } from "components/view/view";

export function Modal(props) {
  const context = useContext(ViewContext);

  return (
    <CSSTransition in appear timeout={0} classNames="modal">
      <div
        className="modal"
      >
        <div className="modal-content overflow-y-auto">
          <Card title={props.title}>
            {props.text && <p>{props.text}</p>}

            {props.form && (
              <Form
                delete={props.delete}
                method={props.method}
                url={props.url}
                inputs={props.form}
                destructive={props.destructive}
                buttonText={props.buttonText}
                cancel={(e) => context.modal.hide(true)}
                submit={props.submit}
              />
            )}
          </Card>
        </div>
      </div>
    </CSSTransition>
  );
}
