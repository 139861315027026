import { Patients } from "views/patients/patients";
import { Patient } from "views/patients/patient";
import i18n from "../i18n";

const Routes = [
  {
    path: "/patients",
    view: Patients,
    layout: "app",
    permission: "familyMember",
    title: i18n.t("patientsScreen.title"),
  },
  {
    path: "/patients/:patientId",
    view: Patient,
    layout: "app",
    permission: "familyMember",
    title: i18n.t("patientsScreen.title"),
  },
];

export default Routes;
