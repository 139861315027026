import { useContext } from "react";

import { useTranslation } from "react-i18next";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Profile } from "./profile";
import { Users } from "./users";
import { Settings } from "./settings/settings";
import { Groups } from "./groups";
import { AuthContext } from "app/providers/auth";

export function Account() {
  const { t } = useTranslation();
  // state/context
  const context = useContext(AuthContext);

  return (
    <main className="grid overflow-scroll flex-1 items-start gap-4 p-4 sm:px-6 md:gap-8">
      <Tabs defaultValue="profile">
        <div className="flex items-center">
          <TabsList>
            <TabsTrigger value="profile">
              {t("accountScreen.profile.title")}
            </TabsTrigger>
            {context.permission?.admin && (
              <>
                <TabsTrigger value="users">
                  {t("accountScreen.users.title")}
                </TabsTrigger>
                {(context.permission?.mater || context.permission?.owner) && (
                  <TabsTrigger value="functions">
                    {t("accountScreen.settings.title")}
                  </TabsTrigger>
                )}
                <TabsTrigger value="groups">
                  {t("groupsScreen.title")}
                </TabsTrigger>
              </>
            )}
          </TabsList>
        </div>
        <TabsContent value="profile">
          <Profile />
        </TabsContent>
        <TabsContent value="users">
          <Users />
        </TabsContent>
        <TabsContent value="functions">
          <Settings />
        </TabsContent>
        <TabsContent value="groups">
          <Groups />
        </TabsContent>
      </Tabs>
    </main>
  );
}
