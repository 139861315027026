import { useContext, useState } from "react";
import { ClassHelper } from "app/utils";
import InputStyle from "../input/input.tailwind.js";
import { Icon } from "@/components/ui/icon/icon";
import { ViewContext } from "components/view/view.js";
import { Label } from "../label/label.js";
import { TextInput } from "../input/text.js";
import { Select } from "../select/select.js";
import { Error } from "../error/error.js";

export function MediaMetadataInput(props) {
  const gameOptions = [
    { label: "NeuronUp", value: "neuronup" },
    { label: "Other", value: "other" },
  ];
  const [value, setValue] = useState(props.value);

  const context = useContext(ViewContext);
  let mediaType = props.formData["media_type"];
  mediaType = mediaType.value ?? mediaType.default;

  const error = props.errorMessage || "Please enter a value";

  // style
  const textStyle = ClassHelper(InputStyle, {
    textbox: true,
    className: props.className,
    error: props.valid === false,
    success: props.valid === true,
    warning: props.warning,
  });

  if (mediaType !== "game" && mediaType !== "external_source") {
    return null;
  }

  const onChange = function (name, inputValue, valid) {
    let newValue = { ...value, [name]: inputValue };
    setValue(newValue);
    props.onChange?.(props.name, newValue, valid);
  };

  return (
    <div className={InputStyle.input}>
      {props.label && (
        <Label text={props.label} required={props.required} for={props.name} />
      )}

      <div>
        <TextInput
          id={"link"}
          name={"link"}
          type={"url"}
          value={value?.link}
          onChange={onChange}
        ></TextInput>
        {mediaType === "game" && (
          <Select
            id={"type"}
            name={"type"}
            label={"Select game type"}
            options={gameOptions}
            default={value?.type}
            onChange={onChange}
          ></Select>
        )}
      </div>

      {props.valid === false && <Error message={error} />}

      {props.valid === true && (
        <Icon
          image="check"
          color="#8CC57D"
          className={InputStyle.successIcon}
          size={16}
        />
      )}
    </div>
  );
}
