import { FC, memo, MouseEventHandler } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { MediaContentDataType } from "@/types";
import { getEntityDefaultIcon } from "../../mediaEditor/MediaEntity/helpers";

import { Checkbox } from "../../checkbox";
import { Import } from "lucide-react";

type PlaylistContentEntityPropsType = {
  entityData: MediaContentDataType; // !TODO: common
  isSelected?: boolean;
  isPackage: boolean;
  actionsMenuEnabled: boolean;
  onClick: (entityData: MediaContentDataType) => void;
  onCheck: (entityData: MediaContentDataType, checked: boolean) => void;
};

const PlaylistContentEntity: FC<PlaylistContentEntityPropsType> = ({
  entityData,
  isSelected,
  isPackage,
  onClick,
  onCheck,
}) => {
  const onCheckClick: MouseEventHandler<HTMLButtonElement> = (e: any) => {
    const {
      dataset: { state },
    } = e.target;
    onCheck(entityData, state !== "checked");
  };

  const entityContentData = entityData?.linked_resource || entityData;

  return (
    <Card className="h-[180px] w-[180px] overflow-hidden flex-col flex">
      <CardHeader className="p-0 px-[12px] py-[12px] border-0 border-slate-200">
        <CardTitle className="items-center flex flex-1 justify-between overflow-hidden">
          <span className="text-sm text-[#11203D] font-medium whitespace-nowrap text-ellipsis w-full truncate mr-2">
            {entityContentData?.name}
          </span>
          <Checkbox
            aria-label="select item"
            checked={isSelected}
            onClick={onCheckClick}
            id={entityData?.id}
            className="focus-visible:ring-0 border-[#E0E0E0] data-[state=checked]:bg-[#1949A3] data-[state=checked]:border-[#E0E0E0]"
          />
        </CardTitle>
      </CardHeader>
      <CardContent
        onClick={() => onClick(entityData)}
        className="cursor-pointer border-1 w-full h-full border-blue-200 flex grow-1 p-0 px-[12px] pb-[12px] relative"
      >
        <div
          className="border-[1px] w-full h-full border-grey-50 flex justify-center items-center bg-grey-background rounded-md bg-cover bg-center bg-no-repeat"
          style={{ backgroundImage: `url(${entityContentData.blob_preview_url})` }}
        >
          {!entityContentData.blob_preview_url && (
            <div
              className="w-[80px] h-[80px] p-0 bg-cover bg-center bg-no-repeat"
              style={{
                backgroundImage: `url(${getEntityDefaultIcon(
                  isPackage,
                  entityContentData
                )})`,
              }}
            />
          )}
          {entityContentData.blob_preview_url && entityContentData.media_type === "video" && (
            <div className="w-full h-full flex items-center justify-center bg-[#00000033]">
              <div
                className="w-[32px] h-[32px] p-0 bg-cover bg-center bg-no-repeat"
                style={{ backgroundImage: `url("/icons/play.png")` }}
              />
            </div>
          )}
        </div>
        {(["shared_category", "shared_resource"].includes(entityData.media_type as string)) && (
          <Import
            color="#858992"
            className="absolute right-6 bottom-4"
          />
        )}
      </CardContent>
    </Card>
  );
};

export default memo(PlaylistContentEntity);
