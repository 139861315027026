const Style = {
  message: "relative p-8 overflow-hidden w-full rounded mb-4",
  title: "text-xl font-semibold m-0 mb-3",
  blueBg: "bg-blue-100",
  blueText: "text-blue-500",
  greenBg: "bg-emerald-100",
  greenText: "text-emerald-500",
  orangeBg: "bg-orange-100",
  orangeText: "text-orange-400",
  redBg: "bg-red-100",
  redText: "text-red-500",
  icon: "float-left mr-6",
  content: "float-left mt-6 width-auto overflow-hidden md:float-none md:mt-0",
  close:
    "absolute top-5 right-5 opacity-60 w-4 h-4 cursor-pointer z-1 bg-transparent ease-in-out duration-300 hover:opacity-90",
  btn: "mt-3",
};

export default Style;
