
import { memo } from "react";
import { t } from "i18next";
import { MoreVertical } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../tooltip";

const MediaToolTip = () => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger
        onClick={(e) =>  {
          e.preventDefault();
          e.stopPropagation();
        }}
        className="!mt-0 px-[12px]"
      >
        <MoreVertical
          className="h-3.5 w-3.5 !m-0"
          color={"#BFC3CD"}
        />
      </TooltipTrigger>
      <TooltipContent>
        <p>{t("mediaLibraryScreen.locked")}</p>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

export default memo(MediaToolTip);