import {
  MediaCategoryDataType,
  MediaContentDataType,
  MediaContentSortOrderType,
  MediaType,
} from "@/types";
import { MediaTreeItem } from "./MediaLibNavigationTree";

// !FIXME
const createMediaLibContentSortFn = (
  sortOrder: MediaContentSortOrderType
) => (
  a: MediaContentDataType | MediaCategoryDataType,
  b: MediaContentDataType | MediaCategoryDataType
) => {
  if (a.is_package && !b.is_package) {
    return -1;
  }
  
  if (!a.is_package && b.is_package) {
    return 1;
  } 

  const item1: MediaContentDataType | MediaCategoryDataType = (a as MediaContentDataType)?.linked_resource ?? a;
  const item2: MediaContentDataType | MediaCategoryDataType = (a as MediaContentDataType)?.linked_resource ?? b;

  if (sortOrder === MediaContentSortOrderType.SORT_BY_NAME) {
    if (!item1.name || !item2.name) return 0;
    return item1.name.toLowerCase() < item2.name.toLowerCase() ? -1 : 1;
  } 
  
  if (sortOrder === MediaContentSortOrderType.SORT_BY_DATE) {
    return item1.created_at < item2.created_at ? -1 : 1;
  }
  
  if (sortOrder === MediaContentSortOrderType.SORT_BY_MEDIA_TYPE) {
    const _a = item1 as MediaContentDataType;
    const _b = item2 as MediaContentDataType;

    if (!_a.media_type || !_b.media_type) return 0;
    return _a.media_type < _b.media_type ? -1 : 1;
  }

  return 0;
};

const checkIfAcceptedMediaResource = (
  item: MediaTreeItem,
  acceptedTypes: MediaType[]
) => {
  return item.type === "resource"
    && (acceptedTypes.length === 0
      || acceptedTypes.includes(item?.payload?.media_type)
      || acceptedTypes.includes(item?.payload?.linked_resource?.media_type));
};

const searchMediaLibraryTreeItems = (
  mediaTree: MediaTreeItem,
  checkFunc: (item: MediaTreeItem) => boolean,
) => {
  const queue = [mediaTree];
  const visited = new Set();
  const result = [];

  while (queue.length) {
    const vertex = queue.shift() as MediaTreeItem;

    if (!visited.has(vertex)) {
      visited.add(vertex);

      if (checkFunc(vertex)) {
        result.push(vertex);
      }

      for (const neighbor of vertex?.children || []) {
        queue.push(neighbor);
      }
    }
  }

  return result;
}

export enum MediaLibraryInUseType {
  PATIENT_MEDIA_LIBRARY = "patient_media_library",
  ACCOUNT_MEDIA_LIBRARY = "account_media_library",
  PACKAGES_MEDIA_LIBRARY = "packages_media_library",
  UNKNOWN_MEDIA_LIBRARY = "unknown_media_library"
};

const getMediaLibraryInUseType = (apiPrefix: string) => {
  if (apiPrefix.startsWith("/api/media")) {
    return MediaLibraryInUseType.ACCOUNT_MEDIA_LIBRARY;
  }

  if (apiPrefix.startsWith("/api/user/")) {
    return MediaLibraryInUseType.PATIENT_MEDIA_LIBRARY;
  }

  if (apiPrefix.startsWith("/api/package")) {
    return MediaLibraryInUseType.PACKAGES_MEDIA_LIBRARY;
  }

  return MediaLibraryInUseType.UNKNOWN_MEDIA_LIBRARY;
}

export {
  createMediaLibContentSortFn,
  searchMediaLibraryTreeItems,
  checkIfAcceptedMediaResource,
  getMediaLibraryInUseType,
};