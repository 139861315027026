import { z } from "zod";
import dialogUiConfig from "./dialogUiConfig";

const PackageFormSchema = z.object({
  packageName: z.string().min(1, {
    message: dialogUiConfig.packageNameFieldValidationMsg,
  }),
  packageAccounts: z.array(z.string()).optional(),
});

export default PackageFormSchema;