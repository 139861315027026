import { FC, memo } from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "../dialog";
import { MediaContentDataType } from "@/types";

type PreviewContentDialogPropsType = {
  triggerStyle?: string;
  selectedMediaContent?: MediaContentDataType | null;
  setSelectedMediaContent: (entityData: MediaContentDataType | null) => void;
};

const PreviewContentDialog: FC<PreviewContentDialogPropsType> = ({
  selectedMediaContent,
  setSelectedMediaContent,
}) => {
  return (
    <Dialog
      open={!!selectedMediaContent}
      onOpenChange={(open) => { if (!open) setSelectedMediaContent(null);}}
    >
      <DialogContent
        hideDefaultCloseBtn
        className="flex flex-col w-10/12 sm:max-w-full sm:max-h-full h-4/6 overflow-hidden"
      >
        <DialogHeader>
          <DialogTitle>
            {selectedMediaContent?.name}
          </DialogTitle>
        </DialogHeader>
        <div className="flex flex-1 w-full h-full overflow-hidden">
          {(selectedMediaContent && selectedMediaContent.media_type === "document") && (
            <iframe
              title="document"
              src={`${selectedMediaContent.blob_url}#toolbar=0&navpanes=0`}
              width="100%"
              height="100%"
            />
          )}
          {selectedMediaContent && (selectedMediaContent.media_type === "external_source") && (
            <iframe
              title="document"
              src={`${selectedMediaContent.metadata?.link}#toolbar=0&navpanes=0`}
              width="100%"
              height="100%"
            />
          )}
          {selectedMediaContent && (selectedMediaContent.media_type === "video") && (
            <video
              width="100%"
              height="100%"
              controls
              controlsList="nodownload"
              onContextMenu={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              preload="none"
              style={{
                backgroundColor: "#cfcfcf",
                borderRadius: "4px",
                maxHeight: "100%",
                width: "100%",
                height: "100%",
              }}
            >
              <source
                src={selectedMediaContent.blob_url}
                type="video/mp4"
              />
            </video>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default memo(PreviewContentDialog);
