import { MediaContentDataType, MediaType } from "@/types";

const mediaTypesDefaultIcons: Record<MediaType, string> = {
  "shared_category": "/icons/folder.png",
  "shared_resource": "/icons/folder.png",
  "document": "/icons/document.png",
  "external_source":"/icons/external_source.png",
  "playlist": "/icons/playlist.png",
  "game": "/icons/game.png",
  "video": "/icons/video.png",
  "audio": "/icons/video.png", // !TODO
  "image": "/icons/image.png",
};

export const getEntityDefaultIcon = (isPackage: boolean, entity: MediaContentDataType) => {
  let mediaType = entity.media_type;

  if (isPackage) {
    return "/icons/innocom-folder.png";
  }

  if (!mediaType) {
    return "/icons/folder.png";
  }

  if (mediaType === "shared_resource" && !!entity.original_media_type) {
    mediaType = entity.original_media_type;
  }



  return mediaTypesDefaultIcons[mediaType] || "/icons/document.png";
};