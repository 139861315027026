/***
 *
 *   ACCOUNTS
 *   Manage all accounts signed up to your application
 *
 **********/

import { Fragment, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { DataTable } from "@/components/ui/data-table";
import { Button } from "@/components/ui/button";
import { MoreHorizontal } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import moment from "moment";
import { CreateAccountDialog } from "./createAccountDialog";
import axios from "axios";
import { AuthContext } from "app/providers/auth";
import { usePlans } from "app/hooks/plans";
import { useToast } from "@/components/ui/use-toast";
import { ViewContext } from "components/view/view";

export function Accounts(props) {
  const { t } = useTranslation();
  const viewContext = useContext(ViewContext);
  const authContext = useContext(AuthContext);
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();

  const plans = usePlans();

  useEffect(() => {
    fetchAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchAccounts() {
    setLoading(true);
    const res = await axios.get("/api/accounts");
    setAccounts(res.data);
    setLoading(false);
  }

  function editAccount(data, callback) {
    viewContext.modal.show(
      {
        title: t("adminScreen.accounts.editAccount"),
        form: {
          id: {
            type: "hidden",
            value: data.id,
          },
          name: {
            label: t("adminScreen.accounts.name"),
            type: "text",
            value: data.name,
            default: data.name,
            required: true,
          },
          // plan: {
          //   label: "Plan",
          //   type: "select",
          //   options: plans.data.list,
          //   default: data.plan,
          //   required: true,
          // },
          active: {
            label: t("adminScreen.accounts.status"),
            type: "select",
            value: data.active,
            default: data.active ? 1 : 0,
            onChange: (value) => {
              return {
                active: value === 1,
              };
            },
            options: [
              { value: 1, label: t("adminScreen.accounts.active") },
              { value: 0, label: t("adminScreen.accounts.cancelled") },
            ],
            // required: true,
          },
        },
        buttonText: t("common.save"),
        url: `/api/account/${data.id}`,
        method: "PATCH",
      },
      (res) => {
        toast({
          title: t("adminScreen.accounts.userUpdated", { name: data.name }),
        });
        callback(res);
      }
    );
  }

  function deleteAccount(data, callback) {
    viewContext.modal.show(
      {
        title: t("adminScreen.accounts.deleteAccount"),
        form: {},
        buttonText: t("common.delete"),
        text: t("adminScreen.accounts.deleteConfirmation", { name: data.name }),
        url: `/api/account/${data.id}`,
        method: "DELETE",
        destructive: true,
      },
      () => {
        toast({
          title: t("adminScreen.accounts.accountDeleted", { name: data.name }),
        });
        callback();
      }
    );
  }

  function addAccount() {}

  return (
    <Fragment>
      <Card>
        <CardHeader className="px-7">
          <CardTitle>{t("adminScreen.accounts.title")}</CardTitle>
        </CardHeader>
        <CardContent>
          <DataTable
            searchField="name"
            actionButtons={
              <CreateAccountDialog onCreateAccount={fetchAccounts} />
            }
            columns={[
              {
                accessorKey: "name",
                header: t("adminScreen.accounts.name"),
              },
              {
                accessorKey: "date_created",
                header: t("adminScreen.accounts.dateCreated"),
                cell: ({ row }) => {
                  const user = row.original;
                  return (
                    <span>
                      {moment(user.date_created).format("YYYY-MM-DD HH:MM")}
                    </span>
                  );
                },
              },
              {
                accessorKey: "active",
                header: t("adminScreen.accounts.active"),
              },
              {
                id: "actions",
                enableHiding: false,
                cell: ({ row }) => {
                  const account = row.original;

                  return (
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          aria-haspopup="true"
                          size="icon"
                          variant="ghost"
                        >
                          <MoreHorizontal className="h-4 w-4" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end">
                        <DropdownMenuLabel>
                          {t("common.actions")}
                        </DropdownMenuLabel>
                        <DropdownMenuItem
                          onClick={(event) => {
                            event.stopPropagation();
                            editAccount(account, () => {
                              fetchAccounts();
                            });
                          }}
                        >
                          {t("common.edit")}
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          onClick={(event) => {
                            event.stopPropagation();
                            deleteAccount(account, () => {
                              fetchAccounts();
                            });
                          }}
                        >
                          {t("common.delete")}
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  );
                },
              },
            ]}
            data={accounts?.data || []}
            onRowClick={editAccount}
          />
        </CardContent>
      </Card>
    </Fragment>
  );
}
