/***
 *
 *   AUTH LAYOUT
 *   Layout for the signup/signin pages
 *
 **********/

import { Logo } from "components/logo/logo.js";
import Style from "./auth.tailwind.js";

export function AuthLayout(props) {
  return (
    <main className={Style.auth}>
      <div className="flex h-screen">
        <div className={Style.logoSection}>
          <Logo className="w-60 h-auto inline-block ml-8" />
        </div>
        <div className="grow flex align-center justify-center">
          <div className="w-full md:w-1/2">
            {<props.children {...props.data} />}
          </div>
        </div>
      </div>
    </main>
  );
}
