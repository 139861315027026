import { FC, memo, ReactElement } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { getEntityDefaultIcon } from "../MediaEntity/helpers";
import MediaToolTip from "./MediaToolTip";
import { MediaContentDataType } from "@/types";
import { Import } from "lucide-react";
import { Icon } from "../../icon/icon";

type MediaContentEntityPropsType = {
  entityData: MediaContentDataType; // !TODO: common
  isPackage: boolean;
  actionsMenuEnabled: boolean;
  onClick: (entityData: MediaContentDataType) => void;
  ActionsDropdown: ReactElement<any>;
};

const MediaContentEntity: FC<MediaContentEntityPropsType> = ({
  entityData,
  isPackage,
  actionsMenuEnabled,
  onClick,
  ActionsDropdown,
}) => {
  return (
    <Card className="h-[180px] w-[180px] overflow-hidden flex-col flex">

      <CardHeader className="flex flex-row p-0 items-center justify-between px-[12px] py-[12px]">
        <CardTitle className="text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden text-[#11203D]">
          {entityData.name}
        </CardTitle>
        {actionsMenuEnabled ? ActionsDropdown : <MediaToolTip />}
      </CardHeader>

      <CardContent
        onClick={() => onClick(entityData)}
        className="cursor-pointer border-1 w-full h-full border-blue-200 flex grow-1 p-0 px-[12px] pb-[12px] relative"
      >
        <div
          className={
            entityData.media_type === "shared_category"
              ? "w-full h-full flex justify-center items-center rounded-md bg-cover bg-center bg-no-repeat"
              : "border-[1px] w-full h-full border-grey-50 flex justify-center items-center bg-grey-background rounded-md bg-cover bg-center bg-no-repeat"
          }
          style={{ backgroundImage: `url(${entityData.blob_preview_url})` }}
        >
          {(entityData.media_type === "shared_category") && (
            <div
              className="flex h-40 w-40 -mt-5 bg-cover bg-center bg-no-repeat justify-center items-center"
              style={{
                backgroundImage: `url(${getEntityDefaultIcon(
                  isPackage,
                  entityData
                )})`,
              }}
            >
              {entityData?.icon?.length &&
              !entityData.is_package &&
              !entityData?.original_media_type ? (
                <Icon
                  pack="innocom"
                  color="#3A70D4"
                  image={`icon-${entityData?.icon}`}
                  className="mt-[5px] !text-[60px]"
                />
              ) : null}
            </div>
          )}

          {["shared_category", "shared_resource"].includes(entityData?.media_type as string) && (
            <Import
              color="#858992"
              className={`absolute ${"shared_resource" === entityData?.media_type
                ? "bottom-4 right-6"
                : "bottom-10 right-8"} `}
            />
          )}
          {!entityData.blob_preview_url &&
            (entityData.media_type !== "shared_category") && (
              <div
                className="w-[80px] h-[80px] p-0 bg-cover bg-center bg-no-repeat"
                style={{
                  backgroundImage: `url(${getEntityDefaultIcon(
                    isPackage,
                    entityData
                  )})`,
                }}
              />
            )}
          {entityData.blob_preview_url && entityData.media_type === "video" && (
            <div className="w-full h-full flex items-center justify-center bg-[#00000033]">
              <div
                className="w-[32px] h-[32px] p-0 bg-cover bg-center bg-no-repeat"
                style={{ backgroundImage: `url("/icons/play.png")` }}
              />
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default memo(MediaContentEntity);
