import Axios from "axios";
import { FC, memo, useCallback, useEffect, useRef, useState } from "react";
import { t } from "i18next";
import { throttle } from "lodash";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Textarea } from "@/components/ui/textarea";
import { ScrollAreaElement } from "@radix-ui/react-scroll-area";
import { Trash } from "lucide-react";
import ChatMessage from "./ChatMessage";
import useSSESubscription, { SSEEventType } from "app/hooks/useSSESubscription";
import {
  ChatMessageType,
  ChatRoomDataType,
  CurrentUserType,
} from "app/providers/stateProvider/types";

export type ChatRoomPropsType = {
  id: string | null;
  currentUserData: CurrentUserType;
  data: ChatRoomDataType;
  messages: ChatMessageType[] | null;
  controlled: boolean;
};

const ChatRoom: FC<ChatRoomPropsType> = ({
  messages = null,
  controlled = false,
  currentUserData,
  data,
}) => {
  const scrollAreaRef = useRef<ScrollAreaElement | null>(null);
  // const [messagesFetching, setMessagesFetching] = useState<boolean>(false);
  const [messageSending, setMessageSending] = useState<boolean>(false);
  const [messageText, setMessageText] = useState<string>("");
  const [chatMessages, setChatMessages] = useState<ChatMessageType[]>([]);

  useEffect(() => {
    // do not fetch the messages data when working in the controlled mode
    if (controlled) {
      return;
    }

    (async () => {
      try {
        // setMessagesFetching(true);

        if (data.roomData) {
          const messagesResult = await Axios.get(
            `/api/chatrooms/${data.roomData.id}/mesages`
          );

          if (!messagesResult.data) {
            throw new Error("failure getting room messages");
          }

          const messages = messagesResult.data as ChatMessageType[];
          setChatMessages(messages);
        } else {
          setChatMessages([]);
        }

        // setMessagesFetching(false);
      } catch (err) {
        console.error(err); // !DEBUG
        // setMessagesFetching(false);
      }
    })();
  }, [data.roomData, controlled]);

  // update the messsages data when working in the controlled mode
  useEffect(() => {
    if (controlled && messages) {
      setChatMessages(messages);
    }
  }, [controlled, messages]);

  const onSSEEvent = useCallback(
    (event: SSEEventType) => {
      if (controlled) {
        return;
      }
      if (event.eventType === "event_room_message_created") {
        const message = event.message as ChatMessageType;
        setChatMessages((messages: ChatMessageType[]) => {
          if (
            chatMessages[messages.length - 1]?.id === message.id ||
            event.message.room_id !== data?.roomData?.id
          ) {
            return messages;
          }

          return messages.concat(event.message);
        });
      }
    },
    [chatMessages, controlled, data?.roomData?.id]
  );

  // disable the SSE subscription for the controlled component
  useSSESubscription(onSSEEvent, !controlled);

  const mesagesCountRef = useRef<number>(0);
  useEffect(() => {
    if (chatMessages.length > mesagesCountRef.current) {
      setTimeout(() => {
        scrollAreaRef?.current?.scrollIntoView(false);
      }, 450);
    }
    mesagesCountRef.current = chatMessages.length;
  }, [chatMessages]);

  //eslint-disable-next-line
  const onSendMessageClick = useCallback(
    throttle(
      async () => {
        try {
          setMessageSending(true);

          if (!data.roomData && !data.userData) {
            throw new Error("error getting data for a new message");
          }

          if (data.userData) {
            const result = await Axios.post("/api/chatrooms", {
              members: [
                { memberType: "user", memberId: data.userData.id },
                { memberType: "user", memberId: currentUserData.id },
              ],
            });

            await Axios.post(`/api/chatrooms/${result.data.id}/mesages`, {
              message: messageText,
            });
          } else if (data.roomData) {
            await Axios.post(`/api/chatrooms/${data.roomData.id}/mesages`, {
              message: messageText,
            });
          }

          setMessageText("");
          setMessageSending(false);
        } catch (err) {
          console.error(err);
          setMessageSending(false);
        }
      },
      750,
      { trailing: false, leading: true }
    ),
    [data, messageText]
  );

  const renderRoomHeaderContent = useCallback(() => {
    if (!data.roomData && !data.userData) {
      return null;
    }

    // public room header
    if (data.roomData && data.roomData.creator_id) {
      return (
        <div className="flex w-full justify-between items-center">
          <p>{data.roomData.name}</p>
          {currentUserData.id === data.roomData.creator_id && (
            <Button
              onClick={() => {
                Axios.delete(`/api/chatrooms/${data.roomData?.id}`);
              }}
            >
              <Trash className="w-4 h-4" />
            </Button>
          )}
        </div>
      );
    }

    // private (1-1) room header
    const userContact =
      data.userData ||
      data.roomData?.users?.find(
        ({ id }: { id: string }) => id !== currentUserData.id
      );

    return <p className="text-gray">{userContact?.name}</p>;
  }, [data, currentUserData]);

  const getMessageUserName = useCallback(
    ({ user_id }: ChatMessageType) => {
      const roomUser = data?.roomData?.users?.find(
        ({ id }: { id: any }) => id === user_id
      );
      return roomUser?.name || "Deleted User";
    },
    [data.roomData]
  );

  return (
    <div className="flex flex-col w-full h-full border justify-around">
      <div className="flex flex-none items-center h-14 bg-slate-100 px-4">
        {renderRoomHeaderContent()}
      </div>
      <ScrollArea className="scroll-smooth flex flex-col flex-auto h-32 p-4 overflow-y-auto">
        {/* https://github.com/shadcn-ui/ui/discussions/2901 */}
        <div ref={scrollAreaRef} className="flex flex-1 flex-col h-full">
          {chatMessages.map((chatMessage) => (
            <ChatMessage
              userName={getMessageUserName(chatMessage)}
              createdAt={chatMessage.created_at}
              key={chatMessage.id}
              bIsMyMessage={currentUserData.id === chatMessage.user_id}
              text={chatMessage.text}
            />
          ))}
        </div>
      </ScrollArea>
      <div className="h-17 flex flex-none flex-row border p-4">
        <Textarea
          onChange={({ currentTarget }) => setMessageText(currentTarget.value)}
          value={messageText}
          rows={1}
          placeholder={t("chatScreen.chatComponent.messageInputPlaceholder")}
          className="focus:!ring-transparent flex flex-1 me-2 h-full !resize-none peer min-h-full"
        />
        <Button
          disabled={messageSending || !messageText.length}
          onClick={onSendMessageClick}
          className="flex flex-none self-end"
        >
          {t("chatScreen.chatComponent.sendButton")}
        </Button>
      </div>
    </div>
  );
};

export default memo(ChatRoom);
