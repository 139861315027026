export enum TreeItemType {
  TYPE_RESOURCE = "resource",
  TYPE_CATEGORY = "category",
  TYPE_ROOT = "root",
};

export type MediaTreeItem = {
  id: string;
  children?: MediaTreeItem[];
  type: TreeItemType;
  payload?: any;
};

export const findMediaTreeItem: (
  treeNode: MediaTreeItem,
  id: string
) => MediaTreeItem | null = (treeNode, id) => {
  if (treeNode.id === id) {
    return treeNode;
  } else if (treeNode.children) {
    let found = null;

    for (let i = 0, len = treeNode.children.length; i < len; i += 1) {
      found = findMediaTreeItem(treeNode.children[i], id);
      if (found) break;
    }

    return found;
  }

  return null;
};

export const composeMediaTreeData = (
  categoriesData: any = [],
  resourcesData: any = [],
  commonCategoriesData: any = [],
  commonResourcesData: any = [],
  rootItemId: string = "id_root",
  rootItemName: string = "root",
): [MediaTreeItem, Record<string, MediaTreeItem>] => {
  const mediaItemsById: Record<string, MediaTreeItem> = {}; // temp
  const root: MediaTreeItem = {
    id: rootItemId,
    type: TreeItemType.TYPE_ROOT,
    payload: { name: rootItemName },
    children: [],
  }; 

  // common ===================
  const commonRoot: MediaTreeItem = {
    id: "id_root_common",
    type: TreeItemType.TYPE_CATEGORY,
    payload: { name: rootItemName },
    children: [],
  };

  commonCategoriesData.forEach((category: { id: string, parent_id: string }) => {
    const categoryTreeItem: MediaTreeItem = {
      id: category.id,
      type: TreeItemType.TYPE_CATEGORY,
      payload: category,
    };

    mediaItemsById[category.id] = mediaItemsById[category.id]
      ? { ...categoryTreeItem, ...mediaItemsById[category.id] }
      : categoryTreeItem;

    if (!category.parent_id) {
      commonRoot.children = commonRoot.children
        ? commonRoot.children.concat(categoryTreeItem)
        : [categoryTreeItem];
    } else {
      if (mediaItemsById[category.parent_id]) {
        mediaItemsById[category.parent_id].children = Array.isArray(mediaItemsById[category.parent_id].children)
          ? mediaItemsById[category.parent_id].children?.concat(categoryTreeItem)
          : [categoryTreeItem]
      } else {
        mediaItemsById[category.parent_id] = {
          id: "",
          type: TreeItemType.TYPE_CATEGORY,
          children: [categoryTreeItem]
        };
      }
    }
  });

  commonResourcesData.forEach((mediaResource: any) => {
    const resourceTreeItem: MediaTreeItem = {
      id: mediaResource.id,
      type: TreeItemType.TYPE_RESOURCE,
      payload: mediaResource,
    };

    mediaItemsById[mediaResource.id] = mediaItemsById[mediaResource.id]
      ? { ...resourceTreeItem, ...mediaItemsById[mediaResource.id] }
      : resourceTreeItem;

    if (!mediaResource.category_id) {
      commonRoot.children = commonRoot.children
        ? commonRoot.children.concat(resourceTreeItem)
        : [resourceTreeItem];
    } else {
      if (mediaItemsById[mediaResource.category_id]) {
        mediaItemsById[mediaResource.category_id].children = Array.isArray(mediaItemsById[mediaResource.category_id].children)
          ? mediaItemsById[mediaResource.category_id].children?.concat(resourceTreeItem)
          : [resourceTreeItem]
      } else {
        mediaItemsById[mediaResource.category_id] = {
          id: "",
          type: TreeItemType.TYPE_CATEGORY,
          children: [resourceTreeItem]
        };
      }
    }
  });
  // <<<<<< common ===================

  categoriesData.forEach((category: { id: string, parent_id: string }) => {
    const categoryTreeItem: MediaTreeItem = {
      id: category.id,
      type: TreeItemType.TYPE_CATEGORY,
      payload: category,
    };

    mediaItemsById[category.id] = mediaItemsById[category.id]
      ? { ...categoryTreeItem, ...mediaItemsById[category.id] }
      : categoryTreeItem;

    if (!category.parent_id) {
      root.children = root.children
        ? root.children.concat(categoryTreeItem)
        : [categoryTreeItem];
    } else {
      if (mediaItemsById[category.parent_id]) {
        mediaItemsById[category.parent_id].children = Array.isArray(mediaItemsById[category.parent_id].children)
          ? mediaItemsById[category.parent_id].children?.concat(categoryTreeItem)
          : [categoryTreeItem]
      } else {
        mediaItemsById[category.parent_id] = {
          id: "",
          type: TreeItemType.TYPE_CATEGORY,
          children: [categoryTreeItem]
        };
      }
    }
  });

  resourcesData.forEach((mediaResource: any) => {
    let resourceTreeItem: MediaTreeItem = {
      id: mediaResource.id,
      type: TreeItemType.TYPE_RESOURCE,
      payload: mediaResource,
    };

    if (mediaResource.media_type === "shared_category"
      && !!mediaResource.linked_resource_id
      && mediaItemsById[mediaResource.linked_resource_id]
    ) {
      resourceTreeItem = {...mediaItemsById[mediaResource.linked_resource_id]};
    }

    if (mediaResource.media_type === "shared_resource"
      && !!mediaResource.linked_resource_id
    ) {
      resourceTreeItem.payload.linked_resource = commonResourcesData.find(
        ({ id }: { id: any }) => id === mediaResource.linked_resource_id
      );
    }

    mediaItemsById[mediaResource.id] = mediaItemsById[mediaResource.id]
      ? { ...resourceTreeItem, ...mediaItemsById[mediaResource.id] }
      : resourceTreeItem;

    if (!mediaResource.category_id) {
      root.children = root.children
        ? root.children.concat(resourceTreeItem)
        : [resourceTreeItem];
    } else {
      if (mediaItemsById[mediaResource.category_id]) {
        mediaItemsById[mediaResource.category_id].children = Array.isArray(mediaItemsById[mediaResource.category_id].children)
          ? mediaItemsById[mediaResource.category_id].children?.concat(resourceTreeItem)
          : [resourceTreeItem]
      } else {
        mediaItemsById[mediaResource.category_id] = {
          id: "",
          type: TreeItemType.TYPE_CATEGORY,
          children: [resourceTreeItem]
        };
      }
    }
  });

  return [root, mediaItemsById];
};
