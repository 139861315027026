/***
 *
 *   VIEW
 *   The view houses global components that are common to all views
 *   (notification, modal), handles errors and renders the layout
 *
 **********/

import { useState, createContext } from "react";
import { LoaderIcon } from "lucide-react";
import { CallDialog } from "@/components/ui/dialog/callDialog/callDialog";
import { MediaDialog } from "@/components/ui/dialog/mediaDialog/mediaDialog";
import { QrDialog } from "@/components/ui/dialog/qrDialog/qrDialog";
import { VideoDialog } from "@/components/ui/dialog/videoDialog/videoDialog";
import { DocumentDialog } from "@/components/ui/dialog/documentDialog/documentDialog";
import { AppLayout } from "components/layout/app/app";
import { AuthLayout } from "components/layout/auth/auth";
import { OnboardingLayout } from "components/layout/onboarding/onboarding";
import { Modal } from "components/modal/modal";

// import './scss/normalize.scss';
// import './scss/view.scss';
// import './scss/typography.scss';

export const ViewContext = createContext();

export function View(props) {
  // state
  const [modal, setModal] = useState({});
  const [mediaDialog, setMediaDialog] = useState({});
  const [qrDialog, setQrDialog] = useState({});
  const [videoDialog, setVideoDialog] = useState({});
  const [callDialog, setCallDialog] = useState({});
  const [documentDialog, setDocumentDialog] = useState({});
  const [loading, setLoading] = useState(false);

  // layouts
  const layouts = {
    app: AppLayout,
    auth: AuthLayout,
    onboarding: OnboardingLayout,
  };

  // set title & layout
  document.title = props.title;
  let Layout = props.layout ? layouts[props.layout] : AppLayout;

  if (!props.display) return false;

  function showMediaDialog(
    categoryId = null,
    callback = null,
    apiPrefix = "/api/media",
    params = null
  ) {
    const multiSelect = params?.multiSelect ?? false;
    const onlyPrivate = params?.onlyPrivate ?? false;
    const mediaType = params?.mediaType ?? null;
    const selectCategory = params?.selectCategory ?? false;
    setMediaDialog({
      ...mediaDialog,
      apiPrefix,
      categoryId,
      callback,
      onlyPrivate,
      mediaType,
      multiSelect,
      selectCategory,
      show: true,
    });
  }

  function hideMediaDialog() {
    setMediaDialog({
      ...mediaDialog,
      categoryId: null,
      callback: null,
      show: false,
    });
  }

  function showQrDialog(token) {
    setQrDialog({
      ...qrDialog,
      token,
      show: true,
    });
  }

  function hideQrDialog() {
    setQrDialog({
      ...qrDialog,
      token: null,
      show: false,
    });
  }

  function showVideoDialog(title, url) {
    setVideoDialog({
      ...videoDialog,
      title,
      url,
      show: true,
    });
  }

  function hideVideoDialog() {
    setVideoDialog({
      ...videoDialog,
      title: null,
      url: null,
      show: false,
    });
  }

  function showCallDialog(patientId, patientName) {
    setCallDialog({
      ...callDialog,
      patientId,
      patientName,
      show: true,
    });
  }

  function hideCallDialog() {
    setCallDialog({
      ...callDialog,
      patientId: null,
      patientName: null,
      show: false,
    });
  }

  function showDocumentDialog(title, url) {
    setDocumentDialog({
      ...documentDialog,
      title,
      url,
      show: true,
    });
  }

  function hideDocumentDialog() {
    setDocumentDialog({
      ...documentDialog,
      title: null,
      url: null,
      show: false,
    });
  }

  function showModal(content, callback) {
    let data = { ...modal };

    if (content) {
      for (let key in content) data[key] = content[key];

      data.show = true;
      data.callback = callback;
    }

    setModal(data);
  }

  function hideModal(cancel, res) {
    // callback?
    if (!cancel && modal.callback) modal.callback(modal.form, res);

    // reset the modal
    setModal({
      title: null,
      text: null,
      buttonText: null,
      url: null,
      method: null,
      show: false,
    });
  }

  const context = {
    modal: {
      show: showModal,
      hide: hideModal,
      data: modal,
    },
    mediaDialog: {
      show: showMediaDialog,
      hide: hideMediaDialog,
      data: mediaDialog,
    },
    qrDialog: {
      show: showQrDialog,
      hide: hideQrDialog,
    },
    videoDialog: {
      show: showVideoDialog,
      hide: hideVideoDialog,
    },
    callDialog: {
      show: showCallDialog,
      hide: hideCallDialog,
    },
    documentDialog: {
      show: showDocumentDialog,
      hide: hideDocumentDialog,
    },

    setLoading: (state) => setLoading(state),
  };

  return (
    <ViewContext.Provider value={context}>
      {loading && <LoaderIcon className="animate-spin" />}

      {modal.show && <Modal {...modal} />}

      {mediaDialog.show && (
        <MediaDialog
          {...mediaDialog}
          hide={mediaDialog.hide ?? hideMediaDialog}
        />
      )}

      {qrDialog.show && <QrDialog {...qrDialog} hide={hideQrDialog} />}

      {videoDialog.show && (
        <VideoDialog {...videoDialog} hide={hideVideoDialog} />
      )}

      {callDialog.show && <CallDialog {...callDialog} hide={hideCallDialog} />}

      {documentDialog.show && (
        <DocumentDialog {...documentDialog} hide={hideDocumentDialog} />
      )}

      <Layout title={props.title} data={props.data}>
        {props.display}
      </Layout>
    </ViewContext.Provider>
  );
}
