import { useContext, useState } from "react";
import Axios from "axios";
import { ClassHelper } from "app/utils";
import InputStyle from "../input/input.tailwind.js";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactSelect from "react-select";
import SelectStyle from "../select/select.tailwind.js";
import _ from "lodash";
import { Button } from "@/components/ui/button";
import { Trash } from "lucide-react";
import { Icon } from "@/components/ui/icon/icon";
import { ViewContext } from "components/view/view.js";
import { Label } from "../label/label.js";
import { Error } from "../error/error.js";

export function EventMetadataInput(props) {
  const { t } = useTranslation();
  const patientId = _.get(props, "formData.patient_id.value", null);
  const mediaApiPrefix = patientId
    ? `/api/user/${patientId}/media`
    : "/api/media";
  const [selectedFile, setSelectedFile] = useState("");
  const [playlists, setPlaylists] = useState([]);

  const context = useContext(ViewContext);
  let eventType = props.formData["event_type"];
  eventType = eventType.value ?? eventType.default;

  const resourceId = props.value?.resource_id ?? "";
  const categoryId = props.value?.category_id ?? "";

  useEffect(() => {
    const fetchResources = async () => {
      try {
        if (resourceId !== "") {
          const resource = await Axios.get(
            `/api/media/category/${categoryId || "root"}/resource/${resourceId}`
          );
          setSelectedFile(resource.data.name);
        }
      } catch (err) {
        console.error(err);
      }
    };

    const fetchPlaylists = async () => {
      try {
        const res = await Axios.get("/api/playlist");
        const playlists = res.data.map((p) => ({ value: p.id, label: p.name }));
        setPlaylists(playlists);
      } catch (err) {
        console.error(err);
      }
    };

    fetchResources();
    fetchPlaylists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const error = props.errorMessage || "Please enter a value";

  function valueToJSON(e) {
    if (eventType === "external_source") {
      return {
        link: e.target.value,
      };
    } else if (eventType === "media") {
      return {
        category_id: e.target.value.categoryId,
        resource_id: e.target.value.resourceId,
      };
    } else if (eventType === "playlist") {
      return {
        playlist_id: e.value,
      };
    } else {
      return {};
    }
  }

  function validate(e) {
    let value = e ? e.target.value : "";
    let valid = true;

    // input is required and value is blank
    if (props.required && eventType === "external_source" && value === "")
      valid = false;

    if (
      props.required &&
      eventType === "media" &&
      (!value.resource_id || value.resource_id === "")
    )
      valid = false;

    if (props.required && eventType === "playlist" && !value.category_id)
      valid = false;

    // update the parent form
    props.onChange?.(props.name, valueToJSON(e), valid);
  }

  function update(e) {
    const value = valueToJSON(e);
    const valid =
      eventType === "external_source" ||
      eventType === "media" ||
      eventType === "playlist"
        ? undefined
        : true;
    props.onChange?.(props.name, value, valid);
  }

  // style
  const textStyle = ClassHelper(InputStyle, {
    textbox: true,
    className: props.className,
    error: props.valid === false,
    success: props.valid === true,
    warning: props.warning,
  });

  const selectStyle = ClassHelper(SelectStyle, {
    select: props.valid !== false,
    error: props.valid === false,
    warning: props.warning,
  });

  const fileSelected = (categoryId, resourceId, filename) => {
    const e = {
      target: {
        value: {
          categoryId,
          resourceId,
        },
      },
    };
    update(e);
    setSelectedFile(filename);
  };

  if (
    eventType !== "external_source" &&
    eventType !== "media" &&
    eventType !== "playlist"
  ) {
    return null;
  }

  return (
    <div className={InputStyle.input}>
      {props.label && (
        <Label text={props.label} required={props.required} for={props.name} />
      )}

      {eventType === "external_source" && (
        <input
          type="text"
          id={props.id}
          name={props.name}
          value={props.value?.link ?? ""}
          className={textStyle}
          disabled={props.disabled}
          placeholder={props.placeholder}
          onChange={update}
          onBlur={(e) => validate(e)}
        />
      )}

      {eventType === "playlist" && (
        <ReactSelect
          styles={{
            control: (baseStyles, state) => ({
              ...baseStyles,
              padding: "0.5rem",
            }),
          }}
          options={playlists}
          isClearable={true}
          onChange={update}
          value={playlists.filter((p) => p.value === props.value?.playlist_id)}
          className={selectStyle}
          placeholder={t("common.selectOption")}
        />
      )}

      {eventType === "media" && (
        <div className="flex items-center justify-left">
          {selectedFile.length > 0 ? (
            <>
              <Label text={selectedFile} className="mr-2" />
              <Button
                variant="ghost"
                size="icon"
                onClick={(event) => {
                  event.preventDefault();
                  setSelectedFile("");
                }}
              >
                <Trash className="h-4 w-4" />
              </Button>
            </>
          ) : (
            <Button
              onClick={(event) => {
                event.preventDefault();
                context.mediaDialog.show(null, fileSelected, mediaApiPrefix, {
                  multiSelect: false,
                  onlyPrivate: true,
                });
              }}
            >
              {t("patientsScreen.eventForm.scheduler.choose")}
            </Button>
          )}
        </div>
      )}

      {props.valid === false && <Error message={error} />}

      {props.valid === true && (
        <Icon
          image="check"
          color="#8CC57D"
          className={InputStyle.successIcon}
          size={16}
        />
      )}
    </div>
  );
}
