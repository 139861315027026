import {
  useState,
  useEffect,
  useContext,
  useRef,
  useMemo,
} from "react";
import Axios from "axios";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Copy, Eye, Trash } from "lucide-react";
import { DataTable, RowDragHandleCell } from "@/components/ui/data-table";
import { Checkbox } from "@/components/ui/checkbox";
import { arrayMove } from "@dnd-kit/sortable";
import { AuthContext } from "app/providers/auth";
import { useError } from "app/utils";
import { apiAddPlaylistItems } from "@/api";
import PlaylistActionsDropdown from "@/components/common/dropdown/PlaylistActionsDropdown";
import { ViewContext } from "components/view/view";

export function Playlist(props) {
  const paginationStateRef = useRef({
    pageIndex: 0,
    pageSize: 20,
  });
  const { apiPrefix, categoryId, id, backToCategory, backToRoot } = props;
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const context = useContext(ViewContext);
  const [playlist, setPlaylist] = useState({});
  const [items, setItems] = useState([]);
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [refr, setTableRefresh] = useState(Date.now()); // !FIXME
  const [handleError] = useError();
  // const { toast } = useToast();

  const loadData = async () => {
    try {
      setLoading(true);

      const res = await Axios.get(
        `${apiPrefix}/category/${categoryId ?? "root"}/resource/${id}`
      );
      const playlist = res.data;
      const items = playlist.playlist.map((item) => {
        return {
          id: item.id,
          name: item.name,
          media_type: item.media_type,
          blob_url: item.blob_url,
          sort_order: item.sort_order,
          resource_id: item.resource_id,
        };
      });

      items.header = [
        {
          name: "name",
          title: t("mediaLibraryScreen.playlist.name"),
          sort: true,
        },
        {
          name: "media_type",
          title: t("mediaLibraryScreen.playlist.type"),
          sort: true,
        },
      ];

      setPlaylist(playlist);
      setItems(items);
      setLoading(false);
    } catch (error) {
      console.error(error);
      handleError(error);
    }
  };

  const addContent = () => {
    context.mediaDialog.show(
      null,
      async (itemsToAdd) => {
        try {
          let i = 1;

          for (const { resourceId } of itemsToAdd) {
            const newItem = {
              resource_id: resourceId,
              sort_order: items.length + i,
            };

            i += 1;

            await Axios.post(
              `${apiPrefix}/category/${
                categoryId ?? "root"
              }/resource/${id}/playlist`,
              newItem
            );
          }

          await loadData();
          setTableRefresh(Date.now()); // !FIXME
        } catch (error) {
          console.error(error);
          handleError(error);
        }
      },
      apiPrefix,
      {
        multiSelect: true,
        onlyPrivate: false,
        mediaType: "video",
      }
    );
  };

  const openContentPreview = (item) => {
    if (item.media_type === "video") {
      return context.videoDialog.show(item.name, item.blob_url);
    }
  };

  const deleteItem = async (item) => {
    if (
      !window.confirm(
        t("mediaLibraryScreen.playlist.deleteConfirmation", {
          name: item.name,
        })
      )
    ) {
      return;
    }

    // setLoading(true);
    try {
      await Axios.delete(
        `${apiPrefix}/category/${
          categoryId ?? "root"
        }/resource/${id}/playlist?items=${item.id}`
      );

      await loadData();
      setTableRefresh(Date.now()); // !FIXME
      // context.notification.show(
      //   t("mediaLibraryScreen.playlist.deleteNotification"),
      //   "success",
      //   true
      // );
    } catch (error) {
      console.error(error);
      handleError(error);
    } finally {
      // setLoading(false);
    }
  };

  const deleteAll = async (data, callback) => {
    if (
      !window.confirm(t("mediaLibraryScreen.playlist.deleteAllConfirmation"))
    ) {
      return;
    }

    const multi = Array.isArray(data);
    const items = multi
      ? data.map((x) => {
          return x.id;
        })
      : [data.id];

    try {
      await Axios.delete(
        `${apiPrefix}/category/${
          categoryId ?? "root"
        }/resource/${id}/playlist?items=${items.join(",")}`
      );
      await loadData();
      // context.notification.show(
      //   t("mediaLibraryScreen.playlist.deleteNotification"),
      //   "success",
      //   true
      // );
      setTableRefresh(Date.now()); // !FIXME
    } catch (error) {
      console.error(error);
      handleError(error);
    } finally {
      setLoading(false);
    }
  };

  // move while calling duplicate
  const duplicate = async (item) => {
    try {
      const itemToCloneInd = items.findIndex(({ id }) => id === item.id);
      const updatedPlaylist =
        itemToCloneInd === items.length - 1
          ? null
          : items.map((item, ind) =>
              ind > itemToCloneInd
                ? { ...item, sort_order: item.sort_order + 1 }
                : item
            );

      if (updatedPlaylist) {
        const updResult = await Axios.patch(
          `${apiPrefix}/category/${categoryId ?? "root"}/resource/${id}`,
          {
            playlist: updatedPlaylist,
          }
        );

        if (!updResult) {
          throw new Error("error updating playlist order before duplicating");
        }
      }

      const createResult = await Axios.post(
        `${apiPrefix}/category/${categoryId ?? "root"}/resource/${id}/playlist`,
        {
          resource_id: item.resource_id,
          sort_order: item.sort_order + 1,
        }
      );

      if (!createResult) {
        throw new Error("error creating new item on duplicate");
      }

      await loadData();
      setTableRefresh(Date.now()); // !FIXME
    } catch (error) {
      console.error(error);
      handleError(error);
    }
  };

  useEffect(() => {
    try {
      setLoading(true);

      // fetch parent category details to create breadcrumbs
      if (categoryId) {
        Axios.get(`${apiPrefix}/category/${categoryId ?? "root"}`).then(
          (res) => {
            setCategory(res.data);
          }
        );
      }

      Axios.get(
        `${apiPrefix}/category/${categoryId ?? "root"}/resource/${id}`
      ).then((res) => {
        const playlist = res.data;
        const items = playlist.playlist.map((item) => {
          return {
            id: item.id,
            name: item.name,
            media_type: item.media_type,
            blob_url: item.blob_url,
            sort_order: item.sort_order,
            resource_id: item.resource_id,
          };
        });

        setPlaylist(playlist);
        setItems(items);
        setLoading(false);
      });
    } catch (error) {
      console.error(error);
      handleError(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, id]);

  const updateSortOrder = async (items) => {
    try {
      await Axios.patch(
        `${apiPrefix}/category/${categoryId ?? "root"}/resource/${id}`,
        {
          playlist: items.map(({ id, resource_id, sort_order }) => ({
            id,
            resource_id,
            sort_order,
          })),
        }
      );
    } catch (err) {
      console.error(err);
      handleError(err);
    }
  };

  const onDragEnd = (oldIndex, newIndex) => {
    const newItems = arrayMove(items, oldIndex, newIndex).map((item, ind) => ({
      ...item,
      sort_order: ind + 1,
    }));

    setItems(newItems);
    updateSortOrder(newItems);
    setTableRefresh(Date.now()); // !FIXME
  };

  const onRequestSubmitPlaylist = async (selectedMediaResources) => {
    return await apiAddPlaylistItems(
      selectedMediaResources,
      playlist?.playlist?.length || 0,
      apiPrefix,
      id,
      categoryId
    );
  };

  const onSubmitPlaylist = async (errMsg, successMsg) => {
    if (successMsg) {
      // display toast freezes the drag-and-drop ability 
      // toast({ title: successMsg });
      await loadData();
      setTableRefresh(Date.now()); // !FIXME
    } else {
      // toast({
      //   title: errMsg,
      //   variant: "destructive",
      // });
    }
  };

  const canModifyLibraryContent = useMemo(() => {
    if (props.privateLibrary) {
      return !category || category.user_id;
    }

    // admin doesnt have perm
    if (props.package) {
      return authContext.permission?.master
        || authContext.permission?.owner;
    }

    // common library
    return (authContext.permission?.master
    || authContext.permission?.owner
    || authContext.permission?.admin) && !category?.is_package;
  }, [
    category,
    props.package,
    props.privateLibrary,
    authContext.permission,
  ]);

  return (
    <div className="flex flex-col flex-1 overflow-hidden">
      {/* !TODO: separate component */}
      <div className="flex flex-col w-full justify-between border-0 border-slate-200 py-4">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink onClick={backToRoot}>
                {t("mediaLibraryScreen.title")}
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            {category && (
              <>
                {category?.breadcrumbs.length > 0 && (
                  <>
                    <BreadcrumbItem>
                      <BreadcrumbEllipsis />
                    </BreadcrumbItem>
                    <BreadcrumbSeparator />
                  </>
                )}

                <BreadcrumbItem>
                  <BreadcrumbLink onClick={backToCategory}>
                    {category.name}
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
              </>
            )}
            <BreadcrumbItem>
              <BreadcrumbPage>{playlist.name}</BreadcrumbPage>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
      </div>

      <DataTable
        stickyHeader
        initialPaginationState={paginationStateRef.current}
        onPaginationStateUpdated={(s) => {
          paginationStateRef.current = s;
        }}
        key={refr}
        onRowDragEnd={canModifyLibraryContent && onDragEnd}
        actionButtons={
          <PlaylistActionsDropdown
            className="focus-visible:ring-0 focus:ring-0"
            canAddPrivate={props.privateLibrary}
            canAddPublic={
              ((props.package && playlist?.is_package)
              || (!props.privateLibrary && !props.package && !playlist?.is_package)
              || (props.privateLibrary && !!playlist?.user_id))
            }
            apiPrefix={apiPrefix}
            // disabled={!hasWriteAccess}
            disabled={!canModifyLibraryContent}
            onSubmitContent={onSubmitPlaylist}
            onRequestSubmitContent={onRequestSubmitPlaylist}
          />
        }
        onRowSelectionDelete={deleteAll}
        enableRowSelection={canModifyLibraryContent}
        columns={[
          {
            id: "drag-row",
            cell: ({ row }) => <RowDragHandleCell rowId={row.id} />,
            size: 40,
          },
          {
            id: "select",
            header: ({ table }) => (
              <Checkbox
                disabled={!canModifyLibraryContent}
                checked={
                  table.getIsAllPageRowsSelected() ||
                  (table.getIsSomePageRowsSelected() && "indeterminate")
                }
                onCheckedChange={(value) =>
                  table.toggleAllPageRowsSelected(!!value)
                }
              />
            ),
            cell: ({ row }) => (
              <Checkbox
                disabled={!canModifyLibraryContent}
                checked={row.getIsSelected()}
                onCheckedChange={(value) => row.toggleSelected(!!value)}
                aria-label="Select row"
              />
            ),
            enableSorting: false,
            enableHiding: false,
          },
          {
            accessorKey: "name",
            header: t("mediaLibraryScreen.playlist.name"),
            sort: true,
          },
          {
            accessorKey: "media_type",
            header: t("mediaLibraryScreen.playlist.type"),
            sort: true,
          },
          {
            id: "actions",
            enableHiding: false,
            cell: ({ row }) => {
              const video = row.original;

              return (
                <>
                  <Button
                    aria-haspopup="true"
                    size="icon"
                    variant="ghost"
                    onClick={(event) => {
                      event.stopPropagation();
                      openContentPreview(video);
                    }}
                  >
                    <Eye className="h-4 w-4" />
                  </Button>
                  <Button
                    disabled={!canModifyLibraryContent}
                    aria-haspopup="true"
                    size="icon"
                    variant="ghost"
                    onClick={(event) => {
                      event.stopPropagation();
                      duplicate(video);
                    }}
                  >
                    <Copy className="h-4 w-4" />
                  </Button>
                  <Button
                    disabled={!canModifyLibraryContent}
                    aria-haspopup="true"
                    size="icon"
                    variant="ghost"
                    onClick={(event) => {
                      event.stopPropagation();
                      deleteItem(video);
                    }}
                  >
                    <Trash className="h-4 w-4" />
                  </Button>
                </>
              );
            },
          },
        ]}
        data={items}
        searchField="name"
      />
    </div>
  );
}
