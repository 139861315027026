const Style = {
  select: "mb-4",
  success: "border-emerald-500 mb-0",
  error: "text-red-600",

  errorWrapper: `border-0 before:absolute before:left-0 before:right-0 before:top-0 before:bottom-4 
    before:rounded before:border before:border-solid before:border-red-500 after:-mt-3`,

  warning: "text-orange-500",
  warningWrapper: `border-0 before:absolute before:left-0 before:right-0 before:top-0 before:bottom-0
    before:rounded before:border before:border-solid before:border-orange-500`,

  message: "mb-0",
};

export default Style;
