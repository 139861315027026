import { t } from "i18next";
import { Pencil, Puzzle } from "lucide-react";

const dialogUiConfig = {
  gameNameFieldLabel: t("mediaLibraryScreen.addUpdateGameDialog.gameNameFieldLabel"),
  gameNameFieldPlaceholder: t("mediaLibraryScreen.addUpdateGameDialog.gameNameFieldPlaceholder"),
  gameTypeFieldLabel: t("mediaLibraryScreen.addUpdateGameDialog.gameTypeFieldLabel"),
  gameTypeFieldPlaceholder: t("mediaLibraryScreen.addUpdateGameDialog.gameTypeFieldPlaceholder"),
  gameNameFieldValidationMsg: t("mediaLibraryScreen.addUpdateGameDialog.gameNameFieldValidationMsg"),
  gameTypeFieldValidationMsg: t("mediaLibraryScreen.addUpdateGameDialog.gameTypeFieldValidationMsg"),
  createContext: {
    DialogTriggerIcon: Puzzle,
    dialogTriggerLabel: t("mediaLibraryScreen.addUpdateGameDialog.createContext.dialogTriggerLabel"),
    dialogHeaderTitle: t("mediaLibraryScreen.addUpdateGameDialog.createContext.dialogHeaderTitle"),
    dialogHeaderDescr: t("mediaLibraryScreen.addUpdateGameDialog.createContext.dialogHeaderDescr"),
    submitBtnLabel: t("mediaLibraryScreen.addUpdateGameDialog.createContext.submitBtnLabel"),
    successMsg: t("mediaLibraryScreen.addUpdateGameDialog.createContext.successMsg"),
    errMsg: t("mediaLibraryScreen.addUpdateGameDialog.createContext.errMsg"),
  },
  updateContext: {
    DialogTriggerIcon: Pencil,
    dialogTriggerLabel: t("mediaLibraryScreen.addUpdateGameDialog.updateContext.dialogTriggerLabel"),
    dialogHeaderTitle: t("mediaLibraryScreen.addUpdateGameDialog.updateContext.dialogHeaderTitle"),
    dialogHeaderDescr: t("mediaLibraryScreen.addUpdateGameDialog.updateContext.dialogHeaderDescr"),
    submitBtnLabel: t("mediaLibraryScreen.addUpdateGameDialog.updateContext.submitBtnLabel"),
    successMsg: t("mediaLibraryScreen.addUpdateGameDialog.updateContext.successMsg"),
    errMsg: t("mediaLibraryScreen.addUpdateGameDialog.updateContext.errMsg"),
  },
};

export default dialogUiConfig;