import { memo } from "react";
import { PlusCircle } from "lucide-react";
import { cn } from "@/lib/utils";
import { t } from "i18next";

type ActiveFilterValueTriggerPropsType<T> = {
  isOpen: boolean;
  selectedItems: { id: T; label: string; }[];
  className?: string;
  noActiveValuesLabel?: string;
};

const ActiveFilterValueTrigger = <T extends string>({
  selectedItems,
  className = "",
  noActiveValuesLabel = t("components.activeFiltersTrigger.label"),
}: ActiveFilterValueTriggerPropsType<T>) => {
  return (
    <div className={cn("flex flex-row items-center justify-between", className)}>
      <div className="flex w-[20px] h-[20px] items-center justify-center">
        <PlusCircle
          width={14}
          height={14}
        />
      </div>
      <span className="ml-[4px] mr-[8px] text-sm font-medium text-[#11203D]">
        {noActiveValuesLabel}
      </span>
      {(selectedItems.length > 0) && (
        <div className="border-l-[1px] border-[#E0E0E0] px-[8px]">
          <span className="px-[8px] py-[4px] bg-slate-200 rounded-md text-xs font-normal text-[#11203D]">
            {selectedItems.length === 1
              ? selectedItems[0].label
              : `${selectedItems.length} ${t("components.activeFiltersTrigger.selected")}`}
          </span>
        </div>
      )}
    </div>
  );
};

export default memo(ActiveFilterValueTrigger);
