export type MediaType = "shared_resource"
  | "shared_category"
  | "document"
  | "playlist"
  | "game"
  | "external_source"
  | "video"
  | "audio"
  | "image";

export type MediaTagType = "trainingLvlEasy"
  | "trainingLvlMedium"
  | "trainingLvlAdvanced"
  | "classLowerBody"
  | "classFullBody"
  | "classUpperBody"
  | "trainingTypeStrength"
  | "trainingTypeBalance"
  | "trainingTypeMobility"
  | "positionSitting"
  | "positionStanding"
  | "positionSittingStanding"
  | "learning"
  | "entertaiment";

export type GameType = "https://app.neuronup.com/play/activity/game/81/en_US"
| "https://app.neuronup.com/play/activity/game/90/en_US"
| "https://app.neuronup.com/play/activity/game/131/en_US"
| "https://app.neuronup.com/play/activity/game/79/en_US"
| "https://app.neuronup.com/play/activity/game/62/en_US"

// Kan dere gjøre klar disse spillene?
// https://app.neuronup.com/play/activity/game/66/en_US

// Defeat the Monster
// https://app.neuronup.com/play/activity/game/81/en_US
// Mole Invasion
// https://app.neuronup.com/play/activity/game/90/en_US
// The Builder
// https://app.neuronup.com/play/activity/game/131/en_US
// Card Pyramid
// https://app.neuronup.com/play/activity/game/79/en_US
// Sorting Bugs
// https://app.neuronup.com/play/activity/game/62/en_US


export type MediaContentDataType = {
  id: string;
  name?: string;
  blob_preview_url?: string;
  blob_name?: string;
  blob_url?: string;
  is_package?: boolean;
  media_type?: MediaType;
  original_media_type?: MediaType,
  tags?: string[];
  description?: string;
  icon?: string;
  modified_by?: string;
  modified_at?: string;
  created_by: string;
  created_at: string;
  user_id?: string;
  category_id?: string;
  account_id: string;
  linked_resource_id?: string;
  playlist: { id: string; resource_id: string; sort_order: number; }[];
  metadata?: { link: string };
  linked_resource?: any;
};

export type MediaCategoryDataType = {
  id: string;
  name: string;
  description?: string;
  icon?: string;
  created_by: string;
  created_at: string
  modified_by?: string;
  modified_at?: string;
  is_public: boolean;
  read_access: string[];
  is_package?: boolean;
  package_visible_to: string[];
  user_id?: string;
  root_category_id?: string;
  parent_id?: string;
  account_id: string;
};

export enum MediaContentSortOrderType {
  SORT_BY_DATE = "sortByDate",
  SORT_BY_NAME = "sortByName",
  SORT_BY_MEDIA_TYPE = "sortByMediaType"
};

export enum MediaLibraryInUseType {
  PATIENT_MEDIA_LIBRARY = "patient_media_library",
  ACCOUNT_MEDIA_LIBRARY = "account_media_library",
  PACKAGES_MEDIA_LIBRARY = "packages_media_library",
  UNKNOWN_MEDIA_LIBRARY = "unknown_media_library"
};

export type ActivityEventType = "media" | "external_source" | "other";
export const ActivityEventTypes = ["media", "external_source", "other"] as const;

export type ActivityRepeatType = "weekly" | "daily" | "never";
export const ActivityRepeatTypes = ["daily", "weekly", "never"] as const;
// const repeatOptions = ["never", "daily", "weekly", "monthly"];

export type ActivityWeekDayType = "mo" | "tu" | "we" | "th" | "fr" | "sa" | "su";
export const ActivityWeekDayTypes = ["mo", "tu", "we", "th", "fr", "sa", "su"] as const;

// const repeatByWeekdayOptions = [
//   { value: "mo", label: t("patientsScreen.eventForm.scheduler.mo") },
//   { value: "tu", label: t("patientsScreen.eventForm.scheduler.tu") },
//   { value: "we", label: t("patientsScreen.eventForm.scheduler.we") },
//   { value: "th", label: t("patientsScreen.eventForm.scheduler.th") },
//   { value: "fr", label: t("patientsScreen.eventForm.scheduler.fr") },
//   { value: "sa", label: t("patientsScreen.eventForm.scheduler.sa") },
//   { value: "su", label: t("patientsScreen.eventForm.scheduler.su") },
// ];
