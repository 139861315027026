import React, { Fragment, useContext, useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Accounts } from "./accounts";
import { AuthContext } from "app/providers/auth";
import { MediaEditor } from "@/components/ui/mediaEditor/mediaEditor";

export function Admin(props) {
  const { t } = useTranslation();
  // state/context
  const context = useContext(AuthContext);

  return (
    <main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
      <Tabs defaultValue="accounts">
        <div className="flex items-center">
          <TabsList>
            {/* <TabsTrigger value="dashboard">
              {t("adminScreen.dashboard.title")}
            </TabsTrigger> */}
            <TabsTrigger value="accounts">
              {t("adminScreen.accounts.title")}
            </TabsTrigger>
            <TabsTrigger value="packages">
              {t("adminScreen.packages.title")}
            </TabsTrigger>
            {/* <TabsTrigger value="feedback">
              {t("adminScreen.feedback.title")}
            </TabsTrigger>
            <TabsTrigger value="events">
              {t("adminScreen.events.title")}
            </TabsTrigger> */}
          </TabsList>
        </div>
        {/* <TabsContent value="dashboard">
          <Dashboard />
        </TabsContent> */}
        <TabsContent value="accounts">
          <Accounts />
        </TabsContent>
        <TabsContent value="packages">
          <MediaEditor apiPrefix="/api/package" package />
        </TabsContent>
      </Tabs>
    </main>
  );
}
