import { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import { Card } from "@/components/ui/card";

export function DocumentDialog(props) {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <CSSTransition in appear timeout={0} classNames="modal">
      <div
        className="modal"
        onClick={(e) => e.target === e.currentTarget && props.hide()}
      >
        <div className="modal-content overflow-y-auto">
          <Card title={props.title}>
            <iframe
              title="document"
              src={`${props.url}#toolbar=0&navpanes=0`}
              width="100%"
              height="750px"
            />
          </Card>
        </div>
      </div>
    </CSSTransition>
  );
}
