import { FC, memo, useState } from "react";
import { Icon, IconNames } from "@/components/ui/icon/icon";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

export type CategoryIconSelectorPropsType = {
  values: typeof IconNames,
  defaultValue: string;
  onValueChange: (value: typeof IconNames[number]) => void;
  placeholder?: string;
};

const CategoryIconSelector: FC<CategoryIconSelectorPropsType> = ({
  onValueChange,
  values,
  defaultValue,
  placeholder = "",
}) => {
  const [open, setOpen] = useState<boolean>(false);

  const onTriggerKeyDown = (e: any) => {
    if (open && e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      setOpen(false);
    }
  };
  
  return (
    <Select
      open={open}
      onOpenChange={setOpen}
      defaultValue={defaultValue}
      onValueChange={onValueChange}
    >
      <SelectTrigger
        onKeyDown={onTriggerKeyDown}
        className="w-full focus-visible:ring-0 focus:ring-0"
      >
        <SelectValue
          className="items-center bg-slate-200 justify-center h-full border-2 border-slate-200"
          placeholder={placeholder}
        />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup className="">
          {values.map((iconName) => {
            return (
              <SelectItem
                key={iconName}
                className="items-center"
                value={iconName}
              >
                <Icon
                  pack="innocom"
                  color="#020817"
                  image={`icon-${iconName}`}
                  className="!text-[32px] py-2"
                />
              </SelectItem>
            );
          })}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

export default memo(CategoryIconSelector);
