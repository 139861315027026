import React, {
  Fragment,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { DataTable } from "@/components/ui/data-table";
import { Button } from "@/components/ui/button";
import { MoreHorizontal, PlusCircle } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { usePermissions } from "app/hooks/permissions";
import { useToast } from "@/components/ui/use-toast";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { useError } from "app/utils";
import { ViewContext } from "components/view/view";

export function Groups(props) {
  const { t } = useTranslation();
  const context = useContext(ViewContext);
  const permissions = usePermissions();
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [handleError] = useError();
  const { toast } = useToast();

  function editGroup(data, callback) {
    context.modal.show(
      {
        title: t("groupsScreen.groups.update.title"),
        form: {
          id: {
            type: "hidden",
            value: data.id,
          },
          name: {
            label: t("groupsScreen.groups.update.name"),
            type: "text",
            required: true,
            value: data.name,
            errorMessage: t("groupsScreen.groups.update.nameError"),
          },
          permission: {
            label: t("groupsScreen.groups.update.permission"),
            type: "select",
            required: true,
            options: permissions?.data?.list?.filter(
              (x) =>
                x.value !== "owner" &&
                x.value !== "developer" &&
                x.value !== "user" &&
                x.value !== "patient"
            ),
            default: data.permission,
          },
          users: {
            type: "checkbox",
            label: t("groupsScreen.groups.update.users"),
            min: 0,
            default: data.users,
            options: users.map((x) => x.name),
            values: users.map((x) => x.id),
          },
        },
        buttonText: t("groupsScreen.groups.update.button"),
        url: "/api/group",
        method: "PATCH",
      },
      (res) => {
        toast({
          title: t("groupsScreen.groups.update.message", { name: data.name }),
        });
      }
    );
  }

  const deleteGroup = async (data) => {
    try {
      await axios.delete(`/api/group/${data.id}`);

      fetchGroups();
      toast({
        title: t("groupsScreen.groups.delete.message", { name: data.name }),
      });
    } catch (err) {
      handleError(err);
    }
  };

  function addGroup() {
    context.modal.show(
      {
        title: t("groupsScreen.groups.add.title"),
        form: {
          name: {
            label: t("groupsScreen.groups.add.name"),
            type: "text",
            required: true,
            value: "",
            errorMessage: t("groupsScreen.groups.add.nameError"),
          },
          permission: {
            label: t("groupsScreen.groups.add.permission"),
            type: "select",
            required: true,
            options: permissions?.data?.list?.filter(
              (x) =>
                x.value !== "owner" &&
                x.value !== "developer" &&
                x.value !== "user" &&
                x.value !== "patient"
            ),
            default: "",
          },
        },
        buttonText: t("groupsScreen.groups.add.button"),
        url: "/api/group",
        method: "POST",
      },
      (res) => {
        toast({
          title: t("groupsScreen.groups.add.message"),
        });
        fetchGroups();
      }
    );
  }

  const fetchUsers = async () => {
    const res = await axios.get("/api/account/users");
    setUsers(res.data?.data?.users ?? []);
  };

  const fetchGroups = async () => {
    setLoading(true);
    const res = await axios.get("/api/group");
    let list = res.data.map((x) => {
      return {
        id: x.id,
        name: x.name,
        permission: x.permission,
        users: x.users,
      };
    });

    setLoading(false);
    setGroups(list);
  };

  useEffect(() => {
    fetchGroups();
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Card>
        <CardHeader className="px-7">
          <CardTitle>{t("groupsScreen.title")}</CardTitle>
        </CardHeader>
        <CardContent>
          <DataTable
            searchField="name"
            actionButtons={
              <Button className="gap-1" onClick={addGroup}>
                <PlusCircle className="h-3.5 w-3.5" />
                <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                  {t("groupsScreen.groups.addGroup")}
                </span>
              </Button>
            }
            columns={[
              {
                accessorKey: "name",
                header: t("groupsScreen.groups.table.name"),
              },
              {
                accessorKey: "permission",
                header: t("groupsScreen.groups.table.permission"),
                cell: ({ row }) => {
                  const group = row.original;
                  return <span>{t(`permission.${group.permission}`)}</span>;
                },
              },
              {
                id: "actions",
                enableHiding: false,
                cell: ({ row }) => {
                  const group = row.original;

                  return (
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button
                          aria-haspopup="true"
                          size="icon"
                          variant="ghost"
                        >
                          <MoreHorizontal className="h-4 w-4" />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end">
                        <DropdownMenuLabel>
                          {t("common.actions")}
                        </DropdownMenuLabel>
                        <DropdownMenuItem
                          onClick={(event) => {
                            event.stopPropagation();
                            editGroup(group);
                          }}
                        >
                          {t("common.edit")}
                        </DropdownMenuItem>
                        <AlertDialog>
                          <AlertDialogTrigger asChild>
                            <DropdownMenuItem
                              onSelect={(e) => e.preventDefault()}
                            >
                              {t("common.delete")}
                            </DropdownMenuItem>
                          </AlertDialogTrigger>
                          <AlertDialogContent>
                            <AlertDialogHeader>
                              <AlertDialogTitle>
                                {t("groupsScreen.groups.delete.title")}
                              </AlertDialogTitle>
                              <AlertDialogDescription>
                                {t("groupsScreen.groups.delete.description", {
                                  name: group.name,
                                })}
                              </AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                              <AlertDialogCancel>
                                {t("common.cancel")}
                              </AlertDialogCancel>
                              <AlertDialogAction
                                onClick={() => deleteGroup(group)}
                              >
                                {t("groupsScreen.groups.delete.button")}
                              </AlertDialogAction>
                            </AlertDialogFooter>
                          </AlertDialogContent>
                        </AlertDialog>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  );
                },
              },
            ]}
            data={groups}
            // onRowClick={editGroup}
          />
        </CardContent>
      </Card>
    </Fragment>
  );
}
