/***
 *
 *   FORGOT PASSWORD
 *   Trigger a password reset process
 *
 **********/

import { useTranslation } from "react-i18next";
import { Card } from "@/components/ui/card";
import { Row } from "components/layout/row/row";
import { Form } from "components/form/form";

export function ForgotPassword(props) {
  const { t } = useTranslation();
  return (
    <Row title={t("signInScreen.forgotPassword.title")}>
      <Card className="border-none shadow-none">
        <p className="mb-5">{t("signInScreen.forgotPassword.description")}</p>

        <Form
          inputs={{
            email: {
              label: t("signInScreen.forgotPassword.email"),
              type: "email",
              required: true,
            },
          }}
          url="/api/auth/password/reset/request"
          method="POST"
          buttonText={t("signInScreen.forgotPassword.button")}
        />
      </Card>
    </Row>
  );
}
