import { memo, useState } from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
} from "@/components/ui/select";
import { SortAsc } from "lucide-react";
import { cn } from "@/lib/utils";

type SelectorValueType<T> = {
  id: T;
  name: string;
};

export type SortOrderSelectorPropsType<T> = {
  disabled?: boolean;
  values: SelectorValueType<T>[],
  defaultValue: T;
  onValueChange: (id: T) => void;
  placeholder?: string;
  className?: string;
};

const SortOrderSelector = <T extends string>({
  disabled = false,
  onValueChange,
  values,
  defaultValue,
  placeholder = "",
  className = "",
}: SortOrderSelectorPropsType<T>) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue || values[0].id);

  const handleValueChange = (value: T) => {
    setSelectedValue(value);
    onValueChange && onValueChange(value);
  }

  return (
    <Select
      disabled={disabled}
      defaultValue={defaultValue}
      onValueChange={handleValueChange}
    >
      <SelectTrigger
        disabled={disabled}
        hideChevron
        className={cn("w-[120px] overflow-hidden focus-visible:ring-0 focus:ring-0 ml-[12px]", className)}
      >
        <div className="flex flex-1 flex-row items-center mr-[8px]">
          <SortAsc
            color="#11203D"
            className="h-4 w-4 mr-2"
          />
          <span className="text-[#11203D] text-sm font-normal truncate">
            {values.find(({ id }) => id === selectedValue)?.name ?? placeholder} 
          </span>
        </div>
        {/* <SelectValue
          className="items-center bg-slate-200 justify-center h-full border-2 border-slate-200"
          placeholder={placeholder}
        /> */}
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {values.map((value) => {
            return (
              <SelectItem
                className="items-center"
                value={value.id}
              >
                <span>
                  {value.name}
                </span>
              </SelectItem>
            );
          })}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

export default memo(SortOrderSelector);