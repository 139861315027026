import moment from "moment";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export type ChatMessagePropsType = {
  text: string;
  userName: string;
  bIsMyMessage: boolean;
  createdAt: string;
};

const ChatMessage: FC<ChatMessagePropsType> = ({
  text,
  userName,
  bIsMyMessage = false,
  createdAt,
}) => {
  const { t } = useTranslation();

  const contaienerClass = `
    flex ${
      bIsMyMessage ? "ml-auto bg-emerald-100" : "mr-auto bg-sky-100"
    } flex-col w-fit min-w-[25%] max-w-[50%] px-4 py-4 my-4 rounded-lg
  `;

  return (
    <div className={contaienerClass}>
      <p className="font-semibold text-sm whitespace-nowrap text-ellipsis overflow-hidden">
        {bIsMyMessage ? t("chatScreen.chatComponent.me") : userName}
      </p>
      <p className="my-2 leading-4 font-normal">{text}</p>
      <p className="text-xs self-end font-light">
        {moment(createdAt).format("YYYY-MM-DD HH:mm")}
      </p>
    </div>
  );
};

export default ChatMessage;
