export function Group(props) {
  let { children, parent, orientation } = props;
  children = Array.isArray(children) ? children : [children];
  orientation = orientation?.toLowerCase() ?? "row";
  const maxCols = orientation === "column" ? children.length : 1;
  const gap = orientation === "column" ? 4 : 0;

  return (
    <div className={`grid ${orientation === "column" ? 'grid-flow-col': 'grid-flow-row'} grid-cols-${orientation === "column" ? maxCols : 1} gap-${gap}`}>
      {children.map((el, idx) => (
        <div key={`${parent}_control_${idx}`}>{el}</div>
      ))}
    </div>
  );
}
