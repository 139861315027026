import { AppContext } from "app/providers/stateProvider";
import { getTotalUnreadCount } from "app/providers/stateProvider/helpers";
import { useContext, useMemo } from "react";

const useUnreadMessagesCount = (roomId?: string) => {
  const appContext = useContext(AppContext);

  return useMemo(() => {
    if (!appContext || !appContext.chat.chatState.currentUserData) {
      return 0;
    }

    let roomsToLookup = appContext.chat.chatState.chatRooms;

    if (roomId) {
      const room = appContext.chat.chatState.chatRooms.find(
        ({ roomData, id }) => roomData && id === roomId
      );
      if (room) roomsToLookup = [room];
    }

    return getTotalUnreadCount(
      appContext.chat.chatState.currentUserData,
      roomsToLookup
    );
  }, [appContext, roomId]);
};

export default useUnreadMessagesCount;
