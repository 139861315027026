import { OnboardingView } from "views/onboarding/onboarding";
import i18n from "../i18n";

const Routes = [
  {
    path: "/welcome",
    view: OnboardingView,
    layout: "onboarding",
    permission: "user",
    title: i18n.t("onboardingScreen.title"),
  },
];

export default Routes;
