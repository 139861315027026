const Style = {
  base: "max-w-sm p-6 border border-slate-200 rounded-lg shadow flex justify-between flex-col relative select-none cursor-pointer",
  cardContainer: "flex flex-row justify-between",
  cardTitleContainer: "flex flex-col",
  privateCategoryCard: "bg-teal-200 border-teal-200",
  packageCategoryCard: "bg-yellow-200 border-yellow-200",
  categoryCard: "bg-slate-200",
  privateFileCard: "bg-teal-100 border-teal-100",
  packageFileCard: "bg-yellow-100 border-yellow-100",
  fileCard: "bg-slate-100 border-slate-100",
  cardTitle: "mb-2 text-sm font-bold",
  mediaType: "text-sm text-gray-400",
  selected: "border-2 !border-blue-500",
};

export default Style;
