import { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { MoreHorizontal, PlusCircle } from "lucide-react";
import { DataTable } from "@/components/ui/data-table";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { useError } from "app/utils";
import { ViewContext } from "components/view/view";

export function Doctors() {
  const { t } = useTranslation();
  const context = useContext(ViewContext);
  const params = useParams();
  const [users, setUsers] = useState([]);
  const [groups, setGroups] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [loading, setLoading] = useState(false);
  const { toast } = useToast();
  const [handleError] = useError();

  const refreshDoctors = async () => {
    try {
      setLoading(true);
      let res = await Axios.get("/api/doctor/users");
      setUsers(res.data ?? []);

      res = await Axios.get("/api/doctor/groups");
      setGroups(res.data ?? []);

      res = await Axios.get(`/api/patient/${params.patientId}/doctor`);
      const data = res.data ?? [];
      let list = data.map((x) => {
        return {
          name: x.name,
          type: x.type,
          id: x.id,
        };
      });

      setLoading(false);
      setDoctors(list);
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    refreshDoctors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addDoctor = () => {
    context.modal.show(
      {
        title: t("patientsScreen.doctors.modalAdd.title"),
        form: {
          users: {
            type: "checkbox",
            label: t("patientsScreen.doctors.modalAdd.users"),
            min: 0,
            default: doctors.filter((x) => x.type === "user").map((x) => x.id),
            options: users.map((x) => x.name),
            values: users.map((x) => x.id),
          },
          groups: {
            type: "checkbox",
            label: t("patientsScreen.doctors.modalAdd.groups"),
            min: 0,
            default: doctors.filter((x) => x.type === "group").map((x) => x.id),
            options: groups.map((x) => x.name),
            values: groups.map((x) => x.id),
          },
        },
        buttonText: t("patientsScreen.doctors.modalAdd.button"),
        url: `/api/patient/${params.patientId}/doctor`,
        method: "PUT",
      },
      refreshDoctors
    );
  };

  const deleteDoctor = (data, callback) => {
    context.modal.show(
      {
        title: t("patientsScreen.doctors.revoke.title"),
        form: {},
        buttonText: t("patientsScreen.doctors.revoke.button"),
        text: t("patientsScreen.doctors.revoke.label", { name: data.name }),
        url: `/api/patient/${params.patientId}/doctor/${data.id}`,
        method: "DELETE",
        destructive: true,
      },
      () => {
        toast({
          title: t("patientsScreen.doctors.revoke.notification", {
            name: data.name,
          }),
        });
        refreshDoctors();
      }
    );
  };

  return (
    <DataTable
      stickyHeader
      actionButtons={
        <Button
          variant="secondary"
          className="gap-1 focus-visible:ring-0"
          onClick={addDoctor}
          size="default"
        >
          <PlusCircle className="h-3.5 w-3.5" />
          <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
            {t("patientsScreen.doctors.modalAdd.title")}
          </span>
        </Button>
      }
      columns={[
        {
          accessorKey: "name",
          header: t("patientsScreen.access.table.name"),
          sort: true,
        },
        {
          accessorKey: "type",
          header: t("patientsScreen.doctors.table.type"),
          cell: ({ row }) => {
            const user = row.original;
            return <span>{t(`patientsScreen.doctors.type.${user.type}`)}</span>;
          },
        },
        {
          id: "actions",
          enableHiding: false,
          cell: ({ row }) => {
            const user = row.original;

            return (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button aria-haspopup="true" size="icon" variant="ghost">
                    <MoreHorizontal className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuLabel>
                    {t("accountScreen.users.table.actions")}
                  </DropdownMenuLabel>
                  <DropdownMenuItem
                    onClick={(event) => {
                      event.stopPropagation();
                      deleteDoctor(user);
                    }}
                  >
                    {t("accountScreen.users.table.delete")}
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            );
          },
        },
      ]}
      data={doctors}
      searchField="name"
    />
  );
}
