import { z } from "zod";
import dialogUiConfig from "./dialogUiConfig";
// import { GameType } from "@/types";

const GameFormSchema = z.object({
  gameName: z.string().min(1, {
    message: dialogUiConfig.gameNameFieldValidationMsg,
  }),
  gameLinkValue: z.string().min(1, {
    message: dialogUiConfig.gameTypeFieldValidationMsg,
  }),
});
// staticGamesConfig // GameType // MediaTagType

// folderIcon: z.enum(IconNames as [string, ...string[]], {
//   message: dialogUiConfig.folderIconFieldValidationMsg,  
// }),

export default GameFormSchema;