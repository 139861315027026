import { Card, CardContent } from "@/components/ui/card";
import ChatComponent from "../../@/components/ui/chat/ChatComponent";

export function Chat(props) {
  return (
    <main className="grid flex-1 gap-4 p-4 sm:px-6 sm:py-0 md:gap-8 h-full">
      <Card>
        <CardContent className="h-full p-2 border-none">
          <ChatComponent userId={null} />
        </CardContent>
      </Card>
    </main>
  );
}
