import { Users } from "views/account/users";
import { Groups } from "views/account/groups";
import { Settings } from "views/account/settings/settings";
import i18n from "../i18n";
import { Account } from "views/account";

const Routes = [
  {
    path: "/account/profile",
    view: Account,
    layout: "app",
    permission: "user",
    title: i18n.t("profileScreen.title"),
  },
  {
    path: "/account/groups",
    view: Groups,
    layout: "app",
    permission: "user",
    title: i18n.t("groupsScreen.title"),
  },
  {
    path: "/account/users",
    view: Users,
    layout: "app",
    permission: "admin",
    title: i18n.t("accountScreen.users.title"),
  },
  {
    path: "/account/settings",
    view: Settings,
    layout: "app",
    permission: "admin",
    title: i18n.t("accountScreen.settings.title"),
  },
];

export default Routes;
