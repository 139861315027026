import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "app/providers/auth";
import { Card } from "@/components/ui/card";
import { Form } from "components/form/form";
import { Row } from "components/layout/row/row";

export function SigninOTP(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const context = useContext(AuthContext);

  const token = location?.search?.substring(7);
  if (!token) navigate("/signin");

  return (
    <Row
      title={t("signInScreen.otp.title")}
      desc={t("signInScreen.otp.description")}
    >
      <Card className="border-none shadow-none">
        <Form
          method="post"
          url="/api/auth/otp"
          inputs={{
            code: {
              type: "text",
              label: t("signInScreen.otp.verificationCode"),
              required: true,
            },
            jwt: {
              type: "hidden",
              value: token,
            },
          }}
          buttonText={t("signInScreen.otp.completeButton")}
          callback={context.signin}
        />

        <div className="mt-4">{t("signInScreen.otp.lostCodeMessage")}</div>
      </Card>
    </Row>
  );
}
