import {
  ChatMessageType,
  ChatRoomDataType,
  ChatRooomType,
  CurrentUserType,
  UserType,
} from "./types";

export enum ChatActions {
  SET_SELECTED_ROOM_MESSAGES = "set_selected_room_messages",
  ACTION_SELECT_ROOM = "action_select_room",
  INITIAL_STATE_REQUEST = "initial_state_request",
  INITIAL_STATE_SUCCESS =  "initial_state_success",
  INITIAL_STATE_FAILURE = "initial_state_failure",
  ADD_CHAT_ROOM = "add_chat_room",
  REMOVE_CHAR_ROOM = "remove_char_room",
  UPDATE_ROOM_LAST_MESSAGE = "update_room_last_message",
  UPDATE_CURRENT_USER_DATA = "update_current_user_data",
}

export type ChatStateType = {
  initialStateFetching: boolean;
  selectedRoomId: string | null;
  currentUserData: CurrentUserType | null;
  chatRooms: ChatRoomDataType[];
  selectedRoomMessages: ChatMessageType[];
};

export type ChatAction = 
  { type: ChatActions.SET_SELECTED_ROOM_MESSAGES; messages: ChatMessageType[]; }
 | { type: ChatActions.ACTION_SELECT_ROOM; selectedRoomId: string | null; }
 | { type: ChatActions.INITIAL_STATE_REQUEST; }
 | { type: ChatActions.INITIAL_STATE_SUCCESS; currentUserData: CurrentUserType; chatRooms: ChatRoomDataType[]; }
 | { type: ChatActions.INITIAL_STATE_FAILURE; }
 | { type: ChatActions.ADD_CHAT_ROOM; roomData: ChatRooomType; }
 | { type: ChatActions.UPDATE_ROOM_LAST_MESSAGE; message: ChatMessageType; }
 | { type: ChatActions.UPDATE_CURRENT_USER_DATA; userData: UserType; }
 | { type: ChatActions.REMOVE_CHAR_ROOM; roomId: string; };

export type ChatReducerType = (
  state: ChatStateType,
  action: ChatAction
) => ChatStateType;

// could be moved to the application root in the future
const reducer: ChatReducerType = (state, action) => {
  switch(action.type) {
    case ChatActions.SET_SELECTED_ROOM_MESSAGES:
      return {
        ...state,
        selectedRoomMessages: action.messages,
      };
    case ChatActions.ACTION_SELECT_ROOM: {
      const { selectedRoomId } = action;
      const { currentUserData, chatRooms } = state;

      const createdRoomToSelect = selectedRoomId
        ? chatRooms.find(({ roomData }) => roomData && roomData.id === selectedRoomId)
        : undefined;

      return {
        ...state,
        selectedRoomId,
        selectedRoomMessages: [],
        currentUserData: (selectedRoomId && createdRoomToSelect && currentUserData) ? {
          ...currentUserData,
          rooms_activity: {
            ...currentUserData.rooms_activity,
            [selectedRoomId]: {
              timestamp: createdRoomToSelect?.roomData?.last_message_timestamp || "",
              messages_count: createdRoomToSelect?.roomData?.messages_count || 0
            },
          }
        } : currentUserData,
      };
    }
    case ChatActions.UPDATE_CURRENT_USER_DATA:
      return {
        ...state,
        currentUserData: action.userData, // types mismatch
      };
    case ChatActions.INITIAL_STATE_REQUEST:
      return {
        ...state,
        selectedRoomId: null,
        currentUserData: null,
        chatRooms: [],
        initialStateFetching: true,
      };
    case ChatActions.INITIAL_STATE_SUCCESS:
      return {
        ...state,
        currentUserData: action.currentUserData,
        chatRooms: action.chatRooms,
        initialStateFetching: false,
      };
    case ChatActions.INITIAL_STATE_FAILURE:
      return {
        ...state,
        initialStateFetching: false,
      };

    case ChatActions.ADD_CHAT_ROOM: {
      const { roomData } = action;
      const { chatRooms, currentUserData, selectedRoomId } = state;

      if (!roomData.creator_id && !roomData.name) {
        const createdRoomContactMember = roomData.members.find(({ memberId }) => memberId !== currentUserData?.id)
        const virtualRoomToExclude = chatRooms.find(
          (chatRoom) => chatRoom.userData && chatRoom.userData.id === createdRoomContactMember?.memberId 
        );

        // !FIXME
        const createdChatRoom: ChatRoomDataType = {
          roomData,
          id: roomData.id,
          userData: undefined
        };

        return {
          ...state,
          chatRooms: [createdChatRoom].concat(
            chatRooms.filter((chatRoom) => chatRoom.roomData
              || !roomData.members.find(({ memberId }) => memberId === chatRoom?.userData?.id))
          ),
          selectedRoomId: virtualRoomToExclude?.id === selectedRoomId
            ? roomData.id
            : selectedRoomId 
        };
      }

      // !FIXME
      const createdChatRoom: ChatRoomDataType = {
        roomData,
        userData: undefined,
        id: roomData.id,        
      };

      return {
        ...state,
        selectedRoomId: roomData.creator_id === currentUserData?.id
          ? roomData.id
          : selectedRoomId, 
        chatRooms: !chatRooms.find((chatRoom) => chatRoom?.roomData?.id === roomData.id)
          ? [createdChatRoom].concat(chatRooms)
          : chatRooms,
      };
    }
    case ChatActions.REMOVE_CHAR_ROOM: {
      const { roomId } = action;
      const { chatRooms, selectedRoomId } = state;
      return {
        ...state,
        chatRooms: chatRooms.filter((chatRoom) => chatRoom?.roomData?.id !== roomId), 
        selectedRoomId: selectedRoomId === roomId ? null : roomId,
      };
    }
    case ChatActions.UPDATE_ROOM_LAST_MESSAGE: {
      const { message } = action;
      const { chatRooms, selectedRoomId, selectedRoomMessages, currentUserData } = state;

      return {
        ...state,
        // update current user data room activity only if the message sender or if it's in the room now
        currentUserData: (currentUserData && (message.room_id === selectedRoomId || message.user_id === currentUserData?.id)) ? {
          ...currentUserData,
          rooms_activity: {
            ...currentUserData.rooms_activity,
            [message.room_id]: {
              timestamp: message.created_at,
              messages_count: currentUserData.rooms_activity?.[message.room_id]
                ? currentUserData.rooms_activity[message.room_id].messages_count + 1
                : 1 
            } ,
          }
        } : currentUserData,
        // update the last timestamp and messges count for the room
        chatRooms: chatRooms.map((chatRoom) => (chatRoom?.roomData?.id === message.room_id)
          ? {
              ...chatRoom,
              roomData: {
                ...chatRoom.roomData,
                messages_count: chatRoom.roomData.messages_count + 1,
                last_message_timestamp: message.created_at,
            }}
          : chatRoom
        ),
        // update the messages list
        selectedRoomMessages: (message.room_id === selectedRoomId
          && selectedRoomMessages[selectedRoomMessages.length-1]?.id !== message.id)
          ? selectedRoomMessages.concat(message)
          : selectedRoomMessages, 
      };
    }

    default:
      return state;
  }
};

export const initalState = {
  initialStateFetching: false,
  selectedRoomId: null,
  currentUserData: null,
  chatRooms: [],
  selectedRoomMessages: [],
};

export default reducer;