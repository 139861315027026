import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { t } from "i18next";
import { PlusCircle } from "lucide-react";
import { FC, memo, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import Axios from "axios";

export type InviteType = {
  id: string;
  email: string;
  permission: string;
  account_id: string;
  date_sent: string;
  used: boolean;
  patient_id?: string;
};

type DialogPropsType = {
  patientId: string;
  onInviteSent: () => void;
};

const InviteFamilyMemberDialog: FC<DialogPropsType> = ({
  patientId,
  onInviteSent,
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const FormSchema = z.object({
    name: z
      .string()
      .min(2, { message: t("patientsScreen.access.modalInvite.errorName") }),
    email: z
      .string()
      .email(t("patientsScreen.access.modalInvite.errorEmail"))
      .transform((emailVal) => emailVal.trim().toLowerCase()),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: "",
      email: "",
    },
  });

  const onSubmit = useCallback(async (data: any) => {
    try {
      const result = await Axios.post("/api/invite/family", {
        name: data.name,
        email: data.email,
        targetPatientId: patientId,
        permission: "familyMember",
      });

      if (!result.data) {
        throw new Error("error inviting a family member");
      }

      setDialogOpen(false);
      form.reset();
      onInviteSent();
    } catch (err) {
      form.setError("email", {
        message: t("patientsScreen.access.modalInvite.errorSubmit"),
      });
      console.error(err);
    }
    // eslint-disable-next-line
  }, []);

  const onOpenChange = useCallback((open: boolean) => {
    if (!open) {
      form.reset();
    }

    setDialogOpen(open);
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog open={dialogOpen} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>
        <Button
          variant="secondary"
          className="gap-1 focus-visible:ring-0"
          size="default"
        >
          <PlusCircle className="h-3.5 w-3.5" />
          <span>{t("patientsScreen.access.modalInvite.triggerButton")}</span>
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[42twx]">
        <DialogHeader>
          <DialogTitle>
            {t("patientsScreen.access.modalInvite.title")}
          </DialogTitle>
        </DialogHeader>
        <div className="py-4">
          <div className="items-center">
            <Form {...form}>
              <FormField
                name="name"
                render={({ field }) => {
                  return (
                    <FormItem className="my-3">
                      <FormLabel>
                        {t("patientsScreen.access.modalInvite.labelName")}
                      </FormLabel>
                      <FormControl>
                        <Input
                          className="focus-visible:ring-0"
                          placeholder={t(
                            "patientsScreen.access.modalInvite.namePlaceholder"
                          )}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
              <FormField
                name="email"
                render={({ field }) => {
                  return (
                    <FormItem>
                      <FormLabel>
                        {t("patientsScreen.access.modalInvite.labelEmail")}
                      </FormLabel>
                      <FormControl>
                        <Input
                          className="focus-visible:ring-0"
                          placeholder={t(
                            "patientsScreen.access.modalInvite.emailPlaceholder"
                          )}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  );
                }}
              />
            </Form>
          </div>
        </div>
        <DialogFooter>
          <DialogClose>
            <Button
              className="focus-visible:ring-0"
              type="button"
              variant="secondary"
            >
              {t("common.cancel")}
            </Button>
          </DialogClose>
          <Button
            className="focus-visible:ring-0"
            type="submit"
            onClick={form.handleSubmit(onSubmit)}
          >
            {t("patientsScreen.access.modalInvite.button")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default memo(InviteFamilyMemberDialog);
