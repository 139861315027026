// import { t } from "i18next";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Badge } from "@/components/ui/badge";
import { cn } from "@/lib/utils";
import { FC, memo } from "react";

type ChatPrivateRoomCardPropsType = {
  isSelected: boolean;
  onSelect: (id: string) => void;
  unreadCount: number;
  currentUserData: { id: string };
  data: {
    id: string;
    roomData: any;
    userData: any;
  };
};

const getUserInitials = (userName: string = "") => {
  return userName
    .split(" ")
    .map((chunk) => chunk.charAt(0).toLocaleUpperCase())
    .slice(0, 2)
    .join("");
};

const ChatPrivateRoomCard: FC<ChatPrivateRoomCardPropsType> = memo(
  ({
    isSelected = false,
    onSelect,
    unreadCount = 0,
    currentUserData,
    data, // roomData / userData / id
  }) => {
    const { roomData, userData, id } = data;

    if (!roomData && !userData) {
      return null;
    }

    const userContact =
      userData ||
      roomData?.users?.find(
        ({ id }: { id: string }) => id !== currentUserData.id
      );

    if (!userContact) {
      return null;
    }

    return (
      <div
        onClick={() => onSelect(id)}
        className={`cursor-pointer relative w-60 h-20 rounded-lg flex items-center border p-2 my-2 transition-all hover:bg-accent ${
          isSelected && "bg-muted"
        }`}
      >
        <Avatar className="me-4">
          <AvatarImage src={userContact.avatar} alt="@avatar" />
          <AvatarFallback>{getUserInitials(userContact.name)}</AvatarFallback>
        </Avatar>
        <div className="flex-1 overflow-hidden">
          <p className="whitespace-nowrap text-ellipsis overflow-hidden">
            {userContact.name}
          </p>
          <Badge variant="secondary">
            {userContact.permission || "?permission"}
          </Badge>
        </div>
        {unreadCount > 0 ? (
          <Badge
            variant="destructive"
            className="absolute -right-2 -top-1 z-10"
          >
            {unreadCount < 100 ? unreadCount : "99+"}
          </Badge>
        ) : null}
      </div>
    );
  }
);

type ChatPublicRoomCardPropsType = {
  isSelected: boolean;
  onSelect: (id: string) => void;
  unreadCount: number;
  currentUserData: { id: string };
  data: {
    id: string;
    roomData: any;
    userData: any;
  };
};

const MAX_AVATARS_TO_DISPLAY = 5;

const ChartPublicRoomCard: FC<ChatPublicRoomCardPropsType> = ({
  isSelected = false,
  onSelect,
  unreadCount = 0,
  currentUserData,
  data, // roomData / userData / id
}) => {
  const { roomData, userData, id } = data;

  console.log("data >>>", data); // !DEBUG

  if (!roomData || userData) {
    return null;
  }

  const roomUsersToDisplay =
    roomData.users > 2
      ? roomData.users?.filter(
          ({ id }: { id: string }) => id !== currentUserData.id
        )
      : roomData.users;

  return (
    <div
      onClick={() => onSelect(id)}
      className={cn(
        "cursor-pointer relative w-60 h-20 rounded-lg flex-col items-center border p-2 my-2 transition-all hover:bg-accent",
        isSelected && "bg-muted"
      )}
    >
      <p className="text-s whitespace-nowrap text-ellipsis overflow-hidden mb-2 ml-2">
        {roomData.name}
      </p>
      <div className="flex -space-x-1.5 *:ring *:ring-black">
        {roomUsersToDisplay
          .slice(0, MAX_AVATARS_TO_DISPLAY)
          .map((roomUser: any) => (
            <Avatar key={roomUser.id} className="h-7 w-7">
              <AvatarImage src={roomUser.avatar} alt="@avatar" />
              <AvatarFallback className="text-sm font-medium">
                {getUserInitials(roomUser.name)}
              </AvatarFallback>
            </Avatar>
          ))}
        {roomUsersToDisplay.length > MAX_AVATARS_TO_DISPLAY && (
          <Badge variant="secondary" className="z-10 font-semibold text-sx">
            +{roomUsersToDisplay.length - MAX_AVATARS_TO_DISPLAY}
          </Badge>
        )}
      </div>
      {unreadCount > 0 ? (
        <Badge variant="destructive" className="absolute -right-2 -top-1 z-10">
          {unreadCount < 100 ? unreadCount : "99+"}
        </Badge>
      ) : null}
    </div>
  );
};

export { ChatPrivateRoomCard, ChartPublicRoomCard };
