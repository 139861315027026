/***
 *
 *   SIGN UP STEP 2
 *   Signup form for account owners
 *   Step 1: create account
 *   Step 2: verify email address
 *   Step 3: select plan
 *
 **********/

import React, { useContext } from "react";
import { Row, Card, PaymentForm, Link } from "components/lib";
import { useTranslation } from "react-i18next";
import { AuthContext } from "app/providers/auth";
import { useNavigate } from "react-router-dom";
import { usePlans } from "app/hooks/plans";
import { Event } from "app/utils/event";

export function SignupPlan(props) {
  const { t } = useTranslation();
  const context = useContext(AuthContext);
  const navigate = useNavigate();
  const plans = usePlans();
  const plan = window.location.hash.substring(1);

  if (!plans.data) return false;

  return (
    <Row title={t("signUpScreen.plan.title")}>
      <Card loading={false} noPadding center>
        <PaymentForm
          inputs={{
            plan: {
              label: t("signUpScreen.plan.plan"),
              type: "select",
              options: plans.data.list,
              default: plan,
              required: true,
            },
            token: {
              label: t("signUpScreen.plan.creditCard"),
              type: "creditcard",
              required: true,
            },
          }}
          url="/api/account/plan"
          method="POST"
          buttonText={t("signUpScreen.plan.button")}
          callback={(res) => {
            // save the plan to context, then redirect
            Event("selected_plan", { plan: res.data.plan });
            context.update({
              plan: res.data.plan,
              subscription: res.data.subscription,
            });
            navigate(res.data.onboarded ? "/patients" : "/welcome");
          }}
        />

        <div className="mt-4">
          <Link
            url="/account/profile"
            text={t("signUpScreen.plan.manageAccount")}
          />
        </div>
      </Card>
    </Row>
  );
}
