import { useState, useEffect, useContext } from "react";
import { Loader2 } from "lucide-react";
import Axios from "axios";
import _ from "lodash";

import { ClassHelper } from "app/utils";
import { Button } from "@/components/ui/button";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Style from "./form.tailwind.js";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useError } from "app/utils";
import { EventMetadataInput } from "./metadata/eventMetadata.js";
import { TextInput } from "./input/text.js";
import { ViewContext } from "components/view/view.js";
import { EmailInput } from "./input/email.js";
import { NumberInput } from "./input/number.js";
import { URLInput } from "./input/url.js";
import { DateInput } from "./input/date/date.js";
import { HiddenInput } from "./input/hidden.js";
import { PhoneInput } from "./input/phone.js";
import { PasswordInput } from "./input/password.js";
import { CardInput } from "./input/card.js";
import { Fieldset } from "./fieldset/fieldset.js";
import { Select } from "./select/select.js";
import { Switch } from "./switch/switch.js";
import { FormHeader } from "./header/header.js";
import { FormLink } from "./link/link.js";
import { FileInput } from "./file/file.js";
import { TimeInput } from "./input/time.js";
import { DateTimeInput } from "./input/datetime.js";
import { MediaMetadataInput } from "./metadata/mediaMetadata.js";
import { SchedulerInput } from "./scheduler/scheduler.js";
import { Group } from "./group.js";

function Form(props) {
  const { t } = useTranslation();
  // context & state
  const context = useContext(ViewContext);
  const [form, setForm] = useState(null);
  const [initialised, setInitialised] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileStore, setFileStore] = useState([]);
  const [processCreditCard, setProcessCreditCard] = useState(false);
  const navigate = useNavigate();
  const [handleError] = useError();
  let valid = true;

  // inputs map
  const Inputs = {
    text: TextInput,
    textarea: TextInput,
    email: EmailInput,
    number: NumberInput,
    url: URLInput,
    date: DateInput,
    hidden: HiddenInput,
    phone: PhoneInput,
    password: PasswordInput,
    creditcard: CardInput,
    radio: Fieldset,
    select: Select,
    checkbox: Fieldset,
    selector: Fieldset,
    switch: Switch,
    header: FormHeader,
    link: FormLink,
    file: FileInput,
    time: TimeInput,
    datetime: DateTimeInput,
    eventMetadata: EventMetadataInput,
    mediaMetadata: MediaMetadataInput,
    scheduler: SchedulerInput,
  };

  function flattenForm(form) {
    const flattened = {};
    const controlsToVisit = [];
    Object.keys(form).forEach((key, idx) => {
      const control = form[key];
      control.name = key;
      control.order = idx;
      controlsToVisit.push(control);
    });

    while (controlsToVisit.length > 0) {
      const control = controlsToVisit.pop();
      if (control.type === "group") {
        Object.keys(control.controls).forEach((key, idx) => {
          const childControl = control.controls[key];
          childControl.name = key;
          childControl.group = control.name;
          childControl.order = idx;
          controlsToVisit.push(childControl);
        });
      }

      flattened[control.name] = control;
    }

    const sortedKeys = Object.keys(flattened).sort((a, b) => {
      return flattened[a].order - flattened[b].order;
    });
    const result = _.pick(flattened, sortedKeys);
    return result;
  }

  useEffect(() => {
    // form has not been set - init
    if (!initialised) {
      let data = flattenForm(props.inputs);

      // init credit card
      if (data?.token) {
        data?.plan?.default === "free"
          ? setProcessCreditCard(false)
          : setProcessCreditCard(true);
      }

      setInitialised(true);
      setForm(data);
    } else if (initialised) {
      // form is already set - update it
      const updatedForm = flattenForm(props.inputs);
      setForm((f) => ({ ...updatedForm, ...f }));
    }
  }, [props, valid, initialised]);

  if (!form) return false;

  function update(input, value, valid) {
    let data = { ...form };

    // is it a file?
    if (value?.length && value[0].name && value[0].type && value[0].size) {
      if (!fileStore[input]?.length) fileStore[input] = [];

      const newFiles = { ...fileStore };
      value.forEach((file) => {
        // add or delete the file
        if (file.data && !fileStore[input].find((x) => x.name === file.name)) {
          newFiles[input].push(file);
        } else if (!file.data) {
          newFiles[input].splice(
            newFiles[input].findIndex((x) => x.name === file.name),
            1
          );
        }
      });

      data[input].value = newFiles[input];
      data[input].valid = valid;
      setFileStore(newFiles);
    } else {
      // update input value & valid state
      data[input].value = value;
      data[input].valid = valid;

      // handle related fields
      if (data[input].onChange) {
        const changes = data[input].onChange(value);
        for (const key in changes) {
          data[key].value = changes[key];
          data[key].valid = undefined;
        }
      }

      // hide credit card input when selecting free plan
      if (props.inputs.token) {
        if (input === "plan" && value === "free") {
          setProcessCreditCard(false);
        } else if (input === "plan" && value !== "free") {
          setProcessCreditCard(true);
        }
      }
    }

    setForm(data);

    props.updateOnChange &&
      props.onChange({ input: input, value: value, valid: valid });

    props.submitOnChange && submit();
  }

  function validate() {
    // loop over each input and check it's valid
    // show error if input is required and value is
    // blank, input validation will be executed on blur

    let errors = [];
    let data = { ...form };

    // loop the inputs
    for (let input in data) {
      // validate credit card
      if (input === "token") {
        if (processCreditCard && !data.token.value) {
          data.token.valid = false;
          errors.push(false);
        } else {
          data.token.valid = true;
        }
      } else {
        // standard input
        let inp = data[input];
        if (inp.value === undefined && inp.default) {
          data[input].value = inp.default;
        }

        // special case: event types
        if (
          inp.type === "metadata" &&
          (data["event_type"].value === "group_call" ||
            data["event_type"].value === "medicine")
        ) {
          continue;
        }

        // special case: scheduler
        if (
          input === "repeat_interval" &&
          data["repeat"].value !== "daily" &&
          data["repeat"].value !== "monthly"
        ) {
          continue;
        }

        if (
          input === "repeat_by_weekday" &&
          data["repeat"].value !== "weekly"
        ) {
          continue;
        }

        // special case: media library playlists and games do not require file upload
        if (
          input === "blob" &&
          (data["media_type"].value === "playlist" ||
            data["media_type"].value === "game" ||
            data["media_type"].value === "external_source")
        ) {
          continue;
        }

        if (inp.required) {
          if (!inp.value || inp.value === "unselected") {
            inp.valid = false;
            errors.push(false);
          } else {
            inp.valid = true;
          }
        }

        if (inp.valid === false) {
          errors.push(false);
        }
      }
    }

    if (errors.length) {
      // form isn't valid
      valid = false;
      setForm(data);
      return false;
    } else {
      // form is valid
      return true;
    }
  }

  async function submit(event) {
    console.log("submitting form", event);
    event?.preventDefault();
    // submit the form
    setLoading(true);
    let data = {};
    Object.keys(form).forEach((key) => {
      if (form[key].type !== "group") {
        data[key] = { ...form[key] };
      }
    });

    // create the credit card token
    if (processCreditCard) {
      const cardElement = await props.elements.getElement(CardElement);
      const token = await props.stripe.createToken(cardElement);
      data.token.value = token.token;
    }

    // is the form valid?
    if (!validate()) {
      setLoading(false);
      return false;
    }

    // optimise data for server
    for (let input in form) {
      if (processCreditCard && input === "token") {
        // procress credit card
        data[input] = form[input].value;
      } else if (input !== "header") {
        // process single input & ignore headers
        data[input] = form[input].value;
      }
    }

    delete data.header;

    // submit the form or execute callback
    if (!props.url && !props.submit) {
      if (props.callback) props.callback(null);

      return false;
    }

    // special case: metadata
    for (const key in data) {
      if (key === "metadata" && data[key] === "") {
        data[key] = {};
      }
    }

    try {
      let res = null;
      if (props.submit && _.isFunction(props.submit)) {
        // custom submit function
        res = await props.submit(data);
      } else {
        // standard form submit
        let formData = new FormData(),
          headers = {};
        if (Object.keys(fileStore).length) {
          headers["Content-Type"] = "multipart/form-data";
          headers["Accept"] = "application/json";

          for (let key in data) {
            // append files
            if (
              Array.isArray(data[key]) &&
              data[key][0].hasOwnProperty("data")
            ) {
              for (let i = 0; i < data[key].length; i++) {
                formData.append(key, data[key][i].data);
              }
            } else {
              // append text values
              formData.append(key, data[key] ?? "");
            }
          }

          data = formData;
        }

        res = await Axios({
          method: props.method,
          url: props.url,
          data: data,
        });

        // check for 2-factor payment requirement
        if (res.data.requires_payment_action) {
          const stripeRes = await props.stripe.handleCardPayment(
            res.data.client_secret
          );

          if (stripeRes.error) {
            setLoading(false);
            handleError(stripeRes.error.message);
            return false;
          } else {
            // re-send the form
            data.stripe = res.data;
            res = await Axios({
              method: props.method,
              url: props.url,
              data: data,
            });
          }
        }
      }

      // finish loading
      setLoading(false);

      // close the modal
      context.modal.hide(false, res.data.data);

      // callback?
      if (props.callback) props.callback(res);

      // redirect?
      if (props.redirect) navigate(props.redirect);

      // success notification
      // !TEST
      // if (res.data.message)
      //   context.notification.show(res.data.message, "success", true);
    } catch (err) {
      // handle error
      setLoading(false);
      context.modal.hide(true);

      // show error on input
      if (err.response?.data?.inputError) {
        let data = { ...form };
        const input = err.response.data.inputError;
        data[input].valid = false;
        data[input].errorMessage = err.response.data.message;
        valid = false;
        setForm(data);
        return false;
      } else {
        // general errors handled by view
        handleError(err);
      }
    }
  }

  async function remove(event) {
    event.preventDefault();
    // eslint-disable-next-line no-restricted-globals
    if (!confirm(t("common.sureDelete"))) {
      return;
    }
    await props.delete();
  }

  let inputsToRender = [];
  const formStyle = ClassHelper(Style, {
    ...props,
    ...{
      loading: props.loading || loading,
    },
  });

  // map the inputs
  Object.keys(form)
    .filter((name) => !form[name].group)
    .map((name) => {
      // get the values for this input
      const data = form[name];
      data.name = name;
      inputsToRender.push(data);
      return inputsToRender;
    });

  const renderInputs = (inputsToRender) => {
    return inputsToRender.map((input) => {
      if (input.type === null) return false;

      if (!input.type) input.type = "text";

      if (input.type === "creditcard" && !processCreditCard) return false;

      if (input.type === "group") {
        const groupInputs = [];
        Object.keys(form)
          .filter((name) => form[name].group === input.name)
          .map((name) => {
            // get the values for this input
            const data = form[name];
            data.name = name;
            groupInputs.push(data);
            return groupInputs;
          });
        return (
          <Group
            key={input.name}
            parent={input.name}
            orientation={input.orientation}
          >
            {renderInputs(groupInputs)}
          </Group>
        );
      }

      const Input = Inputs[input.type];
      let visible = true;
      if (_.isFunction(input.visible)) {
        visible = input.visible(form);
        console.log("visible", visible);
      }

      if (!visible) return false;

      return (
        <Input
          key={input.name}
          type={input.type}
          form={props.name}
          label={input.label}
          className={input.class}
          name={input.name}
          value={input.value}
          required={input.required}
          valid={input.valid}
          min={input.min}
          max={input.max}
          disabled={input.disabled}
          options={input.options}
          values={input.values}
          default={input.default}
          url={input.url}
          text={input.text}
          title={input.title}
          accept={input.accept}
          description={input.description}
          readonly={input.readonly}
          maxFileSize={input.maxFileSize}
          handleLabel={input.handleLabel}
          placeholder={input.placeholder}
          errorMessage={input.errorMessage}
          onChange={update}
          complexPassword={input.complexPassword}
          visible={input.visible}
          formData={form}
        />
      );
    });
  };

  // render the form
  return (
    <form
      action={props.action}
      method={props.method}
      onSubmit={submit}
      className={formStyle}
      encType={fileStore.length && "multipart/form-data"}
      noValidate
    >
      {renderInputs(inputsToRender)}

      <div className="flex justify-end mt-2 pt-4 border-t-2">
        {props.delete && (
          <Button variant="destructive" onClick={remove}>
            {t("common.delete")}
          </Button>
        )}

        {props.cancel && (
          <Button variant="ghost" onClick={props.cancel}>
            {t("common.cancel")}
          </Button>
        )}

        {props.buttonText && (
          <Button
            variant={props.destructive ? "destructive" : ""}
            className="bg-[#3C6099]"
            onClick={submit}
            fullWidth={!props.cancel && !props.delete}
          >
            {loading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : null}
            {props.buttonText}
          </Button>
        )}
      </div>
    </form>
  );
}

function PaymentForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  return <Form {...props} stripe={stripe} elements={elements} />;
}

export { Form, PaymentForm };
