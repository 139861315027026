import { FC, memo } from "react";
import { Button } from "@/components/ui/button";
import { MoreHorizontal } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { t } from "i18next";
import { TableDataItemType } from "./types";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";

type UserActionsDropdownPropsType = {
  user: TableDataItemType;
  onRemoveUserClick: (user: TableDataItemType) => void;
};

const UserActionsDropdown: FC<UserActionsDropdownPropsType> = memo(
  ({ user, onRemoveUserClick }) => {
    return (
      <>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button aria-haspopup="true" size="icon" variant="ghost">
              <MoreHorizontal className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>
              {t("accountScreen.users.table.actions")}
            </DropdownMenuLabel>
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                  {t("common.delete")}
                </DropdownMenuItem>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>
                    {t("patientsScreen.access.revoke.title")}
                  </AlertDialogTitle>
                  <AlertDialogDescription>
                    {t("patientsScreen.access.revoke.label", {
                      name: user.name,
                    })}
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>{t("common.cancel")}</AlertDialogCancel>
                  <AlertDialogAction onClick={() => onRemoveUserClick(user)}>
                    {t("patientsScreen.access.revoke.button")}
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </DropdownMenuContent>
        </DropdownMenu>
      </>
    );
  }
);

type InviteActionsDropdownPropsType = {
  user: TableDataItemType;
  onRemoveUserInvite: (user: TableDataItemType) => void;
  onResendUserInvite: (user: TableDataItemType) => void;
};

const InviteActionsDropdown: FC<InviteActionsDropdownPropsType> = memo(
  ({ user, onRemoveUserInvite, onResendUserInvite }) => (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button aria-haspopup="true" size="icon" variant="ghost">
          <MoreHorizontal className="h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>
          {t("accountScreen.users.table.actions")}
        </DropdownMenuLabel>
        <DropdownMenuItem
          onClick={(event) => {
            event.stopPropagation();
            onRemoveUserInvite(user);
          }}
        >
          {t("accountScreen.users.table.delete")}
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={(event) => {
            event.stopPropagation();
            onResendUserInvite(user);
          }}
        >
          {t("accountScreen.users.table.resend")}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
);

export { UserActionsDropdown, InviteActionsDropdown };
