import { useState, useEffect } from "react";
import { Card } from "@/components/ui/card";
import { CSSTransition } from "react-transition-group";
import QRCode from "qrcode";

export function QrDialog(props) {
  const [source, setSource] = useState("");
  const { token } = props;

  useEffect(() => {
    if (!token) {
      setSource("");
      return;
    }

    QRCode.toDataURL(token, { errorCorrectionLevel: "H", width: 512 })
      .then((url) => setSource(url))
      .catch((err) => console.error(err));
  }, [token]);

  return (
    <CSSTransition in appear timeout={0} classNames="modal">
      <div
        className="modal"
        onClick={(e) => e.target === e.currentTarget && props.hide()}
      >
        <div className="modal-content overflow-y-auto">
          <Card>
            <div className="flex flex-row justify-center items-center">
              <img
                src={source}
                alt="Scan the QR code to login to the mobile app"
                className="w-64 h-64"
              />
            </div>
          </Card>
        </div>
      </div>
    </CSSTransition>
  );
}
