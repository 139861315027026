import { memo, useState } from "react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../select";
import { cn } from "@/lib/utils";

type SelectOptionType<T> = { label: string; value: T; };

type CommonSingleSelectPropsType<T> = {
  options: SelectOptionType<T>[];
  disabled?: boolean;
  defaultValue?: T;
  onValueChanged: (value: T) => void;
  className?: string;
  renderOption?: (option: SelectOptionType<T>) => JSX.Element,
  placeholder?: string;
};

const CommonSingleSelect = <T extends string>({
  options,
  disabled = false,
  defaultValue,
  onValueChanged,
  className = "",
  renderOption,
  placeholder = "",
}: CommonSingleSelectPropsType<T>) => {
  const [open, setOpen] = useState<boolean>(false);

  const onTriggerKeyDown = (e: any) => {
    if (open && e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      setOpen(false);
    }
  };

  return (
    <Select
      open={open}
      onOpenChange={setOpen}
      disabled={disabled}
      defaultValue={defaultValue}
      onValueChange={onValueChanged}
    >
      <SelectTrigger
        onKeyDown={onTriggerKeyDown}
        className={cn("w-full focus-visible:ring-0 focus:ring-0", className)}
        disabled={disabled}
        hideChevron={false}
      >
        <SelectValue
          className="items-center bg-slate-200 justify-center h-full border-2 border-slate-200"
          placeholder={placeholder}
        />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {options.map((option) => (
            <SelectItem
              key={option.value}
              className="items-center"
              value={option.value}
            >
              {renderOption ? renderOption(option) : (
                <span>{option.label}</span>
              )}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

export default memo(CommonSingleSelect);
