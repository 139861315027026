import { t } from "i18next";
import {
  GameType,
  MediaContentSortOrderType,
  MediaTagType,
  MediaType,
} from "./types";

const MAX_MEDIA_FILE_SIZE_MB = 512;
// is used to passthrough form validation (update content)
const MEDIA_FILE_STUB_TYPE = "file/stub";

const allowedMimeTypes = [
  "application/pdf",
  "video/mp4",
  MEDIA_FILE_STUB_TYPE,
];

const staticMediaContentTypeConfig: {
  groupId: string;
  groupLabel: string;
  items: { id: MediaType; label: string; }[];
} [] = [
  {
    groupId: "idContentType",
    groupLabel: t("common.mediaContentTypes.mediaTypesGroup"),
    items: [
      { id: "video", label: t("common.mediaContentTypes.video") },
      { id: "document", label: t("common.mediaContentTypes.document") },
      { id: "game", label: t("common.mediaContentTypes.game") },
      { id: "playlist", label: t("common.mediaContentTypes.playlist") },
      { id: "external_source", label: t("common.mediaContentTypes.externalSource") },
    ],
  },
];

const staticTagsConfig: ({
  groupId: string;
  groupLabel: string;
  items: { id: MediaTagType; label: string; }[];
} | { id: MediaTagType; label: string; })[] = [
  {
    groupId: "idTrainingLvl",
    groupLabel: t("common.mediaTags.trainingLvlGroup"),
    items: [
      { id: "trainingLvlEasy", label: t("common.mediaTags.trainingLvlEasy") },
      { id: "trainingLvlMedium", label: t("common.mediaTags.trainingLvlMedium") },
      { id: "trainingLvlAdvanced", label: t("common.mediaTags.trainingLvlAdvanced") },
    ],
  },
  {
    groupId: "idClassType",
    groupLabel: t("common.mediaTags.classTypeGroup"),
    items: [
      { id: "classLowerBody", label: t("common.mediaTags.classLowerBody") },
      { id: "classFullBody", label: t("common.mediaTags.classFullBody") },
      { id: "classUpperBody", label: t("common.mediaTags.classUpperBody") },
    ],
  },
  {
    groupId: "idTrainingType",
    groupLabel: t("common.mediaTags.trainigTypeGroup"),
    items: [
      { id: "trainingTypeStrength", label: t("common.mediaTags.trainingTypeStrength") },
      { id: "trainingTypeBalance", label: t("common.mediaTags.trainingTypeBalance") },
      { id: "trainingTypeMobility", label: t("common.mediaTags.trainingTypeMobility") },
    ],
  },
  {
    groupId: "idPosition",
    groupLabel: t("common.mediaTags.positionGroup"),
    items: [
      { id: "positionSitting", label: t("common.mediaTags.positionSitting") },
      { id: "positionStanding", label: t("common.mediaTags.positionStanding") },
      { id: "positionSittingStanding", label: t("common.mediaTags.positionSittingStanding") },
    ],
  },
  { id: "learning", label: t("common.mediaTags.learning") },
  { id: "entertaiment", label: t("common.mediaTags.entertaiment") },
];

const staticGamesConfig: {
  gameType: GameType;
  label: string;
} [] = [
  {
    gameType: "https://app.neuronup.com/play/activity/game/81/en_US",
    label: "Defeat the Monster",
  },
  {
    gameType: "https://app.neuronup.com/play/activity/game/90/en_US",
    label: "Mole Invasion",
  },
  {
    gameType: "https://app.neuronup.com/play/activity/game/131/en_US",
    label: "The Builder",
  },
  {
    gameType: "https://app.neuronup.com/play/activity/game/79/en_US",
    label: "Card Pyramid",
  },
  {
    gameType: "https://app.neuronup.com/play/activity/game/62/en_US",
    label: "Sorting Bugs",
  },
];

const sortLabelsConfig = {
  [MediaContentSortOrderType.SORT_BY_NAME]: t("common.sortOptions.byName"),
  [MediaContentSortOrderType.SORT_BY_DATE]: t("common.sortOptions.byDate"),
  [MediaContentSortOrderType.SORT_BY_MEDIA_TYPE]: t("common.sortOptions.byType"),
};

export {
  MAX_MEDIA_FILE_SIZE_MB,
  MEDIA_FILE_STUB_TYPE,
  allowedMimeTypes,
  sortLabelsConfig,
  staticTagsConfig,
  staticMediaContentTypeConfig,
  staticGamesConfig,
};