const IconStyle = {
  base: "absolute top-1/2 left-0 -translate-y-1/2",
  insideButton: "left-4",
  fill: "fill-white",
  alignLeft: "left-0 right-auto",
  alignRight: "right-0 left-auto",
  floatNone: "float-none",
};

export default IconStyle;
