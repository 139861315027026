import { Admin } from "views/admin";
import { Accounts } from "views/admin/accounts";
import i18n from "../i18n";

const Routes = [
  {
    path: "/admin",
    view: Admin,
    layout: "app",
    permission: "master",
    title: i18n.t("adminScreen.dashboard.title"),
  },
  {
    path: "/admin/accounts",
    view: Accounts,
    layout: "app",
    permission: "master",
    title: i18n.t("adminScreen.accounts.title"),
  },
];

export default Routes;
