import { allowedMimeTypes, MAX_MEDIA_FILE_SIZE_MB } from "@/config";
import { t } from "i18next";
import { Pencil, FilePlus } from "lucide-react";

const dialogUiConfig = {
  nameFieldLabel: t("mediaLibraryScreen.addContentDialog.nameFieldLabel"),
  nameFieldPlaceholder: t("mediaLibraryScreen.addContentDialog.nameFieldPlaceholder"),
  tagsFieldLabel: t("mediaLibraryScreen.addContentDialog.tagsFieldLabel"),
  tagsFieldPlaceholder: t("mediaLibraryScreen.addContentDialog.tagsFieldPlaceholder"),
  nameFieldValidationMsg: t("mediaLibraryScreen.addContentDialog.nameFieldValidationMsg"),

  fileFieldRequiredValidationMsg: t("mediaLibraryScreen.addContentDialog.fileFieldRequiredValidationMsg"),
  fileFieldTypeValidationMsg: t("mediaLibraryScreen.addContentDialog.fileFieldTypeValidationMsg", {
    types: allowedMimeTypes.slice(0, allowedMimeTypes.length - 1).join(",")}),
  fileFieldSizeValidationMsg: t("mediaLibraryScreen.addContentDialog.fileFieldSizeValidationMsg", {
    size: MAX_MEDIA_FILE_SIZE_MB
  }),
  createContext: {
    DialogTriggerIcon: FilePlus,
    dialogTriggerLabel: t("mediaLibraryScreen.addContentDialog.createContext.dialogTriggerLabel"),
    dialogHeaderTitle: t("mediaLibraryScreen.addContentDialog.createContext.dialogHeaderTitle"),
    dialogHeaderDescr: t("mediaLibraryScreen.addContentDialog.createContext.dialogHeaderDescr"),
    submitBtnLabel: t("mediaLibraryScreen.addContentDialog.createContext.submitBtnLabel"),
    submitSuccessMessage: t("mediaLibraryScreen.addContentDialog.createContext.submitSuccessMessage"),
    submitErrorMessage: t("mediaLibraryScreen.addContentDialog.createContext.submitErrorMessage"),
  },
  updateContext: {
    DialogTriggerIcon: Pencil,
    dialogTriggerLabel: t("mediaLibraryScreen.addContentDialog.updateContext.dialogTriggerLabel"),
    dialogHeaderTitle: t("mediaLibraryScreen.addContentDialog.updateContext.dialogHeaderTitle"),
    dialogHeaderDescr: t("mediaLibraryScreen.addContentDialog.updateContext.dialogHeaderDescr"),
    submitBtnLabel: t("mediaLibraryScreen.addContentDialog.updateContext.submitBtnLabel"),
    submitSuccessMessage: t("mediaLibraryScreen.addContentDialog.updateContext.submitSuccessMessage"),
    submitErrorMessage: t("mediaLibraryScreen.addContentDialog.updateContext.submitErrorMessage"),
  },
};

export default dialogUiConfig;