import { FC, memo, ReactElement } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Icon } from "@/components/ui/icon/icon";
import MediaToolTip from "./MediaToolTip";
import { MediaCategoryDataType } from "@/types";

type MediaCategoryEntityPropsType = {
  entityData: MediaCategoryDataType;
  actionsMenuEnabled: boolean;
  ActionsDropdown: ReactElement<any>;
  onClick: (entityData: MediaCategoryDataType) => void;
};

const MediaCategoryEntity: FC<MediaCategoryEntityPropsType> = ({
  entityData,
  actionsMenuEnabled,
  ActionsDropdown,
  onClick,
}) => {
  return (
    <Card className="h-[180px] w-[180px] overflow-hidden flex-col flex">
      <CardHeader className="flex flex-row p-0 items-center justify-between py-[12px]">
        <CardTitle className="ml-[12px] text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden text-[#11203D]">
          {entityData.name}
        </CardTitle>
        {actionsMenuEnabled ? ActionsDropdown : <MediaToolTip />}
      </CardHeader>
      <CardContent
        className="cursor-pointer border-1 w-full h-full border-blue-200 flex grow-1 p-0 px-[12px] pb-[12px]"
        onClick={() => onClick(entityData)}
      >
        <div className="w-full h-full border-grey-50 flex justify-center items-center rounded-md">
          <div
            className="flex h-40 w-40 -mt-5 bg-cover bg-center bg-no-repeat justify-center items-center"
            style={{
              backgroundImage: `url(${
                entityData.is_package
                  ? "/icons/innocom-folder.png"
                  : "/icons/folder.png"
              })`,
            }}
          >
            {entityData?.icon?.length && !entityData.is_package ? (
              <Icon
                pack="innocom"
                color="#3A70D4"
                image={`icon-${entityData?.icon}`}
                className="mt-[5px] !text-[60px]"
              />
            ) : null}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default memo(MediaCategoryEntity);
