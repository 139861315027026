import { sortLabelsConfig } from "@/config";
import { MediaContentSortOrderType, MediaLibraryInUseType } from "@/types";
import { t } from "i18next";

const { SORT_BY_NAME, SORT_BY_DATE } = MediaContentSortOrderType;
const {
  ACCOUNT_MEDIA_LIBRARY,
  PATIENT_MEDIA_LIBRARY,
  PACKAGES_MEDIA_LIBRARY,
  UNKNOWN_MEDIA_LIBRARY
} = MediaLibraryInUseType;

const dialogUiConfig = {
  dialogTriggerLabel: t("playlistScreen.addPlaylistContentDialog.dialogTriggerLabel"),
  dialogTriggerLabel2: t("playlistScreen.addPlaylistContentDialog.dialogTriggerLabel2"),
  dialogTriggerLabelPrivate: t("playlistScreen.addPlaylistContentDialog.dialogTriggerLabelPrivate"),
  dialogHeaderTitle: t("playlistScreen.addPlaylistContentDialog.dialogHeaderTitle"),
  filterByNameInputPlaceholder: t("playlistScreen.addPlaylistContentDialog.filterByNameInputPlaceholder"),
  selectedContentSectionHeader: t("playlistScreen.addPlaylistContentDialog.selectedContentSectionHeader"),
  searchResultsTitle: t("playlistScreen.addPlaylistContentDialog.searchResultsTitle"),
  submitBtnLabel: t("playlistScreen.addPlaylistContentDialog.submitBtnLabel"),
  cancelBtnLabel: t("playlistScreen.addPlaylistContentDialog.cancelBtnLabel"),
  submitSuccessMessage: t("playlistScreen.addPlaylistContentDialog.submitSuccessMessage"),
  submitErrorMessage: t("playlistScreen.addPlaylistContentDialog.submitErrorMessage"),
  initErrorMessage: t("playlistScreen.addPlaylistContentDialog.initErrorMessage"),
  mediaTypeFilterTriggerLabel: t("playlistScreen.addPlaylistContentDialog.mediaTypeFilterTriggerLabel"),
  mediaTypeFilterClearBtn: t("playlistScreen.addPlaylistContentDialog.mediaTypeFilterClearBtn"),
  optionCopyToLibrary: t("playlistScreen.addPlaylistContentDialog.optionCopyToLibrary"),
  mediaLibraryNameByType: {
    [ACCOUNT_MEDIA_LIBRARY]: t("playlistScreen.addPlaylistContentDialog.mediaLibraryNames.account"),
    [PATIENT_MEDIA_LIBRARY]: t("playlistScreen.addPlaylistContentDialog.mediaLibraryNames.patient"),
    [PACKAGES_MEDIA_LIBRARY]: t("playlistScreen.addPlaylistContentDialog.mediaLibraryNames.package"),
    [UNKNOWN_MEDIA_LIBRARY]: t("playlistScreen.addPlaylistContentDialog.mediaLibraryNames.unknown"),
  },
  sortMenu: [
    { id: SORT_BY_NAME, name: sortLabelsConfig[SORT_BY_NAME] },
    { id: SORT_BY_DATE, name: sortLabelsConfig[SORT_BY_DATE] },
  ],
};

export default dialogUiConfig;