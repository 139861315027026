import { useState, useEffect } from "react";
import Axios from "axios";
import { Button } from "@/components/ui/button";
import { Entity } from "./entity";
import {
  Breadcrumb,
  BreadcrumbEllipsis,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { useTranslation } from "react-i18next";

export function MediaLibrary(props) {
  const { t } = useTranslation();
  const { apiPrefix } = props;
  const [categoryId, setCategoryId] = useState(props.categoryId ?? null);
  const [sharedCategory, setSharedCategory] = useState(null);
  const [category, setCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [resources, setResources] = useState([]);
  const [selectedResources, setSelectedResources] = useState([]);

  useEffect(() => {
    const fetchResources = async () => {
      try {
        // fetch categories
        let endpoint = `${apiPrefix}/category`;
        if (categoryId) {
          endpoint += `/${categoryId}`;
        }

        const categoryEndpoint =
          sharedCategory === null
            ? endpoint
            : `${endpoint}?share=${sharedCategory}`;
        const categoriesData = await Axios.get(categoryEndpoint);

        // fetch content
        if (!categoryId) {
          endpoint += "/root";
        }

        let resourcesEndpoint = `${endpoint}/resource`;
        if (props.mediaType) {
          resourcesEndpoint += `?media_type=${props.mediaType}`;
        }
        const resourcesData = await Axios.get(resourcesEndpoint);

        if (categoryId) {
          setCategory(categoriesData.data);
          setCategories(categoriesData.data.children);
        } else {
          setCategory(null);
          setCategories(categoriesData.data);
        }

        setResources(resourcesData.data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchResources();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  const selectContent = (content) => {
    if (content.media_type === "shared_category") {
      setSharedCategory(categoryId);
      setCategoryId(content.linked_resource_id);
      return;
    }

    if (props.multiSelect) {
      if (selectedResources.includes(content.id)) {
        setSelectedResources(
          selectedResources.filter((id) => id !== content.id)
        );
      } else {
        setSelectedResources([...selectedResources, content.id]);
      }
    } else {
      props.callback(categoryId, content.id, content.name, "file");
      props.hide();
    }
  };

  const addFiles = () => {
    const items = selectedResources.map((id) => ({
      resourceId: id,
      contentType: "file",
    }));

    props.callback(items);
    props.hide();
  };

  const addCategory = () => {
    if (!category) return;

    const items = [
      {
        resourceId: category.id,
        contentType: "folder",
      },
    ];
    props.callback(items);
    props.hide();
  };

  return (
    <div className={`${props.className} w-full`}>
      {category && (
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink onClick={() => setCategoryId(null)}>
                {t("mediaLibraryScreen.title")}
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
            {category?.breadcrumbs.length > 0 && (
              <>
                {category?.breadcrumbs.length > 1 && (
                  <BreadcrumbItem>
                    <BreadcrumbEllipsis />
                  </BreadcrumbItem>
                )}

                <BreadcrumbItem>
                  <BreadcrumbLink
                    onClick={() => setCategoryId(category.parent_id)}
                  >
                    {category?.breadcrumbs.slice(-1)}
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
              </>
            )}
            {category && (
              <BreadcrumbItem>
                <BreadcrumbPage>{category.name}</BreadcrumbPage>
              </BreadcrumbItem>
            )}
          </BreadcrumbList>
        </Breadcrumb>
      )}

      <div className="grid grid-cols-4 gap-4 max-h-96 overflow-auto">
        {categories?.map((category) => {
          return (
            <Entity
              key={category.id}
              name={category.name}
              file={false}
              isPackage={category.is_package}
              isPrivateLibrary={props.private}
              description={category.description}
              icon={category.icon}
              onNavigate={() => setCategoryId(category.id)}
            />
          );
        })}
        {resources.length > 0 &&
          resources.map((content) => (
            <Entity
              key={content.id}
              name={content.name}
              file={true}
              isPackage={content.is_package}
              isPrivateLibrary={props.private}
              description={category?.description ?? ""}
              icon={content.icon}
              mediaType={content.media_type}
              onNavigate={() => selectContent(content)}
              selected={selectedResources.includes(content.id)}
            />
          ))}
      </div>

      {((props.selectCategory && category) || selectedResources.length > 0) && (
        <div className="mt-4 flex flex-row justify-end">
          {props.selectCategory && category && (
            <Button onClick={addCategory}>
              {t("mediaLibraryScreen.addCategory")}
            </Button>
          )}
          {selectedResources.length > 0 && (
            <Button onClick={addFiles} className="ml-2">
              {t("mediaLibraryScreen.addFiles")}
            </Button>
          )}
        </div>
      )}
    </div>
  );
}
