import { t } from "i18next";
import { Pencil, Link } from "lucide-react";

const dialogUiConfig = {
  nameFieldLabel: t("mediaLibraryScreen.addUpdateExtSourceDialog.nameFieldLabel"),
  nameFieldPlaceholder: t("mediaLibraryScreen.addUpdateExtSourceDialog.nameFieldPlaceholder"),
  valueFieldLabel: t("mediaLibraryScreen.addUpdateExtSourceDialog.valueFieldLabel"),
  valueFieldPlaceholder: t("mediaLibraryScreen.addUpdateExtSourceDialog.valueFieldPlaceholder"),
  tagsFieldLabel: t("mediaLibraryScreen.addUpdateExtSourceDialog.tagsFieldLabel"),
  tagsFieldPlaceholder: t("mediaLibraryScreen.addUpdateExtSourceDialog.tagsFieldPlaceholder"),
  nameFieldValidationMsg: t("mediaLibraryScreen.addUpdateExtSourceDialog.nameFieldValidationMsg"),
  valueFieldValidationMsg: t("mediaLibraryScreen.addUpdateExtSourceDialog.valueFieldValidationMsg"),
  createContext: {
    DialogTriggerIcon: Link,
    dialogTriggerLabel: t("mediaLibraryScreen.addUpdateExtSourceDialog.createContext.dialogTriggerLabel"),
    dialogHeaderTitle: t("mediaLibraryScreen.addUpdateExtSourceDialog.createContext.dialogHeaderTitle"),
    dialogHeaderDescr: t("mediaLibraryScreen.addUpdateExtSourceDialog.createContext.dialogHeaderDescr"),
    submitBtnLabel: t("mediaLibraryScreen.addUpdateExtSourceDialog.createContext.submitBtnLabel"),
    submitSuccessMessage: t("mediaLibraryScreen.addUpdateExtSourceDialog.createContext.submitSuccessMessage"),
    submitErrorMessage: t("mediaLibraryScreen.addUpdateExtSourceDialog.createContext.submitErrorMessage"),
  },
  updateContext: {
    DialogTriggerIcon: Pencil,
    dialogTriggerLabel: t("mediaLibraryScreen.addUpdateExtSourceDialog.updateContext.dialogTriggerLabel"),
    dialogHeaderTitle: t("mediaLibraryScreen.addUpdateExtSourceDialog.updateContext.dialogHeaderTitle"),
    dialogHeaderDescr: t("mediaLibraryScreen.addUpdateExtSourceDialog.updateContext.dialogHeaderDescr"),
    submitBtnLabel: t("mediaLibraryScreen.addUpdateExtSourceDialog.updateContext.submitBtnLabel"),
    submitSuccessMessage: t("mediaLibraryScreen.addUpdateExtSourceDialog.updateContext.submitSuccessMessage"),
    submitErrorMessage: t("mediaLibraryScreen.addUpdateExtSourceDialog.updateContext.submitErrorMessage"),
  },
};

export default dialogUiConfig;