const Style = {
  base: `relative text-left mb-0 after:absolute after:w-4 after:h-[2px] after:left-1/2 
    after:-bottom-2 after:-translate-x-1/2 md:mb-2`,

  title: "font-semibold mb-2",
  subtitle: "font-bold mt-0 mb-10 text-xl md:text-4xl md:mb-16",
  brand: "text-white",
  dark: "text-white",
  light: "text-black",
  desc: "text-left ",
  header: "mb-8 md:mb-16",
};

export default Style;
