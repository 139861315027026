import { useContext, useState, useEffect, useMemo } from "react";
import Axios from "axios";
import _ from "lodash";
// import { Button } from "@/components/ui/button";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useParams } from "react-router-dom";
import moment from "moment";
// import { createEventForm } from "./eventForm";
import "./react-big-calendar.scss";
import { useTranslation } from "react-i18next";
import "moment/locale/nb";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { PlusCircle } from "lucide-react";
import { AuthContext } from "app/providers/auth";
import { useSettings } from "app/hooks/settings";
import { useError } from "app/utils";
import { ViewContext } from "components/view/view";
import AddUpdateEventDialog from "@/components/ui/dialog/AddUpdateEventDialog";
import { useToast } from "@/components/ui/use-toast";

export function Schedule() {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const context = useContext(ViewContext);
  const [handleError] = useError();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [createEventDate, setCreateEventDate] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const { toast } = useToast();

  // setting up events calendar
  const localizer = momentLocalizer(moment);
  const { defaultDate, views, messages } = useMemo(
    () => ({
      defaultDate: new Date(),
      views: ["month", "week", "day"],
      messages: {
        date: t("patientsScreen.schedule.date"),
        time: t("patientsScreen.schedule.time"),
        event: t("patientsScreen.schedule.event"),
        allDay: t("patientsScreen.schedule.allDay"),
        week: t("patientsScreen.schedule.week"),
        work_week: t("patientsScreen.schedule.workWeek"),
        day: t("patientsScreen.schedule.day"),
        month: t("patientsScreen.schedule.month"),
        previous: t("patientsScreen.schedule.previous"),
        next: t("patientsScreen.schedule.next"),
        yesterday: t("patientsScreen.schedule.yesterday"),
        tomorrow: t("patientsScreen.schedule.tomorrow"),
        today: t("patientsScreen.schedule.today"),
        agenda: t("patientsScreen.schedule.agenda"),
        noEventsInRange: t("patientsScreen.schedule.noEventsInRange"),

        showMore: (total) => t("patientsScreen.schedule.more", { total }),
      },
    }),
    [t]
  );

  // fetching events
  const params = useParams();

  const [settings] = useSettings();
  const canManageEvents =
    authContext.user?.permission === "owner" ||
    authContext.user?.permission === "master" ||
    authContext.user?.permission === "admin" ||
    authContext.user?.permission === "doctor" ||
    (authContext.user?.permission === "familyMember" &&
      settings?.nok_can_create_activities);
  const [events, setEvents] = useState([]);
  const [dates, setDates] = useState({
    startDate: moment().startOf("month").toISOString(),
    endDate: moment().endOf("month").toISOString(),
  });

  async function loadPatientEvents(filter) {
    try {
      const res = await Axios.get("/api/calendar", { params: filter });
      return res.data ?? [];
    } catch (err) {
      handleError(err);
    }
  }

  const refreshCalendar = async () => {
    const filter = {
      start_date: dates.startDate,
      end_date: dates.endDate,
      patient_id: params.patientId,
      account_id: authContext.user?.account_id,
    };

    const events = await loadPatientEvents(filter);
    setEvents(events);
  };

  useEffect(() => {
    const fetchEvents = async () => {
      const filter = {
        start_date: dates.startDate,
        end_date: dates.endDate,
        patient_id: params.patientId,
        account_id: authContext.user?.account_id,
      };
      const events = await loadPatientEvents(filter);
      setEvents(events);
    };

    fetchEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates, params, authContext]);

  const onDatesChange = (dates) => {
    if (Array.isArray(dates)) {
      const start = _.min(dates);
      const end = _.max(dates);
      setDates({
        startDate: moment(start).startOf("day").toISOString(),
        endDate: moment(end).startOf("day").add(1, "days").toISOString(),
      });
    } else {
      setDates({
        startDate: moment(dates.start).startOf("day").toISOString(),
        endDate: moment(dates.end).startOf("day").add(1, "days").toISOString(),
      });
    }
  };

  const addEvent = (slotInfo = null) => {
    if (!canManageEvents) {
      return;
    }

    const eventDate = (slotInfo === null)
      ? new Date()
      : moment(slotInfo.slots[0]).toDate();

    setCreateEventDate(eventDate);
    setSelectedEvent(null);
    setDialogOpen(true);

    return;
    // const eventDate =
    //   slotInfo === null ? new Date() : moment(slotInfo.slots[0]).toDate();
    // context.modal.show(
    //   {
    //     title: t("patientsScreen.schedule.addActivity"),
    //     ...createEventForm(params.patientId, authContext, eventDate, null),
    //   },
    //   refreshCalendar
    // );
  };

  const editEvent = (event) => {
    if (!canManageEvents) {
      return;
    }

    const selectedEvent = events.filter((e) => e.id === event.id)[0];

    setSelectedEvent(selectedEvent);
    setDialogOpen(true);
    // return;
    // context.modal.show(
    //   {
    //     title: t("patientsScreen.schedule.updateActivity"),
    //     ...createEventForm(params.patientId, authContext, null, selectedEvent),
    //     delete: async () => {
    //       try {
    //         await Axios.delete(`/api/calendar/${selectedEvent.id}`);
    //         await refreshCalendar();
    //         context.modal.hide(true);
    //       } catch (err) {
    //         handleError(err);
    //       }
    //     },
    //   },
    //   refreshCalendar
    // );
  };

  function setEndDateForMidnight(date) {
    const dt = moment(date);
    if (dt.hour() === 0 && dt.minute() === 0) {
      dt.add(-1, "minute");
    }

    return dt.toDate();
  }

  // console.log("upper selected >>>", selectedEvent);

  return (
    <Card
      className="mb-2 flex flex-col overflow-hidden h-full"
      x-chunk="dashboard-05-chunk-4"
    >
      <CardHeader className="flex flex-row items-start  border-0 border-slate-200 py-0 pt-2">
        <div className="ml-auto flex items-center gap-1">
          {/* {canManageEvents && (
            <Button
              className="gap-1 focus-visible:ring-0 focus:ring-0"
              onClick={() => addEvent()}
              variant="secondary"
              aria-haspopup="true"
              size="default"
            >
              <PlusCircle className="h-3.5 w-3.5" />
              <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                {t("patientsScreen.schedule.addActivity")}
              </span>
            </Button>
          )} */}
          {canManageEvents && (
            <AddUpdateEventDialog
              onSubmit={(errMsg, successMsg) => {
                toast({
                  title: errMsg ?? successMsg,
                  variant: errMsg ? "destructive" : undefined,
                });

                if (!errMsg) {
                  refreshCalendar();
                }
              }}
              onOpenClose={(open) => {
                if (!open) {
                  setSelectedEvent(null);
                  setCreateEventDate(undefined);
                };
                setDialogOpen(open);
              }}
              open={dialogOpen}
              patientId={params.patientId}
              selectedEvent={selectedEvent}
              newEventDate={createEventDate}
            />
          )}
        </div>
      </CardHeader>
      <CardContent className="h-full p-6 text-sm overflow-auto">
        <Calendar
          localizer={localizer}
          culture="nb"
          startAccessor="start"
          endAccessor="end"
          defaultView={"week"}
          // style={{ height: window.innerHeight - 250 }}
          views={views}
          defaultDate={defaultDate}
          events={events.map((x) => {
            return {
              id: x.id,
              title: x.title,
              start: new Date(x.start_date),
              end: setEndDateForMidnight(x.end_date),
            };
          })}
          messages={messages}
          onRangeChange={onDatesChange}
          onSelectEvent={editEvent}
          onSelectSlot={addEvent}
          selectable
        />
      </CardContent>
    </Card>
  );
}
