import {
  ActivityRepeatType,
  ActivityRepeatTypes,
  ActivityWeekDayTypes,
} from "@/types";
import { t } from "i18next";
import { memo, useEffect, useState } from "react"

// !TODO: move to 
const selectorDara = ActivityRepeatTypes.reduce((acc, repeatType) => {
  if (repeatType === "weekly") {
    return acc.concat({
      repeatType,
      value: repeatType,
      label: t(`patientsScreen.eventForm.scheduler.${repeatType}`),
      weekdays: ActivityWeekDayTypes.map((weekDayType) => ({
        label: t(`patientsScreen.eventForm.scheduler.${weekDayType}`),
        value: weekDayType,
      })),
    });
  }

  return acc.concat({
    repeatType,
    value: repeatType,
    label: t(`patientScreen.addUpdateEventDialog.repeatTypes.${repeatType}`)
  })
}, [] as {
  repeatType: ActivityRepeatType;
  value: string;
  label: string;
  weekdays?: { value: string; label: string; } [];
}[]);

type RepetitionSelectorPropsType = {
  disabled?: boolean;
  onUpdateRepeatType?: (repeatType: string|undefined|null) => void;
  onUpdateSelectedWeekdays?: (weekdays: string[]) => void;
  defaultSelectedRepeatType?: string|undefined|null;
  defaultSelectedWeekDays?: string[];
}

const RepetitionSelector = ({
  disabled = false,
  onUpdateRepeatType,
  onUpdateSelectedWeekdays,
  defaultSelectedRepeatType = null,
  defaultSelectedWeekDays = [],
}: RepetitionSelectorPropsType) => {
  const [selectedRepeatType, setSelectedRepeatType] = useState<string|undefined|null>(defaultSelectedRepeatType);
  const [selectedWeekDays, setSelectedWeekDays] = useState<string[]>(defaultSelectedWeekDays);

  useEffect(() => {
    onUpdateRepeatType?.(selectedRepeatType);
    // !TODO: update linter configuration
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRepeatType]);

  useEffect(() => {
    onUpdateSelectedWeekdays?.(selectedWeekDays);
    // !TODO: update linter configuration
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWeekDays]);

  return (
    <div className="flex flex-row border-0 border-red-300 h-auto items-center p-0">
      {selectorDara.map((dataEntry) => {
        const isSelected = selectedRepeatType === dataEntry.value;
        const commonStyle = "select-none font-light text-sm text-[#11203D] mr-2 px-2 py-[10px] rounded-sm border-[1px] cursor-pointer";
        const selectedStyle = "bg-[#e25003] border-[#e25003] text-[#ffffff]";
        const disabledStye = "cursor-not-allowed";

        return (
          <div
            className="h-[40px] items-center justify-center content-center"
            onClick={(e) => {
              e.preventDefault();

              if (disabled) {
                return
              };

              setSelectedRepeatType(dataEntry.value);

              if (dataEntry.repeatType !== "weekly") {
                setSelectedWeekDays([]);
              };
            }}
            key={dataEntry.value}
          >
            {dataEntry.repeatType === "weekly" ? dataEntry?.weekdays?.map((weekDayEntry) => {
              const isWeekDaySelected = selectedWeekDays.includes(weekDayEntry.value);

              return (
                <span
                  onClick={(e) => {
                    e.preventDefault();

                    if (disabled) {
                      return;
                    };

                    if (selectedWeekDays.includes(weekDayEntry.value)) {
                      setSelectedWeekDays(selectedWeekDays.filter((value) => value !== weekDayEntry.value));
                    } else {
                      setSelectedWeekDays(selectedWeekDays.concat(weekDayEntry.value));
                    }
                  }}
                  key={weekDayEntry.value}
                  className={`${commonStyle} ${isWeekDaySelected ? selectedStyle : ""} ${disabled ? disabledStye: ""}`}
                >
                  {weekDayEntry.label}
                </span>              
              );
            }) : (
              <span className={`${commonStyle} ${isSelected ? selectedStyle : ""} ${disabled ? disabledStye: ""}`}>
                {dataEntry.label}
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default memo(RepetitionSelector);
