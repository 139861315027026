import { t } from "i18next";
import { Pencil, Plus } from "lucide-react";

const dialogUiConfig = {
  packageNameFieldLabel: t("mediaLibraryScreen.addUpdatePackageDialog.packageNameFieldLabel"),
  packageNameFieldPlaceholder: t("mediaLibraryScreen.addUpdatePackageDialog.packageNameFieldPlaceholder"),
  packageAccountsFieldLabel: t("mediaLibraryScreen.addUpdatePackageDialog.packageAccountsFieldLabel"),
  packageAccountsFieldPlaceholder: t("mediaLibraryScreen.addUpdatePackageDialog.packageAccountsFieldPlaceholder"),
  packageNameFieldValidationMsg: t("mediaLibraryScreen.addUpdatePackageDialog.packageNameFieldValidationMsg"),
  packageAccountsFieldValidationMsg: t("mediaLibraryScreen.addUpdatePackageDialog.packageAccountsFieldValidationMsg"),
  selectAccountsGroupLabel: t("mediaLibraryScreen.addUpdatePackageDialog.selectAccountsGroupLabel"),
  createContext: {
    DialogTriggerIcon: Plus,
    dialogTriggerLabel: t("mediaLibraryScreen.addUpdatePackageDialog.createContext.dialogTriggerLabel"),
    dialogHeaderTitle: t("mediaLibraryScreen.addUpdatePackageDialog.createContext.dialogHeaderTitle"),
    dialogHeaderDescr: t("mediaLibraryScreen.addUpdatePackageDialog.createContext.dialogHeaderDescr"),
    submitBtnLabel: t("mediaLibraryScreen.addUpdatePackageDialog.createContext.submitBtnLabel"),
    submitSuccessMessage: t("mediaLibraryScreen.addUpdatePackageDialog.createContext.submitSuccessMessage"),
    submitErrorMessage: t("mediaLibraryScreen.addUpdatePackageDialog.createContext.submitErrorMessage"),
  },
  updateContext: {
    DialogTriggerIcon: Pencil,
    dialogTriggerLabel: t("mediaLibraryScreen.addUpdatePackageDialog.updateContext.dialogTriggerLabel"),
    dialogHeaderTitle: t("mediaLibraryScreen.addUpdatePackageDialog.updateContext.dialogHeaderTitle"),
    dialogHeaderDescr: t("mediaLibraryScreen.addUpdatePackageDialog.updateContext.dialogHeaderDescr"),
    submitBtnLabel: t("mediaLibraryScreen.addUpdatePackageDialog.updateContext.submitBtnLabel"),
    submitSuccessMessage: t("mediaLibraryScreen.addUpdatePackageDialog.updateContext.submitSuccessMessage"),
    submitErrorMessage: t("mediaLibraryScreen.addUpdatePackageDialog.updateContext.submitErrorMessage"),
  },
};

export default dialogUiConfig;