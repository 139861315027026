import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { DataTable } from "@/components/ui/data-table";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { t } from "i18next";
import { PlusCircle } from "lucide-react";
import { FC, memo, useCallback, useMemo, useState } from "react";
import Axios from "axios";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { UserType } from "./types";

type FamilyTableEntryDataType = {
  id: string;
  name: string;
  email: string;
  isSelected: boolean;
};

type AddFamilyMembersDialogPropsType = {
  patientId: string;
  onAddSent: () => void;
};

const AddFamilyMembersDialog: FC<AddFamilyMembersDialogPropsType> = ({
  patientId,
  onAddSent,
}) => {
  const [familyDataFetching, setFamilyDataFetching] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [tableData, setTableData] = useState<FamilyTableEntryDataType[]>([]);
  const [dialogError, setDialogError] = useState<string|null>(null);
  const [selectedMembersIds, setSelectedMembersIds] = useState<string[]>([]);

  const onOpenChange = useCallback(async(open: boolean) => {
    try {
      setFamilyDataFetching(true);
      setDialogOpen(open);

      if (open) {
        const result = await Axios.get("/api/family") as {data?: UserType[]};

        if (!result.data) {
          throw new Error("error getting family data");
        }

        const dataToAdd: FamilyTableEntryDataType[] = [];
        const idsToAdd: string[] = []

        result.data.forEach(({ id, name, email, patients }) => {
          const isSelected = patients?.includes(patientId);
          dataToAdd.push({ id, name, email, isSelected });

          if (isSelected) idsToAdd.push(id);
        });

        setTableData(dataToAdd.sort(({ isSelected }) => isSelected ? -1 : 1));
        setSelectedMembersIds(idsToAdd);
      } else {
        setSelectedMembersIds([]);
        setTableData([]);
        setDialogError(null);
      }

      setFamilyDataFetching(false);
    } catch (err) {
      setTableData([]);
      setFamilyDataFetching(false);
      console.error(err);
      setDialogError("patientsScreen.access.modalAdd.errorGetData");
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = useCallback(async() => {
    try {
      setDialogError(null);
      await Axios.post(`/api/patient/${patientId}/family`, {
        allow: tableData
          .filter(({ isSelected }) => isSelected)
          .map(({ id }) => id)
      });

      setDialogOpen(false);
      onAddSent();
    } catch (err) {
      console.error(err)
      setDialogError("patientsScreen.access.modalAdd.errorUpdateData");
    }
    // eslint-disable-next-line
  }, [tableData]);

  const submitEnabled = useMemo(() => {
    for (let i = 0, len = tableData.length; i < len; i += 1) {
      const { id, isSelected } = tableData[i];

      if ((isSelected && !selectedMembersIds.includes(id))
        || (!isSelected && selectedMembersIds.includes(id))
      ) {
        return true;
      }
    }

    return false;
  }, [selectedMembersIds, tableData])

  return (
    <Dialog open={dialogOpen} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>
        <Button
          variant="secondary"
          className="gap-1 focus-visible:ring-0"
          size="default"
        >
          <PlusCircle className="h-3.5 w-3.5" />
          <span>{t("patientsScreen.access.modalAdd.triggerButton")}</span>
        </Button>
      </DialogTrigger>
      <DialogContent className="flex flex-col max-w-3/4 min-w-3/4 w-3/4 h-4/5 min-h-4/5 max-h-4/5">
        <DialogHeader>
          <DialogTitle>
            {t("patientsScreen.access.modalAdd.title")}
          </DialogTitle>
          <DialogDescription>
            {t("patientsScreen.access.modalAdd.label")}
          </DialogDescription>
          {dialogError && (            
            <Alert className="w-1/3" variant="destructive">
              {/* !TODO: <ExclamationTriangleIcon className="h-4 w-4" /> */}
              <AlertTitle>{t("patientsScreen.access.modalAdd.errorMessageHeader")}</AlertTitle>
              <AlertDescription>{t(dialogError)}</AlertDescription>
            </Alert>
          )}
        </DialogHeader>
        <ScrollArea className="flex-auto h-32 overflow-y-auto flex-col">
          <DataTable
            dataFetching={familyDataFetching}
            stickyHeader
            paginationEnabled={false}
            onRowClick={(rowData) => {
              setTableData(tableData.map((entry) => {
                if (entry.id === rowData.id) {
                  return {
                    ...entry,
                    isSelected: !rowData.isSelected,
                  };
                }

                return entry;
              }));
            }}
            columns={[
              {
                accessorKey: "isSelected",
                header: "",
                cell: ({ row }) => {
                  return (
                    <Checkbox
                      checked={row.original.isSelected}
                      aria-label="Select row"
                    />
                  );
                }
              },
              {
                accessorKey: "name",
                header: t("patientsScreen.access.table.name"),
              },
              {
                accessorKey: "email",
                header: t("accountScreen.users.table.email"),
              },
            ]}
            data={tableData}
            searchField="name"
          />
        </ScrollArea>
        <DialogFooter>
          <DialogClose>
            <Button type="button" variant="secondary">
              {t("common.cancel")}
            </Button>
          </DialogClose>
          <Button disabled={!submitEnabled} type="submit" onClick={onSubmit}>
            {t("common.add")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default memo(AddFamilyMembersDialog);
