/***
 *
 *   SIGN UP STEP 1
 *   Signup form for account owners
 *   Step 1: create account
 *   Step 2: verify email address
 *   Step 3: select plan
 *
 **********/

import React, { useContext } from "react";
import { Row, Card, Form, Link } from "components/lib";
import { useTranslation } from "react-i18next";
import { AuthContext } from "app/providers/auth";

export function Signup(props) {
  const { t } = useTranslation();
  const context = useContext(AuthContext);

  return (
    <Row title={t("signUpScreen.create.title")}>
      <Card loading={false} noPadding center>
        <Form
          inputs={{
            name: {
              label: t("signUpScreen.create.firstName"),
              type: "text",
              required: true,
              errorMessage: t("signUpScreen.create.firstNameError"),
            },
            email: {
              label: t("signUpScreen.create.email"),
              type: "email",
              required: true,
            },
            password: {
              label: t("signUpScreen.create.password"),
              type: "password",
              required: true,
              complexPassword: true,
            },
            confirm_password: {
              type: "hidden",
              value: null,
            },
          }}
          url="/api/account"
          method="POST"
          buttonText={t("signUpScreen.create.button")}
          callback={context.signin}
        />

        {/* <SocialSignin network={["facebook", "twitter"]} showOr signup /> */}

        <div className="mt-4 text-center">
          {t("signUpScreen.create.alreadyRegistered")}{" "}
          <Link url="/signin" text={t("signUpScreen.create.signIn")} />
        </div>
      </Card>
    </Row>
  );
}
