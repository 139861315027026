/***
 *
 *   RESET PASSWORD
 *   User can set a new password using the token
 *
 **********/

import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "app/providers/auth";
import { Card } from "@/components/ui/card";
import { Row } from "components/layout/row/row";
import { Form } from "components/form/form";

export function ResetPassword(props) {
  const { t } = useTranslation();
  // context
  const navigate = useNavigate();
  const location = useLocation();
  const context = useContext(AuthContext);

  // check for token
  const qs = location.search;
  const token = qs.substring(qs.indexOf("?token=") + 7);

  return (
    <Row title={t("signInScreen.forgotPassword.title")}>
      <Card className="border-0 shadow-none">
        <Form
          inputs={{
            jwt: {
              type: "hidden",
              value: token,
            },
            email: {
              label: t("signInScreen.forgotPassword.email"),
              type: "email",
              required: true,
            },
            password: {
              label: t("signInScreen.forgotPassword.newPassword"),
              type: "password",
              required: true,
              complexPassword: true,
            },
          }}
          url="/api/auth/password/reset"
          method="POST"
          buttonText={t("signInScreen.forgotPassword.setNewPassword")}
          callback={(res) => {
            res.data["2fa_required"]
              ? navigate(`/signin/otp?token=${res.data.token}`)
              : context.signin(res);
          }}
        />
      </Card>
    </Row>
  );
}
