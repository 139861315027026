import {
  ChatRoomDataType,
  UserType,
} from "./types";

const getTotalUnreadCount = (currentUser: UserType, chatRooms: ChatRoomDataType[]) => {
  const { rooms_activity } = currentUser;

  if (!chatRooms.length) {
    return 0;
  }

  const result = chatRooms.reduce((acc, { roomData }) => {
    const roomActivity = roomData && rooms_activity?.[roomData.id];

    if (roomData && roomData.last_message_timestamp && (
      !roomActivity || roomActivity.timestamp < roomData.last_message_timestamp
    )) {
      return acc + Math.abs(roomData.messages_count - (roomActivity?.messages_count || 0));
    }

    return acc;
  }, 0);

  return result;
};

export { getTotalUnreadCount };