import { FC, useState, memo } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Icon } from "@/components/ui/icon/icon";

export enum TreeItemType {
  TYPE_RESOURCE = "resource",
  TYPE_CATEGORY = "category",
  TYPE_ROOT = "root",
};

export type MediaTreeItem = {
  id: string;
  children?: MediaTreeItem[];
  type: TreeItemType;
  payload?: any;
};

type TreeItemProps = {
  tree: MediaTreeItem;
  lvl?: number;
  onItemSelect: (item?: MediaTreeItem) => void;
  selectedItem?: MediaTreeItem;
  disabledItemsIds: string[];
};

const TreeItem: FC<TreeItemProps> = ({
  tree,
  lvl = 0,
  onItemSelect,
  selectedItem,
  disabledItemsIds,
}) => {
  if (
    (tree.type === TreeItemType.TYPE_ROOT ||
      tree.type === TreeItemType.TYPE_CATEGORY)
  ) {
    const isRootItem = tree.type === TreeItemType.TYPE_ROOT;
    // const resultWidthPx = 252 - lvl * 16; // !FIXME
    const childFolders = tree?.children?.filter(({ type }) => type === TreeItemType.TYPE_CATEGORY) || [];

    return (
      <div className={`mb-1 pl-${isRootItem ? 2 : 4} overflow-hidden`}>
        <AccordionItem
          disabled={disabledItemsIds.includes(tree.id)}
          className="border-0"
          value={tree.id}
        >
          <AccordionTrigger
            hideChevron={isRootItem || childFolders.length === 0}
            chevronStyle="h-5 w-5 mr-[4px]"
            className="p-0 hover:no-underline overflow-hidden w-2"
            isSelected={selectedItem === tree}
            headerClickCustomHandler={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onItemSelect(tree);
            }}
          >
            <div className={`items-center rounded-md px-[8px] py-[4px] flex flex-1 w-full ${selectedItem === tree && "bg-[#F1F5F9]"}`}>
              <div
                className="flex h-[28px] w-[28px] bg-cover bg-center bg-no-repeat justify-center items-center p-0"
                style={{
                  backgroundImage: `url(${tree?.payload?.is_package
                    ? "/icons/innocom-folder.png"
                    : "/icons/folder.png"})`,
                }}
              >
                {(tree.payload?.icon?.length && !tree?.payload?.is_package) && (
                  <Icon
                    pack="innocom"
                    color="#3A70D4"
                    image={`icon-${tree.payload?.icon}`}
                    className="text-[14px] mt-[2px]"
                  />
                )}
              </div>
              <span className="foverflow-hidden flex-1 flex font-medium text-[#11203D] truncate whitespace-nowrap text-nowrap text-ellipsis ml-2">
                {/* long text name long text name long text name */}
                {tree?.payload?.name || "root"}
              </span>
            </div>
          </AccordionTrigger>

          {(childFolders.length > 0) && (
            <AccordionContent className="pb-0">
              {childFolders
                .map((childItem) => (
                  <TreeItem
                    key={childItem.id}
                    disabledItemsIds={disabledItemsIds}
                    selectedItem={selectedItem}
                    onItemSelect={onItemSelect}
                    tree={childItem}
                    lvl={lvl + 1}
                  />
                ))}
            </AccordionContent>
          )}
        </AccordionItem>
      </div>
    );
  }

  return null;
};

type MediaLibNavigationTreePropsType = {
  data: MediaTreeItem;
  initialSelectedItem?: MediaTreeItem;
  selectedItem?: MediaTreeItem,
  onItemSelected?: (item?: MediaTreeItem) => void;
};

const MediaLibNavigationTree: FC<MediaLibNavigationTreePropsType> = ({
  data,
  initialSelectedItem,
  selectedItem,
  onItemSelected,
}) => {
  const [_selectedItem, _setSelectedItem] = useState<MediaTreeItem|undefined>(initialSelectedItem);
 
  const handleItemSelected = (item?: MediaTreeItem) => {
    _setSelectedItem(item);

    if (onItemSelected) {
      onItemSelected(item);
    }
  };
  
  return (
    <Accordion
      defaultValue={["id_root"]}
      className="pr-4"
      type="multiple"
    >
      <TreeItem
        onItemSelect={(selectedItem && onItemSelected)
          ? onItemSelected
          : handleItemSelected}
        selectedItem={selectedItem || _selectedItem}
        disabledItemsIds={[]}
        tree={data}
        lvl={0}
      />
    </Accordion>
  );
};

export default memo(MediaLibNavigationTree);
