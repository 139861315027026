import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import Axios from "axios";
import { PlusCircle } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

export function CreateAccountDialog(props: { onCreateAccount: () => void }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const FormSchema = z.object({
    name: z.string().min(2, {
      message: t("adminScreen.accounts.nameValidationMessage"),
    }),
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      name: "",
    },
  });

  async function onSubmit(values: z.infer<typeof FormSchema>) {
    console.log(values);

    const result = await Axios.post("/api/account", values);
    if (result.status === 201) {
      console.log("Account created successfully");
    }
    props.onCreateAccount();
    setOpen(false);
  }

  return (
    <Dialog
      open={open}
      onOpenChange={setOpen}
    >
      <DialogTrigger asChild>
        <Button
          variant="secondary"
          className="gap-1 focus-visible:ring-0 focus:ring-0"
          size="default"
        >
          <PlusCircle className="h-3.5 w-3.5" />
          <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
            {t("adminScreen.accounts.createAccount")}
          </span>
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{t("adminScreen.accounts.createAccount")}</DialogTitle>
        </DialogHeader>
        <div className="py-4">
          <div className="items-center">
            <Form {...form}>
              <form>
                <FormField
                  control={form.control}
                  name="name"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>{t("adminScreen.accounts.name")}</FormLabel>
                      <FormControl>
                        <Input
                          className="focus-visible:ring-0"
                          placeholder={t(
                            "adminScreen.accounts.namePlaceholder"
                          )}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </form>
            </Form>
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              className="focus-visible:ring-0 focus:ring-0"
              type="button"
              variant="secondary"
            >
              {t("common.cancel")}
            </Button>
          </DialogClose>

          <Button
            className="focus-visible:ring-0 focus:ring-0"
            type="submit"
            onClick={form.handleSubmit(onSubmit)}
          >
            {t("common.save")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
