import { ClassHelper } from "app/utils";
import LogoImg from "./images/logo.svg";
import LogoInvertedImg from "./images/logo-inverted.svg";
import Style from "./logo.tailwind.js";
import { Link } from "components/link/link";

export function Logo(props) {
  const logoStyle = ClassHelper(Style, props);

  return (
    <Link url="/" className={logoStyle}>
      <img src={props.inverted ? LogoInvertedImg : LogoImg} alt="Logo" />
    </Link>
  );
}
