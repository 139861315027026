import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { MediaEditor } from "@/components/ui/mediaEditor/mediaEditor";

export function MediaLibrary(props) {
  return (
    <main
      className="grid gap-4 p-4 sm:px-6 m-0 sm:py-0 md:gap-8"
      style={{ height: window.innerHeight - 60 }}
    >
      {/* <Card>
        <CardHeader className="px-7"></CardHeader>
        <CardContent> */}
      <MediaEditor apiPrefix="/api/media" />
      {/* </CardContent>
      </Card> */}
    </main>
  );
}
