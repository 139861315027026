/***
 *
 *   PROFILE
 *   Update the user profile or close the account
 *
 **********/

import { Fragment, useContext, useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { useNavigate } from "react-router-dom";
import { useAPI } from "app/hooks/api";
import { AuthContext } from "app/providers/auth";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { InfoIcon, MessageCircleWarningIcon } from "lucide-react";
import { Event } from "app/utils/event";
import { ViewContext } from "components/view/view";
import { Form } from "components/form/form";

export function Profile(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const [done, setDone] = useState(false);
  const [qrCode, setQrCode] = useState(undefined);
  const [enabled, setEnabled] = useState(undefined);
  const [backupCode, setBackupCode] = useState(undefined);

  const user = useAPI("/api/user");

  useEffect(() => {
    setEnabled(user.data?.["2fa_enabled"]);
  }, [user.data]);

  function closeAccount() {
    viewContext.modal.show(
      {
        title: t("accountScreen.profile.closeAccount"),
        form: {},
        buttonText: t("accountScreen.profile.closeAccountButton"),
        url: authContext.permission.owner ? "/api/account" : "/api/user",
        method: "DELETE",
        destructive: true,
        text: t("accountScreen.profile.closeAccountDescription"),
      },
      () => {
        // destory user
        Event("closed_account");
        localStorage.clear();
        navigate("/signup");
      }
    );
  }

  return (
    <div className="grid gap-6">
      <Card>
        <CardHeader className="px-7">
          <CardTitle>{t("accountScreen.profile.editProfile")}</CardTitle>
        </CardHeader>
        <CardContent>
          {/* <AccountNav /> */}
          {user?.data && (
            <Form
              buttonText={t("accountScreen.profile.save")}
              url="/api/user"
              method="PATCH"
              inputs={{
                name: {
                  label: t("accountScreen.profile.name"),
                  type: "text",
                  required: true,
                  value: user.data.name,
                  errorMessage: t("accountScreen.profile.nameError"),
                },
                email: {
                  label: t("accountScreen.profile.email"),
                  type: "email",
                  required: true,
                  value: user.data.email,
                  errorMessage: t("accountScreen.profile.emailError"),
                },
                // avatar: {
                //   label: t("accountScreen.profile.profilePicture"),
                //   type: "file",
                //   required: false,
                //   max: 1,
                // },
                ...(user.data.permission === "owner" && {
                  account_name: {
                    type: "text",
                    label: t("accountScreen.profile.accountName"),
                    value: user.data.account_name,
                  },
                }),
                ...(user.data.accounts?.length > 1 && {
                  default_account: {
                    label: t("accountScreen.profile.defaultAccount"),
                    type: "select",
                    default: user.data.default_account,
                    options: user.data.accounts.map((x) => {
                      return {
                        value: x.id,
                        label: x.name,
                      };
                    }),
                  },
                }),
              }}
              callback={(res) => {
                const data = res.data.data;

                // update the account name
                if (
                  data.account_name &&
                  authContext.user?.accounts?.length > 0
                ) {
                  const accounts = [...authContext.user.accounts];
                  accounts[
                    accounts.findIndex(
                      (x) => x.id === authContext.user.account_id
                    )
                  ].name = data.account_name;
                  authContext.update({ accounts: accounts });
                }

                // update the user name and avata
                if (data.name || data.avatar)
                  authContext.update({
                    name: data.name,
                    avatar: data.avatar,
                  });

                // user changed email and needs to verify
                if (data.hasOwnProperty("verified") && !data.verified) {
                  authContext.update({ verified: false });
                  navigate("/signup/verify");
                }
              }}
            />
          )}

          <Fragment>
            <br />
            <Button variant="destructive" onClick={closeAccount}>
              {t("accountScreen.profile.closeAccountButton")}
            </Button>
          </Fragment>
        </CardContent>
      </Card>
      <Card>
        <CardHeader className="px-7">
          <CardTitle>{t("accountScreen.password.title")}</CardTitle>
        </CardHeader>
        <CardContent>
          {!done ? (
            <Form
              url="/api/user/password"
              method="PUT"
              buttonText={t("accountScreen.password.savePassword")}
              inputs={{
                ...(authContext.user.has_password && {
                  oldpassword: {
                    label: t("accountScreen.password.oldPassword"),
                    type: "password",
                    required: true,
                  },
                  has_password: {
                    type: "hidden",
                    value: true,
                  },
                }),
                newpassword: {
                  label: t("accountScreen.password.newPassword"),
                  type: "password",
                  required: true,
                  complexPassword: true,
                },
              }}
              callback={() => {
                setDone(true);
                authContext.update({ has_password: true });
              }}
            />
          ) : (
            <Alert>
              <InfoIcon className="h-4 w-4" />
              <AlertTitle>
                {t("accountScreen.password.messageSuccessTitle")}
              </AlertTitle>
              <AlertDescription>
                {t("accountScreen.password.messageSuccessDescription")}
              </AlertDescription>
            </Alert>
          )}
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>{t("accountScreen.2fa.title")}</CardTitle>
        </CardHeader>
        <CardContent>
          {user.data && (
            <Form
              url="/api/user/2fa"
              method="put"
              submitOnChange
              inputs={{
                "2fa_enabled": {
                  type: "switch",
                  label: t("accountScreen.2fa.switch"),
                  default: user?.data?.["2fa_enabled"],
                },
              }}
              callback={(res) => {
                setQrCode(res.data.data.qr_code);

                if (!res.data.data["2fa_enabled"]) setEnabled(false);
              }}
            />
          )}

          {qrCode ? (
            <Fragment>
              <Alert>
                <InfoIcon className="h-4 w-4" />
                <AlertTitle>
                  {t("accountScreen.2fa.messageQrCodeTitle")}
                </AlertTitle>
                <AlertDescription>
                  {t("accountScreen.2fa.messageQrCodeDescription")}
                </AlertDescription>
              </Alert>

              <img
                src={qrCode}
                alt={t("accountScreen.2fa.scanWithApp")}
                style={{ marginBottom: "1em", marginLeft: "-0.75em" }}
              />

              <Form
                method="post"
                url="/api/user/2fa/verify"
                buttonText={t("accountScreen.2fa.verifyButton")}
                inputs={{
                  code: {
                    label: t("accountScreen.2fa.verificationCode"),
                    type: "text",
                  },
                }}
                callback={(res) => {
                  setQrCode(null);
                  setEnabled(true);
                  setBackupCode(res.data.data.backup_code);
                }}
              />
            </Fragment>
          ) : (
            <Fragment>
              {enabled ? (
                <Fragment>
                  {backupCode ? (
                    <Alert>
                      <InfoIcon className="h-4 w-4" />
                      <AlertTitle>
                        {t("accountScreen.2fa.backupCodeTitle")}
                      </AlertTitle>
                      <AlertDescription>
                        {t("accountScreen.2fa.backupCodeDescription")}
                        <Form
                          inputs={{
                            code: {
                              type: "text",
                              value: backupCode,
                            },
                          }}
                        />
                      </AlertDescription>
                    </Alert>
                  ) : (
                    <Alert>
                      <InfoIcon className="h-4 w-4" />
                      <AlertTitle>
                        {t("accountScreen.2fa.successMessageTitle")}
                      </AlertTitle>
                      <AlertDescription>
                        {t("accountScreen.2fa.successMessageDescription")}
                      </AlertDescription>
                    </Alert>
                  )}
                </Fragment>
              ) : (
                <Alert>
                  <MessageCircleWarningIcon className="h-4 w-4" />
                  <AlertTitle>
                    {t("accountScreen.2fa.enableMessageTitle")}
                  </AlertTitle>
                  <AlertDescription>
                    {t("accountScreen.2fa.enableMessageDescription")}
                  </AlertDescription>
                </Alert>
              )}
            </Fragment>
          )}
        </CardContent>
      </Card>
    </div>
  );
}
