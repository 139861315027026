/***
 *
 *   usePermissions hook
 *   fetch, format and return the user permissions
 *
 **********/

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAPI } from "./api";

export function usePermissions() {
  const { t } = useTranslation();
  const [state, setState] = useState({ data: null, loading: false });
  const permissions = useAPI("/api/user/permissions");

  useEffect(() => {
    setState({ loading: true });

    // format permissions
    if (permissions.data) {
      let perms = [];
      Object.keys(permissions?.data).map((perm) => {
        perms.push({ value: perm, label: t(`permission.${perm}`) });
        setState({ data: { list: perms }, loading: false });
        return perms;
      });
    }
  }, [permissions, t]);

  return state;
}
