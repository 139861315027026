import { z } from "zod";
import dialogUiConfig from "./dialogUiConfig";

const CategoryFormSchema = z.object({
  extSourceName: z.string().min(1, {
    message: dialogUiConfig.nameFieldValidationMsg,
  }),
  extSourceUrl: z.string().url({
    message: dialogUiConfig.valueFieldValidationMsg,
  }),
  tags: z.array(z.string()).optional(), // !TODO: validate type
});

export default CategoryFormSchema;