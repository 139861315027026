import Axios from "axios";

// components
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { PrivateRoute, AuthProvider } from "./providers/auth";

// tailwind css
import "../css/output.css";
import { useTranslation } from "react-i18next";
import StateProvider from "./providers/stateProvider";
import { Toaster } from "@/components/ui/toaster";
import { View } from "components/view/view";

// settings
const Settings = require("settings.json");
// // const StripePromise = loadStripe(
// //   Settings[process.env.NODE_ENV].stripe.publishableAPIKey
// // );

const routes = [
  ...require("routes/account").default,
  ...require("routes/app").default,
  ...require("routes/auth").default,
  ...require("routes/patients").default,
  ...require("routes/mediaLibrary").default,
  ...require("routes/chat").default,
  ...require("routes/admin").default,
];

export default function App(props) {
  const { t } = useTranslation();
  const user = JSON.parse(localStorage.getItem("user"));
  Axios.defaults.baseURL = Settings[process.env.NODE_ENV].server_url;

  if (user?.token) {
    // add auth token to api header calls
    Axios.defaults.headers.common["Authorization"] = "Bearer " + user.token;
  }

  // render the routes
  return (
    // <Elements stripe={StripePromise}>
    <AuthProvider>
      <StateProvider>
        <BrowserRouter>
          <Routes>
            <Route key={"/"} path="/" element={<Navigate to="/patients" />} />
            {routes.map((route) => {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    route.permission ? (
                      <PrivateRoute permission={route.permission}>
                        <View
                          display={route.view}
                          layout={route.layout}
                          title={route.title}
                        />
                      </PrivateRoute>
                    ) : (
                      <View
                        display={route.view}
                        layout={route.layout}
                        title={route.title}
                      />
                    )
                  }
                />
              );
            })}
            <Route path="*" element={<Navigate to="/patients" />} />
          </Routes>
        </BrowserRouter>
      </StateProvider>
      <Toaster />
    </AuthProvider>

    // </Elements>
  );
}
