import { z } from "zod";
import dialogUiConfig from "./dialogUiConfig";

const PlaylistFormSchema = z.object({
  playlistName: z.string().min(1, {
    message: dialogUiConfig.nameFieldValidationMsg,
  }),
  // !TODO: validate tags type
  tags: z.array(z.string()).optional(),
});

export default PlaylistFormSchema;