/***
 *
 *   useAPI hook
 *   Interact with API calls – handle errors, return loading state and data
 *
 *   PROPS
 *   url: endpoint url
 *   method: get, post, put etc.. (default: get)
 *
 **********/

import { useState, useEffect, useCallback } from "react";
import Axios from "axios";
import { useError } from "app/utils";

export function useAPI(url, method) {
  // wrap in useRef to prevent triggering useEffect multiple times
  const [state, setState] = useState({ data: null, loading: false });
  const [handleError] = useError();

  const fetch = useCallback(async () => {
    try {
      if (!url) {
        setState({ data: null, loading: false });
        return false;
      }

      setState({ loading: true });
      const res = await Axios({
        url: url,
        method: method || "get",
      });

      const response = res.data.data || res.data;
      setState({ data: response, loading: false });
    } catch (err) {
      handleError(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, method]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return state;
}
