"use client";

import { Check, ChevronsUpDown } from "lucide-react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "app/providers/auth";

export function AccountSelector() {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(authContext.user?.account_id);

  const currentAccount = authContext.user?.accounts.find(
    (account) => account.id === value
  );
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger className="flex flex-1 w-full" asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="justify-start items-start flex-1"
        >
          <span className="flex-1 flex truncate">
            {
              authContext.user?.accounts.find((account) => account.id === value)
                ?.name
            }
          </span>
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[235px] p-0">
        <Command
          filter={(value, search) => {
            var foundAccount = authContext.user?.accounts.find(
              (account) => account.id === value
            );

            return foundAccount.name
              .toLowerCase()
              .includes(search.toLowerCase())
              ? 1
              : 0;
          }}
        >
          <CommandInput placeholder={t("accountScreen.profile.search")} />
          <CommandEmpty>{t("accountScreen.profile.noAccount")}</CommandEmpty>
          <CommandGroup>
            {authContext.user?.accounts.map((account) => (
              <CommandItem
                key={account.id}
                value={account.id}
                onSelect={(currentValue) => {
                  setValue(currentValue);
                  setOpen(false);
                  authContext.switchAccount(currentValue);
                }}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    value === account.id ? "opacity-100" : "opacity-0"
                  )}
                />
                {account.name}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
