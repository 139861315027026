import { useState, useEffect, useContext } from "react";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import axios from "axios";
import moment from "moment";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { MoreHorizontal, PlusCircle } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { DataTable } from "@/components/ui/data-table";
import { AuthContext } from "app/providers/auth";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast";
import { useError } from "app/utils";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
} from "@/components/ui/breadcrumb";
import { ViewContext } from "components/view/view";

export function Patients(props) {
  const { t } = useTranslation();
  const context = useContext(ViewContext);
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [patients, setPatients] = useState([]);
  const navigate = useNavigate();
  const { toast } = useToast();
  const [handleError] = useError();

  const canManagePatients =
    authContext.user?.permission === "owner" ||
    authContext.user?.permission === "master" ||
    authContext.user?.permission === "admin" ||
    authContext.user?.permission === "doctor";

  useEffect(() => {
    fetchPatients();
    // eslint-disable-next-line
  }, []);

  const fetchPatients = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/api/patient/my");
      // format the user list
      let list = [];

      if (res.data?.length) {
        list = res.data.map((x) => {
          let last_active = x.device_last_active ?? x.last_active;
          if (last_active) {
            last_active = moment(last_active)
              .local()
              .format("YYYY-MM-DD HH:mm:ss");
          }
          return {
            online: x.online,
            batteryLevel: x.battery_level,
            name: x.name,
            id: x.id,
            last_active: last_active,
          };
        });
      }

      setPatients(list);
      setLoading(false);
    } catch (err) {
      handleError(err);
    }
  };

  const manageCalendar = (row) => {
    navigate(`/patients/${row.id}`, {
      state: {
        name: row.name,
        online: row.online,
        batteryLevel: row.batteryLevel,
      },
    });
  };

  const addPatient = () => {
    context.modal.show(
      {
        title: t("accountScreen.users.addPatient.title"),
        form: {
          name: {
            label: t("accountScreen.users.addPatient.name"),
            type: "text",
            required: true,
            errorMessage: t("accountScreen.users.addPatient.nameError"),
          },
          // national_id: {
          //   label: t("accountScreen.users.addPatient.nationalId"),
          //   type: "text",
          //   required: false,
          // },
          // place_of_living: {
          //   label: t("accountScreen.users.addPatient.placeOfLiving"),
          //   type: "radio",
          //   options: [
          //     t("accountScreen.users.addPatient.nursingHome"),
          //     t("accountScreen.users.addPatient.personalAddress"),
          //   ],
          //   values: ["nursing_home", "personal_address"],
          //   required: false,
          // },
          // address: {
          //   label: t("accountScreen.users.addPatient.address"),
          //   type: "text",
          //   required: false,
          // },
        },
        buttonText: t("accountScreen.users.addPatient.button"),
        url: "/api/user/patient",
        method: "POST",
      },
      (form, res) => {
        toast({
          title: t("accountScreen.users.addPatient.message", {
            name: form.name.value,
          }),
        });
        fetchPatients();
      }
    );
  };

  function deleteUser(data, event) {
    context.modal.show(
      {
        title: t("accountScreen.users.delete.title"),
        form: {},
        buttonText: t("accountScreen.users.delete.button"),
        text: t("accountScreen.users.delete.description", { name: data.name }),
        url: `/api/user/${data.id}`,
        method: "DELETE",
        destructive: true,
      },
      () => {
        toast({
          title: t("accountScreen.users.delete.message", { name: data.name }),
        });
        fetchPatients();
      }
    );
  }

  function editUser(data, event) {
    context.modal.show(
      {
        title: t("accountScreen.users.update.title"),
        form: {
          id: {
            type: "hidden",
            value: data.id,
          },
          name: {
            label: t("accountScreen.users.update.name"),
            type: "text",
            required: true,
            value: data.name,
            errorMessage: t("accountScreen.users.update.nameError"),
          },
          // national_id: {
          //   label: t("accountScreen.users.update.nationalId"),
          //   type: "text",
          //   value: data.national_id,
          //   required: false,
          // },
          // place_of_living: {
          //   label: t("accountScreen.users.update.placeOfLiving"),
          //   type: "radio",
          //   options: [
          //     t("accountScreen.users.update.nursingHome"),
          //     t("accountScreen.users.update.personalAddress"),
          //   ],
          //   values: ["nursing_home", "personal_address"],
          //   default: data.place_of_living,
          //   required: false,
          // },
          // address: {
          //   label: t("accountScreen.users.update.address"),
          //   type: "text",
          //   value: data.address,
          //   required: false,
          // },
          disabled: {
            label: t("accountScreen.users.update.disabled"),
            type: "switch",
            default: data.disabled,
          },
        },
        buttonText: t("accountScreen.users.update.button"),
        url: "/api/user",
        method: "PATCH",
      },
      (res) => {
        toast({
          title: t("accountScreen.users.update.message", { name: data.name }),
        });
        fetchPatients();
      }
    );
  }

  return (
    <div className="sm:px-6 flex flex-col flex-1 pb-2 h-full overflow-hidden">
      <Breadcrumb className="py-5">
        <BreadcrumbList>
          <BreadcrumbItem className="text-2xl font-semibold text-[#11203D]">
            {t("patientsScreen.title")}
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <DataTable
        stickyHeader
        actionButtons={
          <Button
            className="px-[18px] focus-visible:ring-0 focus:ring-0"
            onClick={addPatient}
            size="default"
          >
            <PlusCircle className="h-3.5 w-3.5 mr-1" />
            <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
              {t("accountScreen.users.addPatientButton")}
            </span>
          </Button>
        }
        columns={[
          {
            accessorKey: "name",
            header: t("accountScreen.users.table.name"),
            sort: true,
          },
          {
            accessorKey: "online",
            header: t("accountScreen.users.table.online"),
            cell: ({ row }) => {
              const patient = row.original;

              return (
                <Badge variant={patient.online ? "secondary" : "destructive"}>
                  {patient.online
                    ? t("accountScreen.users.table.onlineStatus")
                    : t("accountScreen.users.table.offlineStatus")}
                </Badge>
              );
            },
          },
          {
            accessorKey: "last_active",
            header: t("accountScreen.users.table.lastActive"),
          },
          {
            id: "actions",
            enableHiding: false,
            cell: ({ row }) => {
              const patient = row.original;

              return (
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button aria-haspopup="true" size="icon" variant="ghost">
                      <MoreHorizontal className="h-4 w-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuLabel>
                      {t("accountScreen.users.table.actions")}
                    </DropdownMenuLabel>
                    <DropdownMenuItem
                      onClick={(event) => {
                        event.stopPropagation();
                        editUser(patient, event);
                      }}
                    >
                      {t("accountScreen.users.table.edit")}
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={(event) => {
                        event.stopPropagation();
                        deleteUser(patient, event);
                      }}
                    >
                      {t("accountScreen.users.table.delete")}
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              );
            },
          },
        ]}
        data={patients}
        searchField="name"
        onRowClick={manageCalendar}
      />
    </div>
  );
}
