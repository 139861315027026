import { CSSTransition } from "react-transition-group";
import { MediaLibrary } from "./mediaLibrary";
import { useTranslation } from "react-i18next";
import { Card, CardHeader, CardTitle } from "@/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../tabs";

export function MediaDialog(props) {
  const { apiPrefix, onlyPrivate, ...rest } = props;
  const { t } = useTranslation();
  let publicApi = apiPrefix;
  let privateApi = null;
  if (apiPrefix?.startsWith("/api/user/")) {
    publicApi = "/api/media";
    privateApi = apiPrefix;
  }

  return (
    <CSSTransition in appear timeout={0} classNames="modal">
      <div
        className="modal"
        onClick={(e) => e.target === e.currentTarget && props.hide()}
      >
        <div className="modal-content overflow-y-auto">
          <Card className="border-none shadow-none">
            <CardHeader>
              <CardTitle>
                {props.title ??
                  t("mediaLibraryScreen.contentForm.chooseFileTitle")}
              </CardTitle>
            </CardHeader>
            {!onlyPrivate && privateApi && publicApi && (
              <Tabs defaultValue="public" className="w-[400px]">
                <TabsList>
                  <TabsTrigger value="public">
                    {t("mediaDialog.public")}
                  </TabsTrigger>
                  {privateApi && (
                    <TabsTrigger value="private">
                      {t("mediaDialog.private")}
                    </TabsTrigger>
                  )}
                </TabsList>
                <TabsContent value="public">
                  <MediaLibrary apiPrefix={publicApi} {...rest} />
                </TabsContent>
                {privateApi && (
                  <TabsContent value="private">
                    <MediaLibrary apiPrefix={privateApi} private {...rest} />
                  </TabsContent>
                )}
              </Tabs>
            )}
            {!privateApi && <MediaLibrary apiPrefix={publicApi} {...rest} />}
            {onlyPrivate && privateApi && (
              <MediaLibrary apiPrefix={privateApi} private {...rest} />
            )}
          </Card>
        </div>
      </div>
    </CSSTransition>
  );
}
