import { useContext, useEffect, useMemo, useState } from "react";
import Axios from "axios";
import { t } from "i18next";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Input } from "@/components/ui/input";
import ChatRoom from "./ChatRoom";
import CreateRoomDialog from "./CreateRoomDialog";
import { ChartPublicRoomCard, ChatPrivateRoomCard } from "./ChatRoomCard";
import { getTotalUnreadCount } from "app/providers/stateProvider/helpers";
import { AppContext } from "app/providers/stateProvider";

const ChatComponent = ({ userId = null }) => {
  const appContext = useContext(AppContext);
  const [filterValue, setFilterValue] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const roomData = appContext?.chat.chatState.chatRooms.find(
          (entry) => entry.id === appContext?.chat.chatState.selectedRoomId
        )?.roomData;

        if (!roomData) {
          return;
        }

        const {
          id: roomId,
          last_message_timestamp: timestamp,
          messages_count: messagesCount,
        } = roomData;

        if (roomData?.last_message_timestamp && roomData?.messages_count) {
          const userData = await Axios.post(`/api/user/activity/${roomId}`, {
            timestamp,
            messagesCount,
          });

          if (!userData) {
            throw new Error("error updating user data");
          }
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, [
    appContext?.chat.chatState.chatRooms,
    appContext?.chat.chatState.selectedRoomId,
    appContext?.chat.chatState.currentUserData,
  ]);

  useEffect(() => {
    (async () => {
      try {
        // select room
        if (userId) {
          const chatRoom = appContext?.chat.chatState.chatRooms.find(
            ({ userData, roomData }) => {
              return (
                (userData && userData.id === userId) ||
                (roomData &&
                  !roomData.creator_id &&
                  roomData.members.find(({ memberId }) => memberId === userId))
              );
            }
          );

          if (chatRoom) {
            appContext?.chat.onSelectChatRoomByUser(chatRoom.id);
          }
        }
      } catch (err) {
        console.error(err);
      }
    })();

    return () => {
      appContext?.chat.onSelectChatRoomByUser(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  // do NOT! add dependencies to this handler (causes SSE client reconnect and hanging)

  const selectedChatRoom = useMemo(() => {
    return appContext?.chat.chatState.chatRooms.find(
      (entry) => entry.id === appContext?.chat.chatState.selectedRoomId
    );
  }, [
    appContext?.chat.chatState.chatRooms,
    appContext?.chat.chatState.selectedRoomId,
  ]);

  const chatRoomsToDisplay = useMemo(() => {
    const filterValueToCompare = filterValue.toLowerCase();

    if (!appContext?.chat.chatState.chatRooms) {
      return [];
    }

    return !filterValueToCompare.length
      ? appContext?.chat.chatState.chatRooms
      : appContext?.chat.chatState.chatRooms.filter((room) => {
          return room.roomData
            ? room.roomData?.name?.toLowerCase().includes(filterValueToCompare)
            : room.userData?.name?.toLowerCase().includes(filterValueToCompare);
        });
  }, [appContext?.chat.chatState.chatRooms, filterValue]);

  if (!appContext || !appContext.chat.chatState.currentUserData) {
    return null;
  }

  return (
    <div className="flex flex-row h-full px-4">
      {userId === null && (
        <div className="flex flex-col h-full px-2 border-l border-t border-b border-r border-slate-200">
          <Input
            placeholder={t(
              "chatScreen.chatComponent.filterRoomsInputPlaceholder"
            )}
            className="my-4 focus:!ring-transparent"
            onChange={(e) => setFilterValue(e.target.value)}
          />
          <ScrollArea className="h-full h-32 w-72 flex-auto border-t border-b p-4 overflow-y-auto">
            {chatRoomsToDisplay.map((chatRoom) =>
              chatRoom.roomData && chatRoom.roomData.creator_id ? (
                <ChartPublicRoomCard
                  key={chatRoom.id}
                  data={chatRoom as any}
                  currentUserData={
                    appContext.chat.chatState.currentUserData as any
                  }
                  isSelected={
                    chatRoom.id === appContext.chat.chatState.selectedRoomId
                  }
                  onSelect={appContext?.chat.onSelectChatRoomByUser}
                  unreadCount={getTotalUnreadCount(
                    appContext.chat.chatState.currentUserData as any,
                    [chatRoom]
                  )}
                />
              ) : (
                <ChatPrivateRoomCard
                  key={chatRoom.id}
                  data={chatRoom as any}
                  currentUserData={
                    appContext.chat.chatState.currentUserData as any
                  }
                  isSelected={
                    chatRoom.id === appContext.chat.chatState.selectedRoomId
                  }
                  onSelect={appContext?.chat.onSelectChatRoomByUser}
                  unreadCount={getTotalUnreadCount(
                    appContext.chat.chatState.currentUserData as any,
                    [chatRoom]
                  )}
                />
              )
            )}
          </ScrollArea>
          <CreateRoomDialog />
        </div>
      )}
      {appContext.chat.chatState.currentUserData && selectedChatRoom && (
        <ChatRoom
          controlled
          messages={appContext.chat.chatState.selectedRoomMessages}
          currentUserData={appContext.chat.chatState.currentUserData}
          data={selectedChatRoom}
          id={appContext.chat.chatState.selectedRoomId}
        />
      )}
    </div>
  );
};

export default ChatComponent;
