/***
 *
 *   SIGN IN
 *   Sign in form for all account types (including master).
 *
 **********/

import { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "app/providers/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast";
import { Card } from "@/components/ui/card";
import { ViewContext } from "components/view/view";
import { Message } from "components/message/message";
import { Row } from "components/layout/row/row";
import { Form } from "components/form/form";

export function Signin(props) {
  const { t } = useTranslation();
  // context
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);
  const location = useLocation();
  const navigate = useNavigate();
  const { toast } = useToast();

  // state
  const [useMagic, setUseMagic] = useState(false);
  const [magicLinkSent, setMagicLinkSent] = useState(false);
  const [form, setForm] = useState({
    email: {
      label: t("signInScreen.form.email"),
      type: "email",
      required: true,
    },
    password: {
      label: t("signInScreen.form.password"),
      type: "password",
      required: true,
    },
    forgotpassword: {
      type: "link",
      url: "/forgotpassword",
      text: t("signInScreen.form.forgotPassword"),
    },
  });

  useEffect(() => {
    // was an error message passed from the server router?
    const qs = location.search;

    if (qs.includes("error")) {
      const msg = decodeURIComponent(qs.substring(qs.indexOf("?error=") + 7));
      toast({
        title: msg,
      });
    }
  }, [location.search, toast]);

  // function toggleMagicLink(useMagic){

  //   const f = {...form };
  //   f.password.type = useMagic ? 'hidden' : 'password';
  //   f.password.required = useMagic ? false : true;
  //   f.forgotpassword.type = useMagic ? 'hidden' : 'link';

  //   setForm(f);
  //   setUseMagic(useMagic);

  // }

  return (
    <Row
      title={t("signInScreen.welcome")}
      desc={t("signInScreen.welcomeDescription")}
    >
      {magicLinkSent ? (
        <div style={{ width: "28em", margin: "0 auto" }}>
          <Message
            title={t("signInScreen.magicLink.message")}
            type="success"
            text={t("signInScreen.magicLink.messageDescription")}
          />
        </div>
      ) : (
        <Card className="border-none shadow-none">
          <Form
            inputs={form}
            method="POST"
            url={useMagic ? "/api/auth/magic" : "/api/auth"}
            buttonText={
              useMagic
                ? t("signInScreen.magicLink.button")
                : t("signInScreen.button")
            }
            callback={(res) => {
              useMagic
                ? setMagicLinkSent(true)
                : res.data["2fa_required"]
                  ? navigate(`/signin/otp?token=${res.data.token}`)
                  : navigate(authContext.signin(res));
            }}
          />
        </Card>
      )}
    </Row>
  );
}
