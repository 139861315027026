import { memo, useCallback, useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { ScrollArea } from "@/components/ui/scroll-area";
import Axios from "axios";
import { Toggle } from "@/components/ui/toggle";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
} from "@/components/ui/card";

const CreateRoomDialog = () => {
  const { t } = useTranslation();
  const [usersList, setUsersList] = useState<any[]>([]);
  const [groupsList, setGroupsList] = useState<any[]>([]);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [selectedUsersIds, setSelectedUsersIds] = useState([]);
  const [selectedGroupsIds, setSelectedGroupsIds] = useState([]);
  // const [membersFetching, setMembersFetching] = useState(false);
  const [roomName, setRoomName] = useState("");

  const onOpenChange = useCallback((opened: any) => {
    if (!opened) {
      setUsersList([]);
      setSelectedUsersIds([]);
      setSelectedGroupsIds([]);
      setRoomName("");
      return;
    }

    (async () => {
      try {
        const groups: any[] = [];
        // setMembersFetching(true);

        const currentUserDataResult = await Axios.get("/api/user");

        if (!currentUserDataResult?.data?.data) {
          throw new Error("error getting user data");
        }

        const isAddminRole = ["admin", "master", "owner"].includes(
          currentUserDataResult.data.data?.permission
        );

        const availUsersDataResult = isAddminRole
          ? await Axios.get("/api/contacts/admin/my")
          : await Axios.get("/api/contacts/service/my");

        const availGroupsDataResult = isAddminRole
          ? (await Axios.get("/api/group"))?.data || []
          : [];

        availGroupsDataResult.forEach((group: any) => {
          groups.push({ id: group.id, name: group.name });
        });

        setCurrentUser(currentUserDataResult?.data?.data);
        setGroupsList(groups);
        setUsersList(availUsersDataResult.data);
        // setMembersFetching(false);
      } catch (err) {
        console.error(err);
        // setMembersFetching(false);
      }
    })();
  }, []);

  const onSubmit = useCallback(
    (e: any) => {
      (async () => {
        try {
          const members = selectedGroupsIds
            .map((memberId: string) => ({ memberId, memberType: "group" }))
            .concat(
              selectedUsersIds.map((memberId: string) => ({
                memberId,
                memberType: "user",
              }))
            )
            .concat({ memberId: currentUser.id, memberType: "user" });

          await Axios.post("/api/chatrooms", {
            name: roomName,
            members,
          });
        } catch (err) {
          console.error("err");
          e.stopPropagation();
        }
      })();
    },
    [selectedUsersIds, selectedGroupsIds, roomName, currentUser]
  );

  return (
    <Dialog onOpenChange={onOpenChange}>
      <DialogTrigger asChild>
        <Button className="my-4 w-full flex-none">
          {t("chatScreen.chatComponent.createGroupButton")}
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[625px]">
        <DialogHeader>
          <DialogTitle>
            {t("chatScreen.chatComponent.createGroupHeader")}
          </DialogTitle>
          <DialogDescription>
            {t("chatScreen.chatComponent.createGroupDescription")}
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <Input
              value={roomName}
              onChange={(e) => setRoomName(e.target.value)}
              id="roomname"
              placeholder={t(
                "chatScreen.chatComponent.createGroupInputPlaceholder"
              )}
              className="focus:!ring-transparent col-span-4 w-full"
            />
          </div>
          <Card>
            <CardHeader>
              <CardDescription>
                {t("chatScreen.chatComponent.usersTitle")}
              </CardDescription>
            </CardHeader>
            <CardContent>
              <ScrollArea className="h-full h-32 rounded-lg w-full flex-auto border p-4 overflow-y-auto">
                {usersList.map((user) => (
                  <Toggle
                    key={user.id}
                    onPressedChange={(pressed) => {
                      setSelectedUsersIds(
                        pressed
                          ? selectedUsersIds.concat(user.id)
                          : selectedUsersIds.filter(
                              (userId) => userId !== user.id
                            )
                      );
                    }}
                    className="my-1 mx-1"
                    value={user.id}
                  >
                    {user.name}
                  </Toggle>
                ))}
              </ScrollArea>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardDescription>
                {t("chatScreen.chatComponent.groupsTitle")}
              </CardDescription>
            </CardHeader>
            <CardContent>
              <ScrollArea className="h-full h-32 rounded-lg w-full flex-auto border p-4 overflow-y-auto">
                {groupsList.map((group) => (
                  <Toggle
                    key={group.id}
                    onPressedChange={(pressed) => {
                      setSelectedGroupsIds(
                        pressed
                          ? selectedGroupsIds.concat(group.id)
                          : selectedGroupsIds.filter(
                              (groupId) => groupId !== group.id
                            )
                      );
                    }}
                    className="my-1 mx-1"
                    value={group.id}
                  >
                    {group.name}
                  </Toggle>
                ))}
              </ScrollArea>
            </CardContent>
          </Card>
        </div>
        <DialogFooter>
          <DialogClose>
            <Button
              onClick={onSubmit}
              type="submit"
              disabled={
                !roomName.length ||
                (!selectedUsersIds.length && !selectedGroupsIds.length)
              }
            >
              {t("chatScreen.chatComponent.createGroupSubmitButton")}
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default memo(CreateRoomDialog);
